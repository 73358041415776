import _ from 'lodash';

import { REFRESH_ACTIVITIES_REQUEST } from './';

import { fetchNotifications } from '../api/activity';
import refreshActivitiesSuccess from './refresh-activity-items-success';
import refreshActivitiesError from './refresh-activity-items-error';

export default (next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { userId } = {} } = {},
      activity: { activities: { filter = {}, per_page: limit } = {} } = {},
    } = getState();
    dispatch({ type: REFRESH_ACTIVITIES_REQUEST });

    return fetchNotifications(userId, {
      ...filter,
      limit,
    })
      .then(response => {
        dispatch(refreshActivitiesSuccess(response));
        next(null, response);
      })
      .catch(error => {
        dispatch(refreshActivitiesError(_.get(error, ['response', 'status'])));
        next(error);
      });
  };
};
