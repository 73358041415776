import React, { Component } from 'react';
import { Tag, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { omit, pick } from 'lodash';

import Box from 'APP_ROOT/components/Box';
import Styled from './SectionWrapperEditor.styled';
import PickableZone from '../PickableZone';
import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import withModal from 'APP_COMPONENTS/common/modal/base';
import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import {
  TAB_VALUES,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
} from '../OptionsModalBody/OptionsModalBody';
import {
  CONTRIBUTOR_ASSIGNMENT_SECTION,
  CONTRIBUTOR_ASSIGNMENT,
  CONTRIBUTOR_ASSIGNMENT_DATE,
  CONTRIBUTOR_SECTION_COMPLETE,
  CONTRIBUTOR_SECTION_COMPLETE_DATE,
} from '../../../../constants/contributeToReport.js';
import { SECTION, FIELD } from '../../../../constants/layoutComponentTypes.js';
import {
  AUTOCOMPLETE,
  DATE_TIME,
  CHECKBOX,
} from '../../../../constants/fieldTypes.js';
import getCustomFields from '../../utils/getCustomFields/getCustomFields.js';

import { hasFeatures, FEATURES } from '../../../../utils/features.js';
import store from '../../../../store.js';

const MODAL_OPTIONS = { width: '750px' };
const CONTRIBUTOR_COMPONENTS = [
  {
    field_type: AUTOCOMPLETE,
    type: FIELD,
    label: 'Autocomplete',
    reportingKey: CONTRIBUTOR_ASSIGNMENT,
  },
  {
    field_type: DATE_TIME,
    type: FIELD,
    label: 'Date time',
    reportingKey: CONTRIBUTOR_ASSIGNMENT_DATE,
  },
  {
    field_type: CHECKBOX,
    type: FIELD,
    label: 'Checkbox',
    icon: 'checkbox',
    reportingKey: CONTRIBUTOR_SECTION_COMPLETE,
  },
  {
    field_type: DATE_TIME,
    type: FIELD,
    label: 'Date time',
    icon: 'date-time',
    reportingKey: CONTRIBUTOR_SECTION_COMPLETE_DATE,
  },
];

class SectionWrapperEditor extends withModal(Component) {
  constructor(props) {
    super(props);
    // Check to see if form contains a performance review Section
    let reviewSection = {};

    props.field.properties.forEach(property => {
      if (property.reportingKey === CONTRIBUTOR_ASSIGNMENT_SECTION) {
        reviewSection = property;
      }
    });
    const { properties = [] } = reviewSection;
    const fields = componentsManager.findFieldsByType(
      properties,
      FIELD,
      AUTOCOMPLETE
    );
    const [autocomplete] = fields;

    this.state = {
      values: {
        ...props.field,
        options: { ...props.field.options, ...autocomplete?.options },
      },
      reviewSection,
    };
  }

  componentDidMount() {
    this.createModal(MODAL_OPTIONS);
  }

  setOptions = values => {
    const { reportingKey } = values;

    return {
      reportingKey,
    };
  };

  remove = () => {
    const { id } = this.props;
    componentsManager.removeComponent(id);
  };
  getContributeToReportOptions = options => ({
    visibleToAllContributors: {
      type: 'checkbox',
      label: 'Visible to All Contributors',
      value: options.visibleToAllContributors,
    },
  });

  insertContributeToReportFields = wrapperOptions => {
    const { isContributorSection, ...options } = wrapperOptions;

    const reviewSection = componentsManager.addComponent(
      {
        type: SECTION,
        label: 'Section',
        icon: 'section',
        columns: 4,
        reportingKey: CONTRIBUTOR_ASSIGNMENT_SECTION,
      },
      this.props.id,
      0
    );
    const {
      properties: [{ properties }],
      id: sectionId,
    } = reviewSection;
    properties.forEach(({ id }, i) => {
      const component =
        CONTRIBUTOR_COMPONENTS[i].field_type === AUTOCOMPLETE
          ? {
              ...CONTRIBUTOR_COMPONENTS[i],
              options,
            }
          : CONTRIBUTOR_COMPONENTS[i];
      componentsManager.addComponent(component, id);
    });
    // get the section with the fields added
    const section = componentsManager.findContainersById(
      componentsManager.fields,
      sectionId
    );
    return section[0];
  };

  updateAutocompleteOptions = newOptions => {
    const {
      reviewSection: { properties, id },
    } = this.state;
    const fields = componentsManager.findFieldsByType(
      properties,
      FIELD,
      AUTOCOMPLETE
    );
    const [autocomplete] = fields;
    const modifiedValues = {
      ...autocomplete,
      options: {
        ...autocomplete.options,
        ...newOptions,
      },
    };
    componentsManager.editComponent(autocomplete.id, modifiedValues);
    // return the updated contributor section
    const container = componentsManager.findContainersById(
      componentsManager.fields,
      id
    );
    return container[0];
  };

  updateContributorFields = modifiedValues => {
    const {
      values: { options = {} } = {},
      reviewSection: { id } = {},
    } = this.state;
    const {
      options: {
        isContributorSection,
        visibleToAllContributors,
        ...autocompleteOptions
      },
    } = modifiedValues;
    let reviewSection = {};

    if (options.isContributorSection) {
      if (isContributorSection) {
        reviewSection = this.updateAutocompleteOptions(autocompleteOptions);
      } else {
        componentsManager.removeComponent(id);
      }
    } else {
      if (isContributorSection) {
        reviewSection = this.insertContributeToReportFields(
          modifiedValues.options
        );
      }
    }

    this.setState({
      reviewSection,
      values: modifiedValues,
    });
  };

  getModifiedValues = (params, customFields) => {
    const { reportingKey, visibleToAllContributors } = params;
    const mutations = [];

    customFields.forEach(customField => {
      mutations.push(customField.onSave);
    });
    return mutations.reduce(
      (allChanges, mutation) => mutation(allChanges),
      Object.assign(
        {},
        {
          reportingKey: reportingKey.value,
          options: {
            visibleToAllContributors: visibleToAllContributors.value,
          },
        },
        omit(params, [
          'reportingKey',
          'visibleToAllContributors',
          'validations',
          'customFields',
          'select',
          'sharedKey',
          'sharedRef',
          'populateFrom',
        ])
      )
    );
  };

  showOptionsModal = () => {
    const { values, values: { options = {} } = {} } = this.state;
    const { type, field } = this.props;
    let contributeToReportOptions = {};
    const {
      session: {
        currentUser: { featureFlags = [] },
      },
    } = store.getState();
    const contributeToReportEnabled = hasFeatures(
      featureFlags,
      FEATURES.enableContributeToReport
    );

    let customFieldsInitialValue = {};
    let customFields = [];
    if (contributeToReportEnabled) {
      contributeToReportOptions = this.getContributeToReportOptions(options);
      customFields = getCustomFields(field.type, field.field_type, values);
      customFieldsInitialValue = customFields.reduce(
        (allInitialValues, { initialValue, name }) => ({
          ...allInitialValues,
          [name]: initialValue,
        }),
        {}
      );
    }

    const fieldsOptions = {
      type,
      reportingKey: {
        type: 'text',
        label: 'Reporting Key',
        value: values.reportingKey,
        maxLength: 75,
        placeholder: 'Reporting key',
      },
      ...contributeToReportOptions,
      ...customFieldsInitialValue,
      customFields,
      disableTab: [TAB_VALUES, TAB_SHARE_KEY, TAB_INFORMATION],
    };

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onSave: params => {
        const modifiedValues = this.getModifiedValues(params, customFields);

        this.updateContributorFields(modifiedValues);
        componentsManager.editComponent(field.id, {
          ...modifiedValues,
          options: pick(modifiedValues.options, [
            'visibleToAllContributors',
            'isContributorSection',
          ]),
        });

        this.deleteModal();
      },
      onDelete: () => {
        this.remove();
        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  render() {
    const {
      children,
      type,
      id,
      total,
      field: { reportingKey },
    } = this.props;

    const {
      values: { options = {} },
    } = this.state;
    return (
      <Styled>
        <Box isRounded isWhite>
          <Row type="flex" align="middle">
            <Col>
              <Tag>SECTION-WRAPPER</Tag>
            </Col>
            {options.visibleToAllContributors && (
              <Tag color="blue">Visible to All contributors</Tag>
            )}
            {options.isContributorSection && (
              <Tag color="blue">Contributor Section</Tag>
            )}
            <Col>{reportingKey}</Col>
            <Col className="margin-left">
              {total > 1 && (
                <IconButton
                  icon="setting"
                  className="is-big is-focusable"
                  onClick={this.showOptionsModal}
                />
              )}
            </Col>
          </Row>
          <PickableZone type={type} id={id} style={{ opacity: 0.5 }}>
            {children}
          </PickableZone>
        </Box>
      </Styled>
    );
  }
}

SectionWrapperEditor.displayName = 'FieldEditor.SectionWrapper';

export default connect()(SectionWrapperEditor);
