import some from 'lodash/some';
import castArray from 'lodash/castArray';

export const hasPermissions = (userPermissions, permissionsToFind) =>
  some(castArray(userPermissions), permission =>
    castArray(permissionsToFind).includes(permission)
  );

export const PERMISSIONS = {
  updateSystem: 'updateSystem',
  manageAgencies: 'manageAgencies',
  createAgencyUser: 'createAgencyUser',
  createAnyUser: 'createAnyUser',
  updateAgencyUser: 'updateAgencyUser',
  updateAnyUser: 'updateAnyUser',
  configureDataImport: 'configureDataImport',
  manageRoles: 'manageRoles',
  viewAllUserProfiles: 'viewAllUserProfiles',
  viewOwnOfficerProfile: 'viewOwnOfficerProfile',
  viewAllReports: 'viewAllReports',
  manageWorkflows: 'manageWorkflows',
  manageForms: 'manageForms',
  submitReport: 'submitReport',
  reviewReport: 'reviewReport',
  viewReports: 'viewReports',
  viewCaseLoad: 'viewCaseLoad',
  submitUOFReport: 'submitUOFReport',
  reviewUOFReport: 'reviewUOFReport',
  viewAllUOFReports: 'viewAllUOFReports',
  submitVPReport: 'submitVPReport',
  reviewVPReport: 'reviewVPReport',
  viewAllVPReports: 'viewAllVPReports',
  submitDORReport: 'submitDORReport',
  reviewDORReport: 'reviewDORReport',
  viewAllDailyObservationReports: 'viewAllDailyObservationReports',
  viewOwnDailyObservationReports: 'viewOwnDailyObservationReports',
  submitIACReport: 'submitIACReport',
  reviewIACReport: 'reviewIACReport',
  viewAllIntakes: 'viewAllIntakes',
  submitIAIReport: 'submitIAIReport',
  reviewIAIReport: 'reviewIAIReport',
  viewAllInvestigations: 'viewAllInvestigations',
  accessTraining: 'accessTraining',
  accessTrainingAdminPanel: 'accessTrainingAdminPanel',
  accessAssignTrainingPage: 'accessAssignTrainingPage',
  accessAnalyticsReports: 'accessAnalyticsReports',
  accessAnalyticsAdmin: 'accessAnalyticsAdmin',
  accessAnalyticsDesign: 'accessAnalyticsDesign',
  accessAnalyticsDashboard: 'accessAnalyticsDashboard',
  createRole: 'createRole',
  assignRole: 'assignRole',
  viewOwnUserProfile: 'viewOwnUserProfile',
  updateOwnUserProfile: 'updateOwnUserProfile',
  listUsersByHK: 'listUsersByHK',
  listOrganizationsByHK: 'listOrganizationsByHK',
  accessStatsModule: 'accessStatsModule',
  manageOUAssignment: 'manageOUAssignment',
  viewOUAssignment: 'viewOUAssignment',
  manageDocuments: 'manageDocuments',
  viewDocument: 'viewDocument',
  manageComments: 'manageComments',
  editEmail: 'editEmail',
  listOUReportsByHK: 'listOUReportsByHK',
  listAllOUHierarchyUsers: 'listAllOUHierarchyUsers',
  editEmployeeId: 'editEmployeeId',
  viewReportsWithMention: 'viewReportsWithMention',
  manageOUDocuments: 'manageOUDocuments',
  viewOUDocuments: 'viewOUDocuments',
  deleteReport: 'deleteReport',
  viewUnMaskedSSN: 'viewUnMaskedSSN',
  updateSSN: 'updateSSN',
  viewDraftReports: 'viewDraftReports',
  reassignReports: 'reassignReports',
  viewAllAuditLogs: 'viewAllAuditLogs',
  viewAgencyAuditLogs: 'viewAgencyAuditLogs',
  viewEncryptedData: 'viewEncryptedData',
  childTenantView: 'childTenantView',
  viewSecureProfilePicture: 'viewSecureProfilePicture',
  adminReportEdit: 'adminReportEdit',
  viewStatsPersonnel: 'viewStatsPersonnel',
  manageOUAttributes: 'manageOUAttributes',
  transferEmployment: 'transferEmployment',
  searchDocumentMetadata: 'searchDocumentMetadata',
  manageAllCasefiles: 'manageAllCasefiles',
  manageMyCasefiles: 'manageMyCasefiles',
  accessCasefiles: 'accessCasefiles',
  viewOUComments: 'viewOUComments',
  manageOUComments: 'manageOUComments',
  viewOUPointOfContact: 'viewOUPointOfContact',
  manageOUPointOfContact: 'manageOUPointOfContact',
  manageCommunityMember: 'manageCommunityMember',
  viewCommunityMember: 'viewCommunityMember',
  manageRule28DataExport: 'manageRule28DataExport',
  manageReportDataExport: 'manageReportDataExport',
  manageEquipment: 'manageEquipment',
  manageTMSLiveEvents: 'manageTMSLiveEvents',
  manageCaseFileTypes: 'manageCaseFileTypes',
};
