import getEnumOptions from 'APP_ROOT/utils/getEnumOptions';
import { memoize } from 'lodash';
import { AUTOCOMPLETE } from '../../../../constants/fieldTypes.js';

const getEnums = props => {
  const {
    dataEnums,
    dataKey = '',
    enumRef = '',
    field_type: type = '',
    parentIndex = 0,
    parentKey = '',
    combinedEnumRef = null,
    parentEnum,
    options = {},
  } = props;
  const { source = '' } = options;

  const hasParent = !!parentKey;
  const hasPopulateFromRepeater = source == 'repeating';
  const enumPath = hasParent ? `${enumRef}-${parentIndex}-${dataKey}` : null;
  const enumOptions = {
    dataEnums,
    enumRef,
    parentEnum,
  };
  const dataEnumOptions = getEnumOptions(null, [], enumOptions);
  if (!(hasParent && type === AUTOCOMPLETE)) {
    return dataEnumOptions;
  }
  const dynamicDataEnumOptions = getEnumOptions(
    hasPopulateFromRepeater ? null : enumPath,
    [],
    enumOptions
  );
  const combinedEnums = getEnumOptions(combinedEnumRef, [], enumOptions);
  return [...(combinedEnumRef ? combinedEnums : []), ...dynamicDataEnumOptions];
};

export default memoize(getEnums);
