import { get, toNumber, isNumber, isEmpty } from 'lodash';

import {
  dateValidator,
  noFutureDateRule,
  phonePatternRule,
  numberValidator,
} from 'APP_ROOT/utils/form';
import getEnumOptions from 'APP_ROOT/utils/getEnumOptions';
import booleanRule from 'APP_ROOT/utils/booleanRule';

import getEnumOptionsFromProps from './getEnumOptions';
import getEnums from './getEnums';
import getFieldDateKey from './getFieldDataKey';
import getLabel from './getLabel';
import isFieldOfType from './isFieldOfType';
import getParentRules from './getParentRules';
import isConditionallyRequired from './getIsConditionallyRequired';

import { DURATION } from '../../../../constants/fieldTypes';

const getFieldRules = props => {
  const {
    dataKey = '',
    parentKey = '',
    options = {},
    validations = {},
    timezone,
    field_type,
    fromSource,
  } = props;

  const { defaultValue } = options;

  const hasParent = !!parentKey;

  const { rules: formRules = {} } = validations;

  const enumOptions = getEnumOptionsFromProps(props);
  const enums = getEnums(props);
  const fieldDataKey = getFieldDateKey(props);
  const label = getLabel(props);
  const isDateField = isFieldOfType(props, 'date');
  const isDateFutureAllowed = get(options, 'allowFuture', false);
  const isPhone = isFieldOfType(props, 'phone');
  const isBooleanField = isFieldOfType(props, 'boolean');
  const groupRules = hasParent ? getParentRules(props, parentKey) : formRules;
  const foundRules = groupRules[dataKey] || [];
  const fieldRules = Array.isArray(foundRules) ? foundRules : [foundRules];
  const isNotProfileData = isEmpty(fromSource);

  const withAddedRules = fieldRules
    .concat(
      isDateField && !isDateFutureAllowed ? [noFutureDateRule(timezone)] : []
    )
    .concat(isPhone ? [phonePatternRule] : []);

  const newRules = withAddedRules.map(rule => {
    const required =
      rule.required || isConditionallyRequired(props, rule) || false;

    const message =
      rule.message || `${(label && label.trim()) || 'This field'} is required`;

    if (rule.type === 'multi') {
      return {
        ...rule,
        type: 'array',
        required,
        message,
      };
    }

    if (rule.type === 'enum') {
      return {
        ...rule,
        enum: getEnumOptions(
          fieldDataKey,
          enums,
          Object.assign({}, enumOptions, {
            mapAs: getEnumOptions.mapAsPlain,
          })
        ),
        required,
        message,
      };
    }

    if (rule.type === 'date') {
      return {
        ...rule,
        validator: dateValidator(timezone),
        required,
        message,
      };
    }

    if (
      field_type !== DURATION &&
      isNotProfileData &&
      ['number', 'integer'].includes(rule.type)
    ) {
      return {
        ...rule,
        validator: numberValidator(defaultValue),
        transform: value => {
          const result = toNumber(value);
          return isNumber(result) ? result : value;
        },
        message,
      };
    }
    return { ...rule, required, message };
  });

  return newRules.concat(isBooleanField ? [booleanRule(newRules[0])] : []);
};

export default getFieldRules;
