import { orderBy, get, isEmpty } from 'lodash';
import { message } from 'antd';
import { unescape } from 'html-escaper';

export default function getEHRecords(
  mainForm,
  prefix,
  sectionId,
  attributeId,
  attributes
) {
  const sectionData = get(mainForm, `values.${prefix}[${sectionId}]`, {});
  const keys = get(sectionData, attributeId, []);
  const rowModified = get(sectionData, 'objectRowModified', []);
  const attrs = orderBy(attributes, ['settings.tableColumn']);
  const dataSource = [];

  keys.forEach((key, index) => {
    const modifiedBy =
      rowModified.length && !isEmpty(rowModified[index])
        ? rowModified[index]
        : undefined;
    dataSource.push(
      attrs.reduce(
        (data, { attributeName, isArray }) => {
          if (sectionData[attributeName] === undefined) {
            data[attributeName] = null;
            message.error(
              `There is not value for the following attribute ${attributeName}. Please check the configuration`
            );
          } else if (isArray) {
            data[attributeName] = Array.isArray(
              sectionData[attributeName][index]
            )
              ? sectionData[attributeName][index].join(';')
              : sectionData[attributeName][index];
          } else if (typeof sectionData[attributeName][index] === 'string') {
            const unescapedData = unescape(sectionData[attributeName][index]);
            data[attributeName] = unescapedData;
          } else {
            data[attributeName] = sectionData[attributeName][index];
          }
          return data;
        },
        { key, modifiedBy, index }
      )
    );
  });

  return { attrs, dataSource };
}
