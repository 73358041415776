import React, { useState } from 'react';
import { Button, Input, Form, Select, Row, Col } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';
import { isEmpty } from 'lodash';

import StyledModal from './AddStatusModal.styled';

const FormItem = Form.Item;
const { Option } = Select;
const statusTypeValues = [
  { label: 'Open', value: 'OPEN' },
  { label: 'Paused', value: 'PAUSED' },
];

const layout = {
  labelCol: {
    span: 5,
    offset: 4,
  },
  wrapperCol: {
    span: 12,
  },
};

function AddStatusModal(props) {
  const [statusName, setStatusName] = useState('');
  const [statusType, setStatusType] = useState(null);
  const { visible } = props;
  const disableSave = isEmpty(statusName.trim()) || statusType === null;

  function initData() {
    setStatusName('');
  }

  function handleStatusName(e) {
    const { value } = e.target;
    setStatusName(value);
  }

  function handleStatusType(value) {
    setStatusType(value);
  }

  function handleSave() {
    const { onOk } = props;
    onOk && onOk(statusName, statusType);
    initData();
  }

  function handleCancel() {
    const { onCancel } = props;
    onCancel && onCancel();
    initData();
  }

  return (
    <StyledModal
      title={'New Status'}
      width={1000}
      centered
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="Save"
          type="primary"
          onClick={handleSave}
          disabled={disableSave}
        >
          Save
        </Button>,
      ]}
    >
      <Row>
        <Col span={11}>
          <FormItem {...layout} label="Status Name" colon={false}>
            <Input
              value={htmlUnescape(statusName)}
              onChange={handleStatusName}
            />
          </FormItem>
        </Col>
        <Col span={11}>
          <FormItem {...layout} label="Status Type" colon={false}>
            <Select
              allowClear
              value={statusType}
              style={{ width: '100%' }}
              onChange={handleStatusType}
            >
              {statusTypeValues.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
      </Row>
    </StyledModal>
  );
}

export default AddStatusModal;
