import React, { useState } from 'react';

const useSortData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState('');
  const fetchDataCallback = (pagination, filters, sort) => {
    if (sort?.column && sort?.order) {
      setSort(`${sort.column}:${sort.order === 'ascend' ? 'asc' : 'desc'}`);
    } else {
      setSort('');
    }
    setCurrentPage(pagination.currentPage);
  };

  return { sort, currentPage, fetchDataCallback };
};

export default useSortData;
