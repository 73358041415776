import moment from 'moment';

import {
  FETCH_ACTION_ITEMS_FEED_REQUEST,
  FETCH_ACTION_ITEMS_FEED_SUCCESS,
  FETCH_ACTION_ITEMS_FEED_ERROR,
  FETCH_ACTION_ITEMS_FEED_COUNT_SUCCESS,
} from '../../../actions';

export default (initialState = {}) => ({
  [FETCH_ACTION_ITEMS_FEED_REQUEST]: (state, { payload: { page } }) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      page,
      loading: true,
    },
  }),
  [FETCH_ACTION_ITEMS_FEED_SUCCESS]: (
    state,
    { payload: { data, count: total } }
  ) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      loading: false,
      error: null,
      fetched_at: moment().unix(),
      total,
      data: data.map(item => item.id),
      entries: data.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item,
        }),
        {}
      ),
    },
  }),
  [FETCH_ACTION_ITEMS_FEED_ERROR]: (state, { payload: error }) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      loading: false,
      error,
    },
  }),
  [FETCH_ACTION_ITEMS_FEED_COUNT_SUCCESS]: (state, { payload: { count } }) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      unread_count: count,
    },
  }),
});
