import React, { useState, useEffect, useReducer } from 'react';
import {
  Select,
  Button,
  DatePicker,
  Form,
  Spin,
  notification,
  Icon,
} from 'antd';
import moment from 'moment';
import { unescape as htmlUnescape } from 'html-escaper';
import caseFileEndpoints from '../../../../api/caseFileEndpoints/caseFileEndpoints';
import { getOfficersAndCivilians } from '../../../../api/users';
import {
  setStatusesLoading,
  setStatusesLoadingSuccessful,
  setStatusesLoadingError,
  setOutcomesLoading,
  setOutcomesLoadingSuccessful,
  setOutcomesLoadingError,
  setReasonsLoading,
  setReasonsLoadingSuccessful,
  setReasonsLoadingError,
} from './actions';
import { initialState, reducer } from './reducer';
import {
  AdvanceFilterWrapper,
  FormButtons,
  StyledButton,
} from './CasefileListFilters.styled';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Item } = Form;

const CaseFileListFilters = ({
  setTableData,
  tenantId,
  agencyId,
  enableCasefileKeyDetails,
  form,
  ...props
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [keyDetails, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const NOTIFICATION_SUCCESS = 'success';
  const NOTIFICATION_ERROR = 'error';

  function showError(err) {
    const message = err.message || 'API response is not ok';
    showNotification(NOTIFICATION_ERROR, 'Error', message);
  }

  function showSuccess(message) {
    showNotification(NOTIFICATION_SUCCESS, 'Success', message);
  }

  function showNotification(type, message, description) {
    return notification[type]({ message, description });
  }

  const disabledFutureDays = current => {
    return !(current && current < moment().endOf('day'));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newFilters = form.getFieldsValue();
    const dateFields = ['deadline', 'createdDate'];

    for (let [key, value] of Object.entries(newFilters)) {
      if (
        !value ||
        (dateFields.includes(key) && value.length === 0) ||
        (typeof value === 'object' && value.length === 0)
      ) {
        delete newFilters[key];
        continue;
      }

      if (key === 'deadline') {
        const deadlineStart = value[0].format('YYYY-MM-DD');
        const deadlineEnd = value[1].format('YYYY-MM-DD');
        delete newFilters[key];
        newFilters['deadlineStart'] = deadlineStart;
        newFilters['deadlineEnd'] = deadlineEnd;
        continue;
      }

      if (key === 'createdDate') {
        const createdAtStart = value[0].format('YYYY-MM-DD');
        const createdAtEnd = value[1].format('YYYY-MM-DD');
        delete newFilters[key];
        newFilters['createdAtStart'] = createdAtStart;
        newFilters['createdAtEnd'] = createdAtEnd;
        continue;
      }
    }

    setFilters(form.getFieldsValue());
    setTableData(newFilters);
    setIsFilterVisible(false);
  };

  const handleResetFilters = () => {
    form.resetFields();
    setFilters({});
    setTableData({});
    setIsFilterVisible(false);
  };

  const fetchStatuses = () => {
    if (keyDetails.statuses.length > 0) {
      return;
    }

    dispatch(setStatusesLoading());
    caseFileEndpoints
      .getStatusListDashboard(tenantId)
      .then(response => {
        const statuses = formatDataValuesLikeLabels(response);
        dispatch(setStatusesLoadingSuccessful(statuses));
      })
      .catch(e => {
        showError(e);
        dispatch(setStatusesLoadingError());
      });
  };

  const fetchReasons = () => {
    if (keyDetails.reasons.length > 0) {
      return;
    }

    dispatch(setReasonsLoading());
    caseFileEndpoints
      .getReasonsListDashboard(tenantId)
      .then(response => {
        const reasons = formatDataValuesLikeLabels(response);
        dispatch(setReasonsLoadingSuccessful(reasons));
      })
      .catch(e => {
        showError(e);
        dispatch(setReasonsLoadingError());
      });
  };

  const fetchOutcomes = () => {
    if (keyDetails.outcomes.length > 0) {
      return;
    }

    dispatch(setOutcomesLoading());
    caseFileEndpoints
      .getOutcomesListDashboard(tenantId)
      .then(response => {
        const outcomes = formatDataValuesLikeLabels(response);
        dispatch(setOutcomesLoadingSuccessful(outcomes));
      })
      .catch(e => {
        showError(e);
        dispatch(setOutcomesLoadingError());
      });
  };

  const fetchUsers = () => {
    if (users.length > 0) {
      return;
    }
    setLoadingUsers(true);
    getOfficersAndCivilians(agencyId)
      .then(response => {
        const data = response.map(({ integrationId, fullName }) => ({
          id: integrationId,
          label: fullName,
        }));
        setUsers(data);
      })
      .catch(e => showError(e))
      .finally(setLoadingUsers(false));
  };

  const formatDataValuesLikeLabels = ({ content }) => {
    const uniqueValues = [];
    content.forEach(element => {
      const isAlreadyInArray = uniqueValues.includes(element.label);
      if (!isAlreadyInArray) {
        uniqueValues.push(htmlUnescape(element.label));
      }
    });

    const newArray = uniqueValues.map(el => ({ id: el, label: el }));
    return newArray;
  };

  const renderValuesOptions = values =>
    values.map(({ id, label }) => <Option key={id}>{label}</Option>);

  const renderValuesLabelOptions = values =>
    values.map(({ id, label }) => (
      <Option key={id} value={label}>
        {label}
      </Option>
    ));

  const filtersIcon = isFilterVisible ? 'minus' : 'plus';

  const filterOptionBySearch = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (!isFilterVisible || Object.keys(filters).length === 0) {
      return;
    }
    form.setFieldsValue(filters);
  }, [isFilterVisible]);

  const { statuses, reasons, outcomes } = keyDetails;
  return (
    <div style={{ position: 'relative', marginLeft: 'auto' }}>
      <StyledButton
        type={isFilterVisible ? 'secondary' : 'primary'}
        onClick={() => setIsFilterVisible(!isFilterVisible)}
      >
        <Icon type={filtersIcon} /> Advanced Filters
      </StyledButton>
      {isFilterVisible && (
        <AdvanceFilterWrapper>
          <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Item label="Owner">
              {getFieldDecorator('owners')(
                <Select
                  allowClear
                  showSearch
                  mode="multiple"
                  placeholder="Owner"
                  onFocus={fetchUsers}
                  notFoundContent={
                    loadingUsers ? <Spin size="small" /> : 'No users found'
                  }
                  filterOption={filterOptionBySearch}
                  dropdownStyle={{ position: 'fixed' }}
                >
                  {renderValuesOptions(users)}
                </Select>
              )}
            </Item>
            <Item label="Creation Date">
              {getFieldDecorator('createdDate', {
                rules: [{ required: false }],
              })(<RangePicker disabledDate={disabledFutureDays} allowClear />)}
            </Item>
            {enableCasefileKeyDetails && (
              <Item label="Deadline">
                {getFieldDecorator('deadline')(<RangePicker allowClear />)}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Status">
                {getFieldDecorator('statuses')(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    loading={statuses.loading}
                    placeholder="Status"
                    onFocus={fetchStatuses}
                    notFoundContent={
                      statuses.loading ? (
                        <Spin size="small" />
                      ) : (
                        'No statuses found'
                      )
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                  >
                    {renderValuesLabelOptions(statuses.value)}
                  </Select>
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Reasons">
                {getFieldDecorator('reasons')(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    loading={reasons.loading}
                    placeholder="Reasons"
                    onFocus={fetchReasons}
                    notFoundContent={
                      reasons.loading ? (
                        <Spin size="small" />
                      ) : (
                        'No reasons found'
                      )
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                  >
                    {renderValuesLabelOptions(reasons.value)}
                  </Select>
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Outcomes">
                {getFieldDecorator('outcomes')(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    loading={outcomes.loading}
                    placeholder="Outcomes"
                    onFocus={fetchOutcomes}
                    notFoundContent={
                      outcomes.loading ? (
                        <Spin size="small" />
                      ) : (
                        'No reasons found'
                      )
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                  >
                    {renderValuesLabelOptions(outcomes.value)}
                  </Select>
                )}
              </Item>
            )}
            {enableCasefileKeyDetails && (
              <Item label="Individual">
                {getFieldDecorator('individuals')(
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    placeholder="Individual"
                    onFocus={fetchUsers}
                    notFoundContent={
                      loadingUsers ? <Spin size="small" /> : 'No users found'
                    }
                    filterOption={filterOptionBySearch}
                    dropdownStyle={{ position: 'fixed' }}
                  >
                    {renderValuesOptions(users)}
                  </Select>
                )}
              </Item>
            )}
            <FormButtons>
              <Button type="danger" onClick={handleResetFilters}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit">
                Apply
              </Button>
            </FormButtons>
          </Form>
        </AdvanceFilterWrapper>
      )}
    </div>
  );
};

const WrappedCaseFileListForm = Form.create({ name: 'casefile_filters' })(
  CaseFileListFilters
);

export default WrappedCaseFileListForm;
