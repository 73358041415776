import agencyUsersRoutes from '../agency-users/AgencyUsers.routes';
import configureAgencyTemplatesRoutes from '../configure-templates/ConfigureTemplates.routes';
import configureAgencyWorkflowsRoutes from '../configure-workflows/ConfigureWorkflows.routes';
import formBuilderRoutes from '../form-builder/FormBuilder.routes';
import rolesPermissionsRoutes from '../../../modules/Security/security.routing';
import agencyReportsRoutes from '../agency-reports/AgencyReports.routes';
import agencyAuditLogs from '../agency-audit-logs/AgencyAuditLogs.routes';
import agencyMigratedFilesRoutes from '../agency-migrated-files/AgencyMigratedFiles.routes';
import agencyBulkUsersImportRoutes from '../agency-bulk-users-import/AgencyBulkUsersImport.routes';
import agencyAuditViewLogs from '../agency-audit-view-logs/AgencyAuditViewLogs.routes';
import agencyAuditLogsReportTimeline from '../agency-audit-logs-report-timeline/AgencyAuditLogsReportTimeline.routes';
import agencyLiveEventCheckIn from '../agencies/agency-live-event-check-in/AgencyLiveEventCheckIn.routes';
import agencyCasefilesRoutes from '../agency-casefiles/AgencyCasefiles.routes';
import Agency from './AgencyProfile';
import { PERMISSIONS } from '../../../utils/admin';
import permissionAttributes from '../permission-attributes/PermissionAccessView.routes';

export default ({ path = '', name = '' }) => {
  const routeConfig = {
    path: `${path}/:agencyId`,
    name,
  };

  return [
    {
      ...routeConfig,
      component: Agency,
      protectedWith: [
        PERMISSIONS.manageAgencies,
        PERMISSIONS.createAnyUser,
        PERMISSIONS.createAgencyUser,
        PERMISSIONS.updateAnyUser,
        PERMISSIONS.updateAgencyUser,
        PERMISSIONS.manageWorkflows,
        PERMISSIONS.viewAllReports,
        PERMISSIONS.accessAnalyticsReports,
        PERMISSIONS.manageForms,
        PERMISSIONS.accessTrainingAdminPanel,
        PERMISSIONS.createRole,
        PERMISSIONS.assignRole,
        PERMISSIONS.viewAllAuditLogs,
        PERMISSIONS.viewAgencyAuditLogs,
        PERMISSIONS.searchDocumentMetadata,
        PERMISSIONS.manageRule28DataExport,
        PERMISSIONS.manageReportDataExport,
        PERMISSIONS.manageTMSLiveEvents,
        PERMISSIONS.manageCaseFileTypes,
      ],
      childRoutes: [
        ...permissionAttributes(routeConfig),
        ...agencyUsersRoutes(routeConfig),
        ...configureAgencyTemplatesRoutes(routeConfig),
        ...configureAgencyWorkflowsRoutes(routeConfig),
        ...formBuilderRoutes(routeConfig),
        ...rolesPermissionsRoutes(routeConfig),
        ...agencyReportsRoutes(routeConfig),
        ...agencyAuditLogs(routeConfig),
        ...agencyMigratedFilesRoutes(routeConfig),
        ...agencyBulkUsersImportRoutes(routeConfig),
        ...agencyAuditViewLogs(routeConfig),
        ...agencyAuditLogsReportTimeline(routeConfig),
        ...agencyLiveEventCheckIn(routeConfig),
        ...agencyCasefilesRoutes(routeConfig),
      ],
    },
  ];
};
