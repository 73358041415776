import moment from 'moment';

import {
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  REFRESH_ACTIVITIES_REQUEST,
  REFRESH_ACTIVITIES_SUCCESS,
  REFRESH_ACTIVITIES_ERROR,
} from '../../../actions';

export default (initialState = {}) => ({
  [FETCH_ACTIVITIES_REQUEST]: state => ({
    ...state,
    activities: {
      ...state.activities,
      loading: true,
    },
  }),
  [FETCH_ACTIVITIES_SUCCESS]: (state, { payload }) => ({
    ...state,
    activities: {
      ...state.activities,
      loading: false,
      error: null,
      fetched_at: moment().unix(),
      total: payload.length,
      data: payload.map(item => item.id),
      entries: payload.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item,
        }),
        {}
      ),
    },
  }),
  [FETCH_ACTIVITIES_ERROR]: (state, { payload: error }) => ({
    ...state,
    activities: {
      ...state.activities,
      loading: false,
      error,
    },
  }),
  [REFRESH_ACTIVITIES_REQUEST]: state => ({
    ...state,
    activities: {
      ...state.activities,
      loading: false,
      refreshing: true,
    },
  }),
  [REFRESH_ACTIVITIES_SUCCESS]: (state, { payload }) => ({
    ...state,
    activities: {
      ...state.activities,
      refreshing: false,
      loading: false,
      error: null,
      refreshed_at: moment().unix(),
      total: payload.length,
      data: payload.map(item => item.id),
      entries: payload.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item,
        }),
        {}
      ),
    },
  }),
  [REFRESH_ACTIVITIES_ERROR]: (state, { payload: error }) => ({
    ...state,
    activities: {
      ...state.activities,
      refreshing: false,
      loading: false,
      error,
    },
  }),
});
