import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Button, Icon, Row, Col, Upload, notification } from 'antd';
import { genOptions, route, fetchRequest } from 'APP_ROOT/utils/request';
import urlBuilder from 'APP_ROOT/utils/url-builder';
import storage from 'APP_ROOT/utils/storage';
import sectionModalCancel from '../sectionModalCancel';
import SectionAttribute from '../SectionAttribute';
import FILE_STATUSES from '../constants/file-statuses.constants';
import { get } from 'lodash';
import {
  ATTRIBUTE_TYPE_TEXTAREA,
  SaveSectionWarning,
} from '../UserProfile.constants';
import { connect } from 'react-redux';

import { Fragment } from 'react';
import Select from 'antd/lib/select';
import { Field } from 'redux-form';
import Input from '../../../components/input';
import organizationEndpoints from '../../../../../api/organization/organizationEndpoints';
import showErrorMessages from '../errors/OuErrorMessages';

import FileSizeLimit from '../../../../../components/form-viewer/forms/utils/fileSizeLimit';

const Option = Select.Option;
const SelectField = Input(Select);

// options

const documentTypeOptions = [
  <Option key="other-document" value="Other">
    Other
  </Option>,
  <Option key="certification" value="Certification">
    Certification
  </Option>,
  <Option key="drivers-licence" value="Driver’s License">
    Driver’s License
  </Option>,
  <Option key="fingerprint" value="Fingerprint">
    Fingerprint
  </Option>,
  <Option key="profile-picture" value="Profile Picture">
    Profile Picture
  </Option>,
];

const [DONE, ERROR] = FILE_STATUSES;

const PREFIX = 'userDocumentsData';
const ATTRIBUTE_ID = 'id';
const uploadErrorMessage =
  'This file is too large or is an invalid type. Select files filtered as Custom Files.';
let documentExtension = '';
let currentDocumentType = '';

const fieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-5 ant-col-lg-5',
  inputCol: 'ant-col-xs-24 ant-col-sm-16 ant-col-md-19 ant-col-lg-19',
};

class UserDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editForm: null,
      isNew: false,
      currentRecords: [],
      uploadInputKey: 'uploadInput',
      hasUploadedFile: false,
      fileList: [],
    };
  }

  onBeforeUpload = file => {
    //No action
  };
  /**
   * Calls node-api to get S3 ready link to upload a file
   * It also gets attachment information of the upload link
   * @param fileName String of the file name to upload
   * @param fileType String of the data type of the file
   * @param fileSize Number describing byte size of the file
   * @returns {Promise<{postUrl: (string), attachmentId: (number)}>}
   */
  getUploadUrl = async (fileName, fileType, fileSize) => {
    const options = genOptions(
      'POST',
      {
        fileName,
        fileType,
        fileSize,
      },
      storage.get('token')
    );
    const { profileForm } = this.props;
    const { registeredDocumentId: id } = get(
      profileForm,
      'values.userDocumentsData'
    );
    const request = route(
      urlBuilder('/UserDocuments/:id/upload/request-url', {
        id,
      })
    );
    const response = await fetchRequest(request, options);
    if (!response || !response.url) {
      throw Error('Unable to upload at the moment');
    }
    return {
      postUrl: response.url,
      attachmentId: response.attachmentId,
    };
  };
  /**
   * Custom method that sends file to S3.
   * By default, antd (ant design) sends a POST request on their Upload element
   * and S3 needs a PUT request + with no authorization headers
   * @param antdUploadElementData
   */
  uploadOverride = async antdUploadElementData => {
    const { file, onError, onProgress, onSuccess } = antdUploadElementData;
    const { postUrl, attachmentId } = await this.getUploadUrl(
      file.name,
      file.type,
      file.size
    );
    onProgress({ percent: 0 });
    const xhr = new XMLHttpRequest();
    const response = await new Promise(resolve => {
      xhr.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      });
      xhr.addEventListener('loadend', () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open('PUT', postUrl, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
    if (response) {
      const { profileForm } = this.props;
      const { registeredDocumentId: id } = get(
        profileForm,
        'values.userDocumentsData'
      );
      // S3 uploads do not return node-api attachment record
      const options = genOptions('GET', {}, storage.get('token'));
      const getAttachmentRoute = route(
        urlBuilder('/UserDocuments/:id/attachments/:attachmentId', {
          id,
          attachmentId,
        })
      );
      const attachment = await fetchRequest(getAttachmentRoute, options);
      onSuccess(attachment);
    } else {
      onError(new Error('unable to get a response'));
    }
  };

  componentDidUpdate(prevProps) {
    if (!this.props.visible && this.props.visible !== prevProps.visible) {
      this.setState({ hasUploadedFile: false }, this.resetUploadInput);
    }
  }

  getCurrentValue = () => {
    return get(
      this.props.profileForm,
      `values.userDocumentsData.documents.documentType[${this.props.rowIndex}]`
    );
  };

  resetUploadInput = () => {
    const newKey = `uploadInput-${Math.random() * 10}`;
    this.setState({ uploadInputKey: newKey });
  };

  getURL = () => {
    const { profileForm } = this.props;
    const { registeredDocumentId: id } = get(
      profileForm,
      'values.userDocumentsData'
    );
    return route(
      urlBuilder('/UserDocuments/:id/upload', {
        id,
      })
    );
  };

  getDownloadURL = fileId => {
    const { profileForm } = this.props;
    const { registeredDocumentId: id } = get(
      profileForm,
      'values.userDocumentsData'
    );
    return route(
      urlBuilder('/UserDocuments/:id/download/request-url?id=:fileId', {
        id,
        fileId,
      })
    );
  };

  onChange = ({ file = {} }) => {
    const { status, response = {} } = file;
    const { rowIndex, updateSectionField, currentUserFullName } = this.props;

    switch (status) {
      case DONE:
        this.setState({ hasUploadedFile: true, fileList: [file] });
        const locationUrl = this.getDownloadURL(response.id);

        updateSectionField(
          `userDocumentsData.documents.locationUrl[${rowIndex}]`,
          locationUrl
        );

        updateSectionField(
          `userDocumentsData.documents.fileName[${rowIndex}]`,
          response.name || response.originalFilename
        );

        updateSectionField(
          (documentExtension = `${response.mimeType.split('/')[1]}`)
        );

        const currentType = get(
          this.props.profileForm,
          `values.userDocumentsData.documents.documentType[${rowIndex}]`
        );

        if (!currentType) {
          updateSectionField(
            `userDocumentsData.documents.documentType[${rowIndex}]`,
            'Other'
          );
        }

        //Used date format LLL instead of standard as this was the format specified in the design.
        //Changed to use the fullname of the logged user instead of the current profile name.
        updateSectionField(
          `userDocumentsData.documents.uploaded[${rowIndex}]`,
          `Uploaded ${moment().format('LLL')} by ${currentUserFullName}`
        );

        break;
      case ERROR:
        notification.error({
          message: 'Upload error',
          description: uploadErrorMessage,
        });

        break;
      // case UPLOADING:
      //   console.log("UPLOADING");
      //   break;
      default:
        break;
    }
  };

  onRemoveFileOnStorage = () => {
    //No action
  };

  saveUpload = () => {
    //No action
  };

  getRowIndex = key => {
    const { profileForm, sectionId } = this.props;
    const keys = get(
      profileForm,
      `values.${PREFIX}.${sectionId}.${ATTRIBUTE_ID}`,
      []
    );
    return keys.findIndex(k => k === key);
  };

  onCancelEdit = () => {
    const { editForm } = this.state;
    const props = {
      updateSectionField: this.props.updateSectionField,
      removeSectionFieldValue: this.props.removeSectionFieldValue,
      attributes: this.props.sectionAttributes,
      profileForm: this.props.profileForm,
      prefix: PREFIX,
      sectionId: this.props.sectionId,
      attributeId: ATTRIBUTE_ID,
    };

    sectionModalCancel(editForm.isNew, editForm.record, props);
    this.setState({ editForm: null });
  };

  onOkEdit = () => {
    this.setState({ editForm: null });
  };

  validDocumentType = () => {
    currentDocumentType = this.getCurrentValue();
    return currentDocumentType !== 'Profile Picture'
      ? true
      : currentDocumentType === 'Profile Picture' &&
          ['jpg', 'png', 'jpeg'].includes(documentExtension);
  };

  onModalOk = () => {
    const { onOk, isNew } = this.props;
    const { hasUploadedFile } = this.state;

    if (!this.validDocumentType()) return;
    if (isNew) {
      if (hasUploadedFile) {
        this.setState({ editForm: null, isNew: false });
        onOk && onOk();
      }
    } else {
      this.setState({ editForm: null, isNew: false });
      onOk && onOk();
    }
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    this.setState({ editForm: null, isNew: false });
    onCancel && onCancel();
  };

  softDeleteCurrentDocument = async () => {
    const {
      dispatch,
      userProfile: { integrationId: userId },
      sectionId: documentId,
    } = this.props;

    try {
      await organizationEndpoints.deleteUserDocument(userId, documentId);

      window.location.reload();
    } catch (err) {
      showErrorMessages(dispatch, err);
    }
  };

  createDeleteModal = async () => {
    Modal.confirm({
      title: 'Heads Up!',
      okText: 'Yes',
      content: (
        <p>You cannot undo deleting this record; do you want to continue?</p>
      ),
      onOk: this.softDeleteCurrentDocument,
    });
  };

  generateButtonDefinitions = okLabel => ({
    readOnly: {
      buttons: [
        { key: 'done', text: 'Done', type: 'primary', onClick: this.onModalOk },
      ],
    },
    isNew: {
      buttons: [
        { key: 'cancel', text: 'Cancel', onClick: this.onModalCancel },
        {
          key: 'save',
          type: 'primary',
          text: okLabel,
          onClick: this.onModalOk,
          disabled: !this.state.hasUploadedFile,
        },
      ],
    },
    regular: {
      buttons: [
        {
          key: 'delete',
          type: 'danger',
          text: 'Delete',
          onClick: this.createDeleteModal,
        },
        {
          key: 'cancel',
          text: 'Cancel',
          style: { marginLeft: 'auto' },
          onClick: this.onModalCancel,
        },
        {
          key: 'Save',
          type: 'primary',
          text: okLabel,
          onClick: this.onModalOk,
        },
      ],
      style: {
        display: 'flex',
      },
    },
  });

  generateFooter = (readOnly, isNew, okLabel) => {
    const type = readOnly ? 'readOnly' : isNew ? 'isNew' : 'regular';
    const buttonSet = this.generateButtonDefinitions(okLabel)[type];

    return (
      <div style={buttonSet.style}>
        {buttonSet.buttons.map(button => (
          <Button
            key={button.key}
            type={button.type}
            onClick={button.onClick}
            style={button.style}
            disabled={button.disabled}
          >
            {button.text}
          </Button>
        ))}
      </div>
    );
  };

  render = () => {
    const {
      title,
      visible,
      profileForm,
      rowIndex,
      readOnly,
      isNew,
      showDocumentType = true,
      showSaveWarning = true,
      okLabel = 'Apply changes',
    } = this.props;

    return (
      <Modal
        title={title}
        width={1000}
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={this.generateFooter(readOnly, isNew, okLabel)}
      >
        {isNew && (
          <Row
            type="flex"
            justify="center"
            style={{ paddingBottom: '22px', paddingTop: '22px' }}
          >
            <Col>
              <Upload
                showUploadList={true}
                beforeUpload={this.onBeforeUpload}
                name="fileData"
                headers={{
                  Authorization: storage.get('token'),
                }}
                action={this.getURL()}
                onChange={this.onChange}
                onRemove={this.onRemoveFileOnStorage}
                customRequest={this.uploadOverride}
                ref={this.saveUpload}
                multiple={false}
                key={this.state.uploadInputKey}
                disabled={readOnly}
              >
                <Button>
                  Select File <Icon type="upload" />
                </Button>
              </Upload>
            </Col>
          </Row>
        )}
        <Row style={{ paddingBottom: '22px', paddingTop: '22px' }}>
          {showDocumentType && (
            <Col xs={24}>
              <Field
                type="select"
                name={`userDocumentsData.documents.documentType[${rowIndex}]`}
                data-test="user-document"
                label={'Document Type'}
                placeholder="Please Select"
                component={SelectField}
                dropdownOptions={documentTypeOptions}
                disabled={readOnly}
                defaultValue={'Other'}
                inputProps={{ className: 'full-width' }}
                options={fieldOptions}
              />
            </Col>
          )}
          <Col xs={24}>
            <SectionAttribute
              key="key-user-document"
              name={`userDocumentsData.documents.description[${rowIndex}]`}
              title="File Description"
              type={ATTRIBUTE_TYPE_TEXTAREA}
              validValues={null}
              unique={true}
              profileForm={profileForm}
              isArray={false}
              readOnly={readOnly}
              data-test="user-document"
            />
          </Col>
        </Row>
        {!readOnly && (
          <Fragment>
            <Row type="flex" justify="center">
              <Col>
                <Icon type="exclamation-circle" />
                <em>
                  Leave Custom Types filter to ensure you select a valid file
                  type.
                </em>
              </Col>
            </Row>
            {showSaveWarning && (
              <Row type="flex" justify="center">
                <Col>
                  <Icon type="exclamation-circle" />
                  <SaveSectionWarning />
                </Col>
              </Row>
            )}
            <Row type="flex" justify="center">
              <FileSizeLimit />
            </Row>
          </Fragment>
        )}
      </Modal>
    );
  };
}

const mapState = state => {
  const currentUserFullName = get(state, 'session.currentUser.fullName', '');
  return { currentUserFullName };
};

export default connect(mapState)(UserDocumentModal);
