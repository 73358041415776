const MIN_DIRECT_SEARCH_WEIGHT = -1;
const MAX_DIRECT_SEARCH_WEIGHT = 0.6;
const MIN_DIRECT_SEARCH_LENGTH = 2;

const calculateDistance = (searchTerm, target) => {
  const sanitizedSearchTerm = sanitizeSearchTerm(searchTerm)
  const regex = new RegExp(`^${sanitizedSearchTerm}`, 'i');
  const matchesStart = target.match(regex) !== null;
  const levenshtein = levenshteinDistance(searchTerm, target);

  if (matchesStart) return levenshtein - 2; // make sure these are first in sort
  return levenshtein;
};
const levenshteinDistance = (searchTerm, target) => {
  if (!searchTerm.length) return target.length;
  if (!target.length) return searchTerm.length;
  const arr = [];
  for (let i = 0; i <= target.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= searchTerm.length; j++) {
      arr[i][j] =
        i === 0
          ? j
          : Math.min(
              arr[i - 1][j] + 1,
              arr[i][j - 1] + 1,
              arr[i - 1][j - 1] + (searchTerm[j - 1] === target[i - 1] ? 0 : 1)
            );
    }
  }
  return arr[target.length][searchTerm.length];
};

const fuzzySearch = (searchTerm, items = [], distanceCap = 2) => {
  items = items.filter(v => !!v);
  if (items.length === 0) return [];
  const type = typeof items[0];
  if (!['string', 'object'].includes(type))
    throw Error('Incorrect items array');
  if (type === 'object') {
    if (typeof items[0].label !== 'string')
      throw Error(
        'Items array with objects must contain a label or value property that is a string'
      );
  }

  searchTerm = searchTerm.toLowerCase().trim();
  let hasNested = false;
  let filterArr = items.map(item => {
    let label;
    let enums;
    // handle nested form
    if (type == 'object' && item.enums)
      enums = item.enums.map(childItem => {
        hasNested = true;
        const isDirectMatch = search(searchTerm, childItem.label);
        return {
          label: childItem.label,
          distance:
            isDirectMatch !== false
              ? isDirectMatch
              : calculateDistance(
                  searchTerm,
                  childItem.label.toLowerCase().trim()
                ),
          value: childItem.value,
        };
      });
    if (type == 'object') label = item.label || item.value;
    else label = item;
    const isDirectMatch = search(searchTerm, label);
    const withDistance = {
      label,
      distance:
        isDirectMatch !== false
          ? isDirectMatch
          : calculateDistance(searchTerm, label.toLowerCase().trim()),
    };
    if (item.enums) withDistance.enums = enums;
    else withDistance.value = item.value;

    return withDistance;
  });

  if (hasNested) {
    // filter children
    return filterArr
      .map(item => {
        if (!item.enums) return item;
        const filteredOptions = item.enums
          .filter(({ distance }) => distance <= distanceCap)
          .sort((a, b) => a.distance - b.distance);
        // no child items are available for this group, ignore the group
        if (filteredOptions.length === 0) return null;
        return {
          ...item,
          enums: filteredOptions,
        };
      })
      .filter(v => !!v);
  } else {
    return filterArr
      .filter(({ distance }) => distance <= distanceCap)
      .sort((a, b) => a.distance - b.distance);
  }
};

const search = (searchTerm, item) => {
  if (searchTerm.length < MIN_DIRECT_SEARCH_LENGTH) return false;

  const index = item.toLowerCase().indexOf(searchTerm.toLowerCase());
  const ratio = index / item.length;
  if (index > MIN_DIRECT_SEARCH_WEIGHT && ratio < MAX_DIRECT_SEARCH_WEIGHT) {
    return ratio;
  }
  return false;
};

const sanitizeSearchTerm = (searchTerm) => {
  const specialCharactersRegex = /[.*+?^${}()|[\]\\]/g;
  const escapeCharacter = '\\$&';
  return searchTerm.replace(specialCharactersRegex, escapeCharacter);
};

export default fuzzySearch;
