import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon, Button, Spin, Select } from 'antd';
import {
  set,
  get,
  has,
  isEmpty,
  filter,
  isArray,
  uniqBy,
  first,
  template as interpolateTemplate,
  debounce,
  findIndex,
  isPlainObject,
} from 'lodash';
import trap from 'mousetrap';
import URLSearchParams from 'url-search-params';
import FormViewer from 'APP_ROOT/components/form-viewer';
import ModalTitle from 'APP_ROOT/components/common/modal/title';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import FormHistoryTimeline from 'APP_ROOT/components/form-history-timeline';
import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import hideModal from 'APP_ROOT/actions/hide-modal';
import postDraftReport from 'APP_ROOT/actions/post-draft-report';
import updateDraftReport from 'APP_ROOT/actions/update-draft-report';
import getFormActions from 'APP_ROOT/actions/get-form-actions';
import postReport from 'APP_ROOT/actions/post-report';
import deleteReport from 'APP_ROOT/actions/delete-report';
import deleteReportSoftDelete from 'APP_ROOT/actions/delete-report-softdelete';
import syncFormValidation from 'APP_ROOT/actions/sync-form-validations';
import deleteAllAtachments from 'APP_ROOT/actions/delete-all-attachments';
import deleteFormLink from 'APP_ROOT/actions/delete-form-link';
import withModal from 'APP_ROOT/components/common/modal/base';
import flattenErrors from 'APP_ROOT/utils/flatten-errors';
import addUserReportSuccess from 'APP_ROOT/actions/add-user-report-success';
import {
  getDataFromConditions,
  isFinalLinkedSubmission,
  pendingLinkedSubmissions,
  isLinked,
} from 'APP_ROOT/utils/form';
import route from 'APP_ROOT/utils/get-route';
import transformFormToNormalizedTimeline from 'APP_ROOT/utils/transform-form-to-normalized-timeline';
import groupReviewers from 'APP_ROOT/utils/group-reviewers';
import { GRAY_2 } from 'APP_ROOT/config/theme';
import { REQUEST_MORE_INFO } from 'APP_ROOT/constants/submit-actions';
import deleteConfirmationModalOptions from './deleteConfirmationModalOptions';
import SubmitConfirmationModal from '././submitConfirmationModal';
import onError from './onError';
import { hasPermissions, PERMISSIONS } from '../../../utils/admin';
import generateReportPrintWithAttachments from './generateReportPrintWithAttachments';

import getErrorMessageModalBody from '../../../utils/getErrorMessageModalBody';
import getEditedErrors from './getEditedErrors';

import getModalBody from './getModalBody';
import getModalOptions from './getModalOptions';
import getTransformedData from './getTransformedData';
import propsHasChanged from '../../../utils/propsHasChanged.js';

const Option = Select.Option;

const deleteReportConfirmModalID = 'report-delete-request';

class ReportContainer extends withModal(Component) {
  state = {
    shouldValidate: true,
    selectedReviewer: null,
    reviewerNotes: {
      notes: '',
    },
  };

  whiteListKeys = [
    '__users',
    'currentUserId',
    'submitterId',
    '__reports',
    'encrypted',
    'externalId',
    '__assignedSections',
    '__assignedSectionsHistory',
  ];

  printTriggered = false;
  hasAttachments = false;
  hasNotes = false;

  componentWillMount() {
    const { location } = this.props;

    const shouldValidateFromQuery = () => {
      const query = new URLSearchParams(location.search);
      const validate = query.get('validate');
      return validate !== null ? JSON.parse(validate) : true;
    };

    this.setState({ shouldValidate: shouldValidateFromQuery() });

    this.createModal();

    trap.bind(...this.onPrintEvent());
  }

  componentDidMount() {
    const { selectedForm } = this.props;
    const isPrinting = this.isPrinting();

    if (isPrinting) {
      const template = get(selectedForm, 'template.formSchema.form', {});

      const hasAttachments = obj => {
        const cb = o => o.type === 'upload';

        const asserts = cb(obj);

        if (asserts) {
          return true;
        }

        if (has(obj, 'properties')) {
          return obj.properties.reduce((res, cur) => {
            if (res) {
              return res;
            }

            return hasAttachments(cur);
          }, null);
        }

        return null;
      };

      const hasReviewNotes =
        has(selectedForm, 'meta.notes') &&
        get(selectedForm, 'meta.notes', []).length;
      const hasFormAttachments = hasAttachments(template);

      if (hasFormAttachments || hasReviewNotes) {
        this.hasAttachments = hasFormAttachments;
        this.hasNotes = hasReviewNotes;
      } else {
        this.printTriggered = true;
        setTimeout(() => this.setState({ toPrint: true }), 750);
      }
    }
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();

    trap.reset();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      form: { selectedForm: nextForm, actions: nextActions = {} } = {},
      app: { sidebarCollapsed: nextSidebarCollapsed } = {},
    } = nextProps;
    const {
      form: { selectedForm, actions = {} } = {},
      app: { sidebarCollapsed } = {},
    } = this.props;

    return (
      propsHasChanged(nextForm, selectedForm) ||
      propsHasChanged(nextActions, actions) ||
      propsHasChanged(nextState, this.state) ||
      nextProps.loading !== this.props.loading ||
      nextSidebarCollapsed !== sidebarCollapsed
    );
  }

  componentDidUpdate() {
    const { modals = {} } = this.props;
    const { toPrint } = this.state;
    const modalOpen = Object.values(modals).some(modal => modal.visible);
    if (!modalOpen && toPrint) {
      this.setState({ toPrint: false }, () => window.print());
    }
  }

  printWithAttachments = generateReportPrintWithAttachments(this);

  onPrintEvent = () => {
    const { selectedForm = {} } = this.props;
    const { meta: { id = '', workFlowData = {} } = {} } = selectedForm;

    return [
      ['command+p', 'ctrl+p'],
      e => {
        if (!this.isPrinting() && id && workFlowData.state !== 'initial') {
          e.preventDefault();

          if (this.props.canPrintWithAttachments === true) {
            this.printWithAttachments();
          } else {
            window.open(this.getPrintLink());
          }
          return false;
        }
      },
      'keydown',
    ];
  };

  isPrinting = () => this.props.isPrinting;

  isDraft() {
    const {
      selectedForm: { meta },
    } = this.props;
    return !!meta.draftDate;
  }

  isPersisted() {
    const {
      selectedForm: { meta },
    } = this.props;
    return !!meta.id;
  }

  saveDraft = (cb = () => {}) => {
    const {
      dispatch,
      selectedForm: { meta = {}, data, formFields },
    } = this.props;
    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    const transformedData = getDataFromConditions(
      {
        ...data,
        isReviewer: !this.isEditable(),
      },
      allFields,
      this.whiteListKeys
    );

    if (meta.id) {
      dispatch(
        updateDraftReport(
          {
            id: meta.id,
            data: { ...meta, data: transformedData },
          },
          cb
        )
      );
    } else {
      dispatch(postDraftReport(transformedData, cb));
    }
  };

  setReviewer = value => {
    const {
      form: { actions },
    } = this.props;
    const allReviewers = uniqBy(
      actions.reduce((res, action) => [...res, ...action.reviewer.list], []),
      'id'
    );
    let reviewerId = isArray(value) ? value[0] : value;
    if (actions.length) {
      this.setState({
        selectedReviewer: allReviewers.find(({ id }) => id + [] === reviewerId),
      });
    }
  };

  setResolution = value => {
    const {
      form: { actions },
    } = this.props;

    if (actions.length) {
      this.setState({
        selectedResolution: actions.find(
          ({ id: actionValue }) => actionValue === value
        ),
        isFinal: true,
      });
    }
  };

  getModalBody = getModalBody;

  timeoutCallback = ({
    isBlendLink,
    isFinalLinked,
    sendingMoreInfo,
    options,
    linkedSubmission,
  }) => {
    const {
      dispatch,
      history: { push },
    } = this.props;

    if (sendingMoreInfo) {
      push('/reports');
      dispatch(showModal(options.id));
    } else {
      if (isBlendLink && !isFinalLinked) {
        const { id: linkedFormId } = linkedSubmission;
        push(`/reports/${linkedFormId}`);
        this.updateModal(options);
        this.showModal(options.id);
      } else {
        push('/reports');
        dispatch(showModal(options.id));
      }
    }
  };

  onReview = (
    selectedResolution = {},
    selectedReviewer = {},
    notes,
    fields = {}
  ) => {
    const {
      dispatch,
      selectedForm: {
        meta: {
          id,
          agencyId,
          linkedSubmissions = [],
          formLinks = [],
          formType,
          formNumber,
        } = {},
        formFields,
        data,
      },
    } = this.props;
    const { label: actionLabel = '', id: action = '' } = selectedResolution;
    const { id: reviewer } = selectedReviewer;
    const transformedData = getTransformedData(
      fields,
      formFields,
      data,
      this.isEditable()
    );
    const isBlendLink = isLinked(formLinks, 'Blend');
    const isFinalLinked = isFinalLinkedSubmission(id, linkedSubmissions);
    const linkedSubmission = linkedSubmissions[0] || {};
    const sendingMoreInfo = action.includes(REQUEST_MORE_INFO);

    dispatch(
      postReport(
        { agencyId, id, reviewer, action, notes, data: transformedData },
        async (error, report) => {
          if (error) {
            const { response } = error;
            const editedErrors = await getEditedErrors(response);
            this.showErrorConfirmation(this.getErrorResponse(editedErrors));
            return false;
          }
          const options = getModalOptions({
            report,
            isBlendLink,
            isFinalLinked,
            linkedSubmission,
            sendingMoreInfo,
            actionLabel,
            formType,
            formNumber,
          });
          dispatch(createModal(options));
          setTimeout(() => {
            this.timeoutCallback({
              isBlendLink,
              isFinalLinked,
              sendingMoreInfo,
              options,
              linkedSubmission,
            });
          }, 500);
        }
      )
    );
  };

  setErrorState = async errors => {
    const { dispatch } = this.props;
    const errs = Object.keys(errors).reduce(
      (acc, k) => set(acc, k, { errors: errors[k] }),
      {}
    );
    const flattenedErrors = flattenErrors(errs);
    dispatch(syncFormValidation(flattenedErrors));
  };

  showErrorConfirmation = (
    message = '',
    type = 'error',
    typeIcon = 'exclamation-circle'
  ) => {
    const ErrorTitle = (
      <ModalTitle type={type}>
        <Icon type={typeIcon} /> <span>Heads up!</span>
      </ModalTitle>
    );

    const ErrorText = getErrorMessageModalBody(message);

    this.createModal({
      visible: true,
      title: ErrorTitle,
      children: ErrorText,
    });

    this.showModal();
  };

  getSuccessLinkedContent = (
    currentForm = {},
    linkedForm = {},
    reviewer = {}
  ) => {
    const { formType: currentFormType } = currentForm;
    const {
      formType: linkedFormType,
      formNumber: linkedFormNumer,
    } = linkedForm;
    const rankName = get(reviewer, ['rank', 'name'], '');
    const fullName = get(reviewer, ['fullName'], '');
    return (
      <ModalBody>
        <p>
          {`This ${currentFormType} involves a ${linkedFormType} incident ${linkedFormNumer}.`}
        </p>
        <p>
          Both reports have been submitted to{' '}
          <strong>
            <span
              dangerouslySetInnerHTML={{
                __html: `${rankName} ${fullName}`,
              }}
            />
          </strong>{' '}
          for review.
        </p>
      </ModalBody>
    );
  };

  // errors: string | object
  //         if object: like { caseNumber: [{ message: 'This field is required' }] };
  getErrorResponse = errors =>
    isPlainObject(errors)
      ? Object.keys(errors).reduce((err, key) => {
          err.push(`${key}: ${get(errors, [key, 0, 'message'], '')}`);
          return err;
        }, [])
      : errors;

  onSave = debounce(() => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta, data, formFields, template },
      currentUser = {},
      templateConfig,
    } = this.props;
    const {
      selectedReviewer = {},
      isFinal = false,
      selectedResolution = {},
      actions = [],
      reviewerNotes: notes,
    } = this.state;

    dispatch(addUserReportSuccess(selectedReviewer));
    this.updateModal({
      footer: this.modalButtons({ isLoading: true }),
    });
    const users = {
      ...get(data, '__users', {}),
      [selectedReviewer.id]: {
        ...selectedReviewer,
      },
    };

    const finalData = {
      ...data,
      __users: users,
    };

    const { agencyId, id, linkedSubmissions = [], formLinks = [] } = meta;
    const { id: currentuserId = '' } = currentUser;

    const isBlendLink = isLinked(formLinks, 'Blend');
    const isFinalLinked = isFinalLinkedSubmission(id, linkedSubmissions);
    const linkedSubmission = linkedSubmissions[0] || {};

    const { id: reviewerId, firstName, lastName } = selectedReviewer || {};
    const { id: resolutionValue, label: resolutionText } =
      selectedResolution || {};

    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );

    const submissionMessageConfig = get(templateConfig, 'submissionMessage');
    const submissionMessage = get(submissionMessageConfig, 'value', []);
    const submissionMessageTemplate = get(first(submissionMessage), 'name');
    const hasSubmissionMessage =
      submissionMessageConfig &&
      submissionMessage &&
      submissionMessage.length > 0;

    let reviewer = reviewerId;
    let action;

    if (isEmpty(selectedReviewer) && !isFinalLinked) {
      return;
    }

    if (isBlendLink && isFinalLinked) {
      const { actions = [] } = linkedSubmission;
      reviewer = get(first(actions), 'metadata.workflowData.reviewers');
      action = get(first(actions), 'metadata.workflowData.action.value');
    } else if (isFinal) {
      reviewer = currentuserId;
      action = resolutionValue;
    } else {
      action = actions.reduce((res, _action) => {
        if (_action.reviewers.includes(reviewer)) {
          return _action.id;
        }
        return res;
      }, action);
    }

    const transformedData = getDataFromConditions(
      {
        ...finalData,
        isReviewer: !this.isEditable(),
      },
      allFields,
      this.whiteListKeys
    );

    dispatch(
      postReport(
        { agencyId, id, reviewer, action, notes, data: transformedData },
        async (error, report) => {
          const { response } = error;
          if (error) {
            const editedErrors = await getEditedErrors(response);
            await this.setErrorState(editedErrors);
            this.hideModal();
            this.showErrorConfirmation(this.getErrorResponse(editedErrors));
            return false;
          }
          const { activeReviewerId, participants = [] } = report;
          const reviewer = participants.length
            ? participants.filter(
                participant => participant.id === activeReviewerId
              )
            : participants[0];
          const activeReviewer = reviewer[0];

          const Title = (
            <ModalTitle success>
              <Icon type="check-circle" /> <span>Success!</span>
            </ModalTitle>
          );

          const reviewerStr = isEmpty(firstName)
            ? '.'
            : ` to ${get(
                selectedReviewer,
                'rank.name',
                ''
              )} ${firstName} ${lastName} for review.`;
          let Text = this.getModalBody(
            {},
            `Your report has been submitted${reviewerStr}`
          );

          if (hasSubmissionMessage) {
            const submitter = currentUser;
            const target = selectedReviewer;

            const context = {
              submitter: {
                fullName: get(submitter, 'fullName'),
                rank: get(submitter, 'rank.name'),
              },
              target: {
                fullName: get(target, 'fullName'),
                rank: get(target, 'rank.name'),
              },
            };

            const interpolate = interpolateTemplate(submissionMessageTemplate);
            const interpolatedMessage = interpolate(context);

            Text = this.getModalBody({}, interpolatedMessage);
          }

          if (isBlendLink && isFinalLinked && activeReviewerId) {
            Text = this.getSuccessLinkedContent(
              report,
              linkedSubmission,
              activeReviewer
            );
          } else if (isFinalLinked && !activeReviewerId) {
            const {
              formType: linkedFormType,
              formNumber: linkedFormNumber,
            } = linkedSubmission;
            const {
              selectedForm: { meta: { formType } = {} },
            } = this.props;
            Text = (
              <ModalBody>
                <p>
                  This {formType} involves a {linkedFormType} {linkedFormNumber}
                  .
                </p>
                <p>
                  You have submitted both reports. Both reports are now closed
                </p>
              </ModalBody>
            );
          } else if (isFinal) {
            Text = (
              <ModalBody>{`You have submitted this report as ${resolutionText} and the report is now closed`}</ModalBody>
            );
          }

          const options = {
            id: 'report-success',
            title: Title,
            children: Text,
          };

          dispatch(createModal(options));
          dispatch(
            deleteAllAtachments({
              kind: template.type,
              formId: id,
            })
          );
          setTimeout(() => {
            if (isBlendLink && !isFinalLinked) {
              const { id: linkedFormId } = linkedSubmissions[0] || {};
              push(`/reports/${linkedFormId}`);
            } else {
              push('/reports');
              dispatch(showModal(options.id));
            }
          }, 500);
        }
      )
    );
  }, 500);

  onCancel = () => {
    this.setState({ selectedReviewerId: '' });
    this.hideModal();
  };

  modalButtons = ({
    isDisable = false,
    isLoading = false,
    cancelLabel = 'Cancel',
    submitLabel = 'Submit',
  } = {}) => [
    <Button
      key="cancel"
      size="default"
      disabled={isDisable}
      onClick={this.onCancel}
    >
      {cancelLabel}
    </Button>,
    <Button
      key="submit"
      type="primary"
      size="default"
      disabled={isDisable}
      loading={isLoading}
      onClick={this.onSave}
    >
      {submitLabel}
    </Button>,
  ];

  /**
   * Triggers on cancel delete confirmation modal.
   */
  onCancelDeleteConfirmationModalClick = () => {
    const { dispatch } = this.props;
    dispatch(hideModal(deleteReportConfirmModalID));
  };

  /**
   * Triggers on confirmation modal click.
   */
  onAcceptConfirmationModalClick = () => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta },
    } = this.props;
    const { linkedSubmissions = [] } = meta;
    const { id: linkedSubmissionId } = linkedSubmissions[0] || {};
    const resetKeys = ['isUseOfForceInvolved', 'useOfForceTrackingNumber'];
    const isEditable = this.isEditable();

    const hideModalAndRedirect = () => {
      dispatch(hideModal(deleteReportConfirmModalID));
      // This timeout is used here, to prevent a flick effect when the modal
      // animation starts.
      setTimeout(() => push('/reports'), 300);
    };

    if (!isEditable) {
      dispatch(
        deleteReportSoftDelete(meta.id, () => {
          hideModalAndRedirect();
        })
      );
    } else {
      dispatch(
        deleteFormLink(
          {
            formId: linkedSubmissionId,
            resetRemoteKeys: resetKeys,
            saveLocal: false,
          },
          () => {
            dispatch(
              deleteReport(meta.agencyId, meta.id, () => {
                hideModalAndRedirect();
              })
            );
          }
        )
      );
    }
  };

  /**
   * On Header Toolbar Delete Button Click.
   * Shows the delete report modal.
   */
  onDeleteHeaderButtonClick = () => {
    const { dispatch } = this.props;
    const options = {
      id: deleteReportConfirmModalID,
      title: deleteConfirmationModalOptions.title,
      children: deleteConfirmationModalOptions.text,
      footer: deleteConfirmationModalOptions.buildFooter(
        this.onCancelDeleteConfirmationModalClick,
        this.onAcceptConfirmationModalClick
      ),
    };
    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  updateDraft = cb => {
    this.saveDraft(async (error, report) => {
      if (error) {
        const { error: { errors = {} } = {} } = await error.response.json();
        let message =
          'Something went wrong. We cannot process your request right now; please try again later.';

        this.setErrorState(errors);

        if (errors.hasOwnProperty('assessmentDate')) {
          const errFieldMsg = get(errors, ['assessmentDate', 0, 'message'], '');
          message = errFieldMsg
            ? `${errFieldMsg}. Please select a different Trainee or Date.`
            : message;
        }

        this.showErrorConfirmation(message);
      }

      cb && typeof cb === 'function' && cb(error, report);
      this.redirectToReportPage(report.id);
    });
  };

  redirectToReportPage = async id => {
    if (id && window.location.href.includes(route('createNewReport'))) {
      const reportUrlRoute = `/reports/${id}`;
      window.history.replaceState(null, '', reportUrlRoute);
      window.history.pushState(null, '', reportUrlRoute);
    }
  };

  formButtons = () => {
    const { selectedForm } = this.props;

    const { meta: { id, isDeletable = false } = {} } = selectedForm;

    const deleteDraft = {
      icon: 'delete',
      type: 'icon',
      title: 'Delete',
      tooltipMessage: 'Delete Report',
      onButtonClick: () => this.onDeleteHeaderButtonClick,
    };

    const print = {
      icon: 'printer',
      type: 'icon',
      title: 'print',
      tooltipMessage: 'Print Report',
      onButtonClick: () => this.printWithAttachments,
    };

    const printSelf = {
      icon: 'printer',
      type: 'icon',
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    if (this.isPrinting()) {
      return [printSelf];
    }

    if (!id) {
      return [];
    }

    if (isDeletable) {
      if (pendingLinkedSubmissions(selectedForm)) {
        return [];
      }

      return [deleteDraft];
    }

    return [print];
  };

  reviewFormButtons = () => {
    const { session: { currentUser = {} } = {} } = this.props;
    const { permissions = [] } = currentUser;

    const isSoftDeleteAllow = hasPermissions(permissions, [
      PERMISSIONS.deleteReport,
    ]);

    const history = {
      icon: 'clock-circle',
      type: 'icon',
      title: 'History',
      tooltipMessage: 'Timeline',
      onButtonClick: () => this.viewHistory,
    };

    const print = {
      icon: 'printer',
      type: 'icon',
      title: 'print',
      tooltipMessage: 'Print Report',
      onButtonClick: () => this.printWithAttachments,
    };

    const printSelf = {
      icon: 'printer',
      type: 'icon',
      title: 'print',
      tooltipMessage: 'Print Report',
      onButtonClick: () => this.printWithAttachments,
    };

    const deleteDraft = {
      icon: 'delete',
      type: 'icon',
      title: 'Delete',
      tooltipMessage: 'Delete Report',
      onButtonClick: () => this.onDeleteHeaderButtonClick,
    };

    if (this.isPrinting()) {
      return [printSelf];
    }

    if (isSoftDeleteAllow) {
      return [history, print, deleteDraft];
    }

    return [history, print];
  };

  getReviewersModalOptions = (title = 'Select Reviewer') => ({
    title,
    children: (
      <div className="text-center">
        <Spin />
      </div>
    ),
    footer: this.modalButtons(),
  });

  getLinkedContent = (currentForm = {}, linkedForm = {}) => {
    const { formType: currentFormType } = currentForm;
    const {
      formType: linkedFormType,
      formNumber: linkedFormNumber,
    } = linkedForm;
    return (
      <div className="linked-content">
        <p>
          This {currentFormType} involves a {linkedFormType} {linkedFormNumber}.
        </p>
        <p>Both reports will be sent for review together.</p>
        <p>Please select reviewer to proceed.</p>
      </div>
    );
  };

  onSubmit = async values => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta, data, state, formFields },
    } = this.props;
    const { shouldValidate } = this.state;
    const { linkedSubmissions = [], formLinks = [], notes } = meta;

    let currentFormSubmission = {};
    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    const transformedData = getDataFromConditions(
      {
        ...data,
        isReviewer: !this.isEditable(),
      },
      allFields,
      this.whiteListKeys
    );
    let serverError;

    if (meta.id) {
      Object.assign(currentFormSubmission, meta);
    } else {
      await dispatch(
        postDraftReport(transformedData, async (error, result) => {
          serverError = error;

          if (error) {
            const { error: { errors = {} } = {} } = await error.response.json();
            let message =
              'Something went wrong. We cannot process your request right now; please try again later.';

            this.setErrorState(errors);

            if (errors.hasOwnProperty('assessmentDate')) {
              const errFieldMsg = get(
                errors,
                ['assessmentDate', 0, 'message'],
                ''
              );
              message = errFieldMsg
                ? `${errFieldMsg}. Please select a different Trainee or Date.`
                : message;
            }

            this.showErrorConfirmation(message);
          }

          Object.assign(currentFormSubmission, result);
        })
      );
    }

    if (serverError) {
      this.showErrorConfirmation(
        `Something went wrong. We cannot process your request right now; please try again later.`
      );
      return false;
    }

    const { id, agencyId, creationInProgress = false } = currentFormSubmission;

    if (creationInProgress) {
      this.showErrorConfirmation(
        'Report creation in progress, please submit later.'
      );
      return false;
    }

    const hasErrors = !!Object.entries(state).filter(
      ([k, v]) => v.errors && v.errors.length
    ).length;
    if (hasErrors && shouldValidate) {
      this.showErrorConfirmation(
        `Something went wrong. We cannot process your request right now; please try again later.`
      );
      return false;
    }

    dispatch(
      getFormActions(
        {
          agencyId,
          formSubmissionId: id,
        },
        (error, reviewActions = {}) => {
          const { actions = [], resolutionLabel } = reviewActions;
          const sendingMoreInfo = actions.every(a => isEmpty(a.reviewer.list));
          const isFinal = actions.some(a => a.isFinal);
          const isBlendLink = isLinked(formLinks, 'Blend'); // Forms that moves at the same time
          const isFinalLinked = isFinalLinkedSubmission(id, linkedSubmissions);
          const linkedSubmission = linkedSubmissions[0] || {};
          const reviewerNoteSubmission = get(
            first(actions),
            'showSubmitterNotes'
          );
          let defaultAction = 'submit';
          if (actions.length === 1) {
            defaultAction = actions[0].id;
          }
          const allReviewers = actions.reduce(
            (res, action) => [...res, ...action.reviewer.list],
            []
          );
          const groupedReviewers = groupReviewers(uniqBy(allReviewers, 'id'));

          if (error) {
            const { message } = error;
            this.showErrorConfirmation(message);
            return;
          }

          if (isEmpty(reviewActions)) {
            this.showErrorConfirmation(
              'We are processing your request. Please wait.',
              'warning',
              'info-circle'
            );
            return;
          }

          if (isBlendLink && isFinalLinked) {
            this.onSave();
          } else if (isFinal) {
            this.updateModal({
              ...this.getReviewersModalOptions(),
              title: `Select a ${resolutionLabel || 'Resolution'}`,
              children: (
                <div className="text-center">
                  <Select
                    style={{ width: '80%' }}
                    onChange={this.setResolution}
                    placeholder="Please Select"
                  >
                    {actions.map(action => (
                      <Option key={action.id} value={action.id}>
                        {action.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              ),
              footer: this.modalButtons(),
            });
            this.showModal();
          } else if (sendingMoreInfo) {
            dispatch(
              postReport(
                { agencyId, id, data: transformedData, action: defaultAction },
                async (error, report) => {
                  const { response } = error;
                  if (error) {
                    const editedErrors = await getEditedErrors(response);
                    await this.setErrorState(editedErrors);
                    this.hideModal();
                    this.showErrorConfirmation(
                      this.getErrorResponse(editedErrors)
                    );
                    return false;
                  }
                  const { activeReviewerId, participants = [] } = report;
                  const reviewer = participants.length
                    ? participants.filter(
                        participant => participant.id === activeReviewerId
                      )
                    : participants[0];
                  const {
                    firstName: reviewerFirstName,
                    lastName: reviewerLastName,
                    rank: { name: rankName = '' } = {},
                  } = reviewer[0] || {};

                  const Title = (
                    <ModalTitle success>
                      <Icon type="check-circle" /> <span>Success!</span>
                    </ModalTitle>
                  );

                  const reviewerStr = isEmpty(reviewerFirstName)
                    ? '.'
                    : ` to ${rankName} ${reviewerFirstName} ${reviewerLastName} for review.`;
                  let Text = this.getModalBody(
                    {},
                    `Your report has been submitted${reviewerStr}`
                  );

                  const options = {
                    id: 'report-submit-success',
                    title: Title,
                    children: Text,
                  };
                  dispatch(createModal(options));

                  setTimeout(() => {
                    if (isBlendLink && !isFinalLinked) {
                      const { id: linkedFormId } = linkedSubmission;
                      push(`/reports/${linkedFormId}`);
                    } else {
                      push('/reports');
                      dispatch(showModal(options.id));
                    }
                  }, 500);
                }
              )
            );
          } else {
            this.updateModal({
              ...this.getReviewersModalOptions(),
              children: (
                <ReviewerWrapper>
                  {isBlendLink &&
                    this.getLinkedContent(meta, linkedSubmissions[0])}
                  <SubmitConfirmationModal
                    groupedReviewers={groupedReviewers}
                    setReviewer={this.setReviewer}
                    updateModal={this.updateModal}
                    modalButtons={this.modalButtons}
                    notes={notes}
                    onNotesReviewer={this.onNotesReviewer}
                    reviewerNoteSubmission={reviewerNoteSubmission}
                  />
                </ReviewerWrapper>
              ),
              footer: isBlendLink
                ? this.modalButtons({
                    submitLabel: 'Continue',
                    isDisable: true,
                  })
                : this.modalButtons({ isDisable: true }),
            });

            this.setState(
              {
                actions: actions.reduce(
                  (res, action) => [
                    ...res,
                    {
                      ...action,
                      reviewers: action.reviewer.list.map(
                        reviewer => reviewer.id
                      ),
                    },
                  ],
                  []
                ),
              },
              () => {
                this.showModal();
              }
            );
          }
        }
      )
    );
  };

  getTemplateName = () => {
    const {
      selectedForm: { meta },
      templates,
    } = this.props;
    const { formType, templateName } = meta;
    return (
      templateName ||
      get(templates, `${formType}.name`, formType) ||
      get(templates, `${formType}.typeName`, formType)
    );
  };

  viewHistory = () => {
    const { selectedForm, timezone } = this.props;
    const {
      meta: { participants: metaParticipants, formNumber },
    } = selectedForm;
    const templateName = this.getTemplateName();
    let snapshotUsers = [];
    let usersOnBehalf = [];
    let metaCustom = {};
    JSON.stringify(get(selectedForm, 'data.__users', {}), function(key, value) {
      snapshotUsers = Object.values(value);
    });

    // The User data from the snapshot table will overwrite the current user data
    // from the benchmark user takble, only if there is id match
    // Data from cache is used prioritized
    for (const metaParticipant of metaParticipants) {
      if (findIndex(snapshotUsers, { id: metaParticipant.id }) === -1) {
        snapshotUsers.push(metaParticipant);
      }
    }

    const {
      onBehalf = {},
      data: { __assignedSectionsHistory = [] } = {},
    } = selectedForm;
    const hasUsersOnBehalf = !isEmpty(onBehalf);

    if (hasUsersOnBehalf)
      JSON.stringify(onBehalf, function(key, value) {
        usersOnBehalf = Object.values(value);
      });
    metaCustom = {
      ...get(selectedForm, 'meta', {}),
      participants: snapshotUsers,
      usersOnBehalf: hasUsersOnBehalf ? usersOnBehalf : [],
      __assignedSectionsHistory,
    };

    const normalizedRecord = transformFormToNormalizedTimeline(
      metaCustom,
      timezone
    );

    const Title = (
      <ModalTitle>
        <span>{`${templateName} ${formNumber} - Timeline`}</span>
      </ModalTitle>
    );

    const Body = (
      <ModalBody>
        <FormHistoryTimeline {...normalizedRecord} />
      </ModalBody>
    );

    this.createModal({
      visible: true,
      title: Title,
      children: Body,
    });

    this.showModal();
  };

  getPrintLink = () => {
    const { selectedForm } = this.props;

    return route('reportsViewAs', {
      id: get(selectedForm, ['meta', 'id'], '404'),
      viewAs: 'print',
    });
  };

  isEditable = () => {
    const { selectedForm } = this.props;
    const { meta: { isEditMode = true } = {}, actions = [] } = selectedForm;

    return (
      isEditMode &&
      filter(actions, action => action.status === 'pending').length === 0
    );
  };

  onAttachmentsFetched = () => {
    if (!this.isPrinting()) {
      return false;
    }

    if (!this.printTriggered) {
      this.attachmentsLoaded = true;

      if ((this.hasNotes && this.reviewNotesLoaded) || !this.hasNotes) {
        const { modals = {} } = this.props;
        const modalOpen = Object.values(modals).some(modal => modal.visible);
        if (!modalOpen) {
          this.printTriggered = true;
          window.print();
        }
      }
    }
  };

  onNotesFetched = () => {
    if (!this.isPrinting()) {
      return false;
    }

    if (!this.printTriggered) {
      this.reviewNotesLoaded = true;

      if (
        (this.hasAttachments && this.attachmentsLoaded) ||
        !this.hasAttachments
      ) {
        const { modals = {} } = this.props;
        const modalOpen = Object.values(modals).some(modal => modal.visible);
        if (!modalOpen) {
          this.printTriggered = true;
          window.print();
        }
      }
    }
  };

  onNotesReviewer = value => {
    this.setState({
      reviewerNotes: {
        notes: value,
      },
    });
  };

  render() {
    const {
      selectedForm,
      dispatch,
      error,
      session: { currentUser = {} } = {},
      app,
      attachments,
      loading,
      form: { templates = [] } = {},
      timezone,
    } = this.props;
    const { shouldValidate } = this.state;

    const { permissions = [], id: currentUserId = '' } = currentUser;
    // get report share list
    const shares = get(selectedForm, 'meta.share.shares', []);
    // check if the report has been shared with the current user
    const shareWithCurrent = shares.some(s =>
      s.shareToId.includes(currentUserId)
    );
    // if not shared with current check permissions
    const canSubmit =
      !shareWithCurrent &&
      hasPermissions(permissions, [
        PERMISSIONS.submitUOFReport,
        PERMISSIONS.submitVPReport,
        PERMISSIONS.submitDORReport,
        PERMISSIONS.submitIACReport,
        PERMISSIONS.submitIAIReport,
        PERMISSIONS.submitReport,
      ]);

    if (error) {
      return <h3>Server error</h3>;
    }

    const {
      meta: { activeReviewerId = '', id = '', isEditMode = true } = {},
    } = selectedForm;

    const isEditable = this.isEditable();

    const canReview = !isEditMode && currentUserId === activeReviewerId;

    const isPrinting = this.isPrinting();

    const formViewerProps = {
      app,
      attachments,
      selectedForm,
      dispatch,
      user: currentUser,
      canSubmit,
      shouldValidate,
      onError,
      onSaveDraft: this.updateDraft,
      isPrinting,
      loading,
      timezone,
      onAttachmentsFetched: this.onAttachmentsFetched,
      onNotesFetched: this.onNotesFetched,
      ...(id
        ? {
            isReviewer: !isEditable,
            canReview,
            actions: !isEditable
              ? this.reviewFormButtons()
              : this.formButtons(),
            onSubmit: isEditable ? this.onSubmit : this.onReview,
          }
        : {
            actions: this.formButtons(),
            onSubmit: this.onSubmit,
          }),
      ...(isPrinting
        ? {
            canSubmit: false,
            canReview: false,
            isReviewer: true,
            onSubmit: () => {},
          }
        : {}),
      templates,
    };

    return (
      <PageWrapper>
        <FormViewer {...formViewerProps} />
      </PageWrapper>
    );
  }
}

const PageWrapper = styled.div`
  position: inherit;
`;

const ReviewerWrapper = styled.div`
  padding: 10px 0;
  width: 400px;
  margin: 0 auto;

  .linked-content {
    color: ${GRAY_2};
    padding-bottom: 20px;
  }

  .reviewer-select {
    width: 300px;
    margin: 0 auto;

    p {
      margin-bottom: 15px;
    }
  }

  .reviewer-note {
    width: 300px;
    margin: 15px auto;

    p {
      margin-bottom: 15px;
    }
  }
`;

export default ReportContainer;
