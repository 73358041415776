import React, { Component } from 'react';
import { Row, Col } from 'antd';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { mapProperties, transformParticipants } from '../../../utils/form';
import FormWrapper from '../styled/form-wrapper';
import ReviewList from '../../form/review-list';
import Conditional from '../../utils/ConditionalRender';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import parseDate, { BENCHMARK_DATE_FORMAT } from 'APP_ROOT/utils/parse-date';
import {
  getFormMeta,
  getFormTemplate,
  getDataEnums,
} from 'APP_ROOT/selectors/form';
import { getCurrentUser, getActiveUser } from 'APP_ROOT/selectors/session';
class TabsContent extends Component {
  constructor(props) {
    const { options = {} } = props;
    const { active: current = 0 } = options;

    super(props);

    this.state = {
      current,
    };
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  header = () => {
    const { props } = this;
    const { templates } = props;
    const currentUser = get(props, 'currentUser.fullName', '');
    const templateType = get(props, 'meta.formType', '');
    const reportName = get(templates, [templateType, 'label'], '');
    const formNumber = reportName ? get(props, 'meta.formNumber', '') : '';
    const createdAt = get(props, 'meta.createdAt', '');
    const unitName = get(props, 'meta.organizationalUnitDisplayName');
    const submitterId = get(props, 'meta.submitterId', '');
    const participants = get(props, 'meta.participants', []);
    const participant = participants.find(p => p.id === submitterId) || {};
    const { fullName = '' } = participant;
    const printedOn = parseDate(
      new Date(),
      props.timezone,
      BENCHMARK_DATE_FORMAT
    );
    const reportCreated = parseDate(
      createdAt,
      props.timezone,
      BENCHMARK_DATE_FORMAT
    );

    return (
      <div className="header-wrapper">
        <Row>
          <Col span={8} className="agency">
            <Row>{unitName}</Row>
          </Col>
          <Col span={8} className="report">
            <Row className="name">{reportName}</Row>
            <Row>{formNumber}</Row>
          </Col>
          <Col span={8} className="submitted">
            <Row className="printed">Printed on: {printedOn}</Row>
            <Row className="printed">Printed By: {currentUser}</Row>
            <Row>Submitted By: {fullName}</Row>
            <Row>Report Created on: {reportCreated}</Row>
          </Col>
        </Row>
      </div>
    );
  };

  footer = () => {
    return (
      <div className="footer-wrapper">
        <div className="page-number"></div>
      </div>
    );
  };

  tabContent = () => {
    const { properties = [], tab = 0, data, dataEnums, ...rest } = this.props;

    const activeTab = properties[tab] || {};
    const { properties: tabProperties = [] } = activeTab;
    const total = tabProperties.length;

    return (
      <Row className="steps-content">
        <Col span={24}>
          {tabProperties.map(
            mapProperties({
              ...rest,
              total,
              tab,
              parentIndex: tab,
            })
          )}
        </Col>
      </Row>
    );
  };

  saveTabRef = index => el => {
    const { settings = {} } = this.props;

    const { saveTabRef } = settings;
    if (typeof saveTabRef === 'function') {
      saveTabRef(el, index);
    }
  };

  spaguettiTabContent = () => {
    const {
      data = {},
      form = {},
      properties = [],
      settings = {},
      tab = 0,
      isReviewer = false,
      ...rest
    } = this.props;

    const stepsContentClassNames = classNames({
      'steps-content': true,
      'is-reviewer': isReviewer,
    });

    return (
      <Row className={stepsContentClassNames} id="scrollable-sections">
        <Col span={24}>
          {properties.map((property, index) => {
            const { properties: tabProperties = [], title = '' } = property;
            const total = tabProperties.length;

            return (
              <div
                className="steps-tab-content"
                id={`section-${title}-${index}`}
                key={index}
                ref={this.saveTabRef(index)}
              >
                <h2 className="steps-tab-title">
                  <span>{title}</span>
                </h2>

                {tabProperties.map(
                  mapProperties({
                    ...rest,
                    data,
                    form,
                    settings,
                    total,
                    tab,
                    isReviewer,
                  })
                )}
              </div>
            );
          })}
        </Col>
      </Row>
    );
  };

  render() {
    const {
      meta = {},
      activeUser = '',
      isReviewer = false,
      loading = false, // eslint-disable-line
      timezone,
      isReviewPanel = false,
      form,
    } = this.props;

    const {
      notes = [],
      participants = [],
      history = [],
      activeReviewerId = '',
    } = meta;

    const notesExist = notes.length > 0;

    const transformedParticipants = transformParticipants(participants);

    return (
      <FormWrapper>
        {this.header()}

        <Conditional condition={!isReviewer}>
          <div>
            {notesExist && !isReviewPanel && (
              <ReviewList
                participants={transformedParticipants}
                activeUser={activeUser}
                activeReviewer={activeReviewerId}
                history={history}
                reviews={notes}
                form={form}
                timezone={timezone}
                onlyLastItem
                withBorder
              />
            )}

            {this.tabContent()}
          </div>
        </Conditional>

        <Conditional condition={isReviewer}>
          {this.spaguettiTabContent()}
        </Conditional>

        {this.footer()}
      </FormWrapper>
    );
  }
}

const mapState = (state, props) => {
  const formTemplate = getFormTemplate(state, props);

  return {
    meta: getFormMeta(state),
    dataEnums: getDataEnums(state, props, formTemplate),
    activeUser: getActiveUser(state),
    currentUser: getCurrentUser(state),
  };
};

export default connect(mapState)(TabsContent);
