import React, { useCallback, useState } from 'react';
import postUserEquipment from '../../../agency-equipment/actions/post-user-equipment';
import encodeEquipmentData from './utils/encodeEquipmentData';
import validateFormModal from './utils/validateFormModal';

const SUCCESS_MESSAGE = 'Equipment successfully created.';
const ERROR_MESSAGE = 'Error creating equipment.';

const useUserEquipmentNew = ({
  equipmentModalFields,
  tenantId,
  userId,
  selectedCategory = '',
  equipment,
  onSave,
  dispatch,
}) => {
  const [validationMessages, setValidationMessages] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const onSaveEquipment = () => {
    setIsProcessing(true);
    const { isValid, validationMessages } = validateFormModal({
      equipment,
      equipmentModalFields,
    });
    setIsValid(isValid);
    setValidationMessages(validationMessages);

    if (!isValid) {
      setIsProcessing(false);
      return;
    }

    dispatch(
      postUserEquipment({
        tenantId,
        userId,
        body: {
          category: { id: selectedCategory },
          fieldsValues: equipment.map(({ fieldId, type, value }) => ({
            fieldId,
            value: encodeEquipmentData(type, value),
          })),
        },
        next: callback,
      })
    );
  };

  const callback = useCallback((error, data) => {
    onSave({ success: SUCCESS_MESSAGE, error: ERROR_MESSAGE }, error, data),
      [onSave];
    setIsProcessing(false);
  });

  return { onSaveEquipment, isValid, isProcessing, validationMessages };
};

export default useUserEquipmentNew;
