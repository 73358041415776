import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Select, Spin, Empty } from 'antd';
import { get, isEmpty } from 'lodash';
import getRoute from 'APP_ROOT/utils/get-route';

import { getUsers, reassignReport } from '../services/api';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import { getCurrentUser } from 'APP_ROOT/selectors/session';
import { getFormMeta } from 'APP_ROOT/selectors/form';
import { reportReassignFilter } from '../../ReportSharing/utils/networkRequestFilters';
import ChangesReportReassign from '../services/changesReportReassign';
import { formItemLayout } from '../constants/modal';
import openConfirm from '../actions/openConfirm';
import {
  getReportId,
  getAgencyId,
  getRank,
  getFullName,
  getReportName,
} from '../../ReportSharing/utils/utils';
import { getTemplates } from '../../../selectors/form';
import { FEATURES, hasFeatures } from 'APP_ROOT/utils/features';

const FormItem = Form.Item;
const Option = Select.Option;

class Modal extends Component {
  state = {
    data: [],
    userSelected: undefined,
    fetching: false,
    reportOwner: undefined,
  };

  componentDidMount() {
    this.submitSubscription = ChangesReportReassign.getSubmit().subscribe(
      data => data && this.submit()
    );
    this.getReportOwner();
  }

  componentWillUnmount() {
    this.submitSubscription.unsubscribe();
  }

  getReportOwner = () => {
    const state = get(this.props, 'meta.workFlowData.state');
    const reportOwner = get(
      this.props,
      `meta.workFlowData.activeStateReviewers['${state}'][0]`
    );
    this.setState({ reportOwner });
  };

  resetState() {
    this.setState({
      data: [],
      userSelected: undefined,
      fetching: false,
      reportOwner: undefined,
    });
  }

  getUsers = async (filter = '') => {
    const { reportOwner } = this.state;
    const agencyId = getAgencyId(this.props);
    const formId = getReportId(this.props);
    const { user: { featureFlags = [] } = {} } = this.props;
    const hasReportUserSelectionFuzzySearch = hasFeatures(
      featureFlags,
      FEATURES.reportUserSelectionFuzzySearch
    );
    const newFilter = reportReassignFilter(filter);
    const users =
      (await getUsers(
        agencyId,
        formId,
        newFilter,
        hasReportUserSelectionFuzzySearch
      )) || [];
    return users.filter(({ id }) => id !== reportOwner);
  };

  fetchUser = async (value = '') => {
    this.setState({ fetching: true });
    const users = await this.getUsers(value);
    const data = users.map(user => ({
      label: getFullName(user),
      key: user.id,
      rank: getRank(user),
    }));
    this.setState({ data, fetching: false });
  };

  submitReassign = async () => {
    const { userSelected, reportOwner } = this.state;
    const id = getReportId(this.props);
    if (isEmpty(userSelected) || Number(userSelected.key) === reportOwner)
      return;
    reassignReport(id, Number(userSelected.key));
    return userSelected.label;
  };

  closeModal = reassignedTo => {
    const { dispatch, history } = this.props;
    const { userSelected, reportOwner } = this.state;
    const reportName = getReportName(this.props);
    ChangesReportReassign.setSubmit(false);
    ChangesReportReassign.setCloseModal(false);
    if (!isEmpty(userSelected) && Number(userSelected.key) !== reportOwner) {
      history.push(getRoute('reports'));
      dispatch(openConfirm({ reassignedTo, reportName }));
      this.resetState();
    }
  };

  submit = async () => {
    const namesToShare = await this.submitReassign();
    this.closeModal(namesToShare);
  };

  handleChange = userSelected => {
    this.setState({
      userSelected,
      fetching: false,
    });
  };

  render() {
    const { fetching, data, userSelected } = this.state;

    return (
      <ModalBody>
        <Form>
          <FormItem {...formItemLayout} label="Reassign report" colon={false}>
            <Select
              labelInValue
              showSearch
              value={userSelected}
              placeholder="Please select"
              notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
              filterOption={false}
              onFocus={this.fetchUser}
              onSearch={this.fetchUser}
              onChange={this.handleChange}
              style={{ width: '100%' }}
            >
              {data.map(d => (
                <Option key={d.key}>{d.label}</Option>
              ))}
            </Select>
          </FormItem>
        </Form>
      </ModalBody>
    );
  }
}

const mapState = (state, props) => {
  return {
    user: getCurrentUser(state),
    meta: getFormMeta(state),
    templates: getTemplates(state),
  };
};

export default withRouter(connect(mapState)(Modal));
