import React from 'react';
import Button from './ButtonDefinition.styled';

export default (
  callback,
  showIconAction,
  onAction,
  sectionId,
  isSectionReadOnly,
  buttonParameters,
  parentContext,
  disabled = false
) => {
  const { altText, keyNaming, actionName, targetIcon } = buttonParameters;

  const handleClick = (event, record) => {
    event.stopPropagation();
    if (!disabled) {
      onAction
        ? onAction(
            record,
            record.index,
            actionName,
            () => callback(sectionId, record.index, record, isSectionReadOnly),
            parentContext
          )
        : callback(sectionId, record.index, record, isSectionReadOnly);
    }
  };

  return {
    title: showIconAction ? '' : altText,
    dataIndex: '',
    key: keyNaming,
    width: showIconAction ? 50 : 100,
    render: (text, record, index) => (
      <Button
        className={disabled ? 'disabled' : ''}
        aria-disabled={isSectionReadOnly}
        onClick={event => handleClick(event, record)}
      >
        {targetIcon}
      </Button>
    ),
  };
};
