import React from 'react';
import { change, arrayPush, arrayRemove } from 'redux-form';
import { notification } from 'antd';

import SectionAddButton from '../../../components/custom-sections/sections/SectionAddButton';
import CustomSectionTable from '../../../components/custom-sections/tables/CustomSectionTable';
import CaseFileDocumentModal from './CaseFileDocumentModal';
import storage from 'APP_ROOT/utils/storage';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../../components/custom-sections/sections/Sections.styled';
import { get } from 'lodash';

import {
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_LINK,
  ATTRIBUTE_TYPE_DATE,
} from '../../administrator/agency-users/UserProfile/UserProfile.constants';
import { fetchRequest, genOptions } from '../../../utils/request';

const NOTIFICATION_ERROR = 'error';

const CaseFileDocument = props => {
  const _sectionAttributes = [
    {
      attributeName: 'description',
      type: ATTRIBUTE_TYPE_STRING,
      title: 'Description',
      settings: { tableColumn: 1, unique: false },
    },
    {
      attributeName: 'fileName',
      type: ATTRIBUTE_TYPE_LINK,
      title: 'File',
      settings: { tableColumn: 2 },
    },
    {
      attributeName: 'uploaded',
      type: ATTRIBUTE_TYPE_DATE,
      title: 'Upload Date',
      settings: { tableColumn: 4, defaultValue: null },
    },
  ];

  const {
    profileForm,
    editSection: pEditSection,
    updateSectionField: pUpdateSectionField,
    addSectionFieldValue: pAddSectionFieldValue,
    removeSectionFieldValue: pRemoveSectionFieldValue,
    setEditSectionTable: pSetEditSectionTable,
    userProfile,
    isSectionReadOnly = false,
    parentContent,
    sectionAttributes = _sectionAttributes,
    uploadURL,
    casefileId,
  } = props;

  const _updateSectionField = (field, value) => {
    const { dispatch } = parentContent.props;
    dispatch(change(parentContent.formName, field, value));
  };

  const _addSectionFieldValue = (field, value) => {
    const { dispatch } = parentContent.props;
    dispatch(arrayPush(parentContent.formName, field, value));
  };

  const _removeSectionFieldValue = (field, index) => {
    const { dispatch } = parentContent.props;
    dispatch(arrayRemove(parentContent.formName, field, index));
    parentContent.setState({ editUserSection: null });
  };

  const _setEditSectionTable = (sectionId, key) => {
    if (sectionId) {
      parentContent.setState({ editUserSection: { sectionId, key } });
    } else {
      parentContent.setState({ editUserSection: null });
    }
  };

  const editSection =
    pEditSection || get(parentContent, 'state.editUserSection');
  const updateSectionField = pUpdateSectionField || _updateSectionField;
  const addSectionFieldValue = pAddSectionFieldValue || _addSectionFieldValue;
  const removeSectionFieldValue =
    pRemoveSectionFieldValue || _removeSectionFieldValue;
  const setEditSectionTable = pSetEditSectionTable || _setEditSectionTable;

  const onActionCallback = record => {
    showNotification(
      'info',
      `Delete file '${record.fileName}'`,
      'Not implemented yet'
    );
  };

  const onCancelCallback = () => {};

  const showNotification = (type, message, description) =>
    notification[type]({ message, description });

  const downloadDocument = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    const location = get(
      profileForm,
      `values.userDocumentsData.documents.locationUrl`
    )[index];
    const url = location.URI;

    const options = genOptions('GET', {}, `Bearer ${storage.get('token')}`);
    fetchRequest(url, options)
      .then(response => {
        const { content: { url } = {} } = response;
        if (!url) {
          throw Error('Cannot find url to download');
        }
        const element = document.createElement('a');
        element.href = url;
        element.download = '';
        element.click();
      })
      .catch(error => {
        showNotification(
          NOTIFICATION_ERROR,
          'Something went wrong',
          error.message
        );
      });
  };

  const buildDocumentsModal = (
    readOnly,
    showModal,
    sectionId,
    rowIndex,
    handleModalCancel,
    handleModalOk,
    isNew
  ) => {
    return (
      <CaseFileDocumentModal
        title={'Add Document'}
        attributes={sectionAttributes}
        profileForm={profileForm}
        readOnly={readOnly}
        visible={showModal}
        sectionId={sectionId}
        rowIndex={rowIndex}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        updateSectionField={updateSectionField}
        userProfile={userProfile}
        isNew={isNew}
        uploadURL={uploadURL}
        casefileId={casefileId}
      />
    );
  };

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey="documents"
      expandIconPosition="right"
    >
      <StyledPanel
        header={<StyledPanelTitle size="large">Documents</StyledPanelTitle>}
        key="documents"
        className="section-panel"
        extra={
          <SectionAddButton
            sectionId="documents"
            attributeId="id"
            prefix="userDocumentsData"
            attributes={sectionAttributes}
            mainForm={profileForm}
            addSectionFieldValue={addSectionFieldValue}
            setEditSectionTable={setEditSectionTable}
            buttonTitle="Add Document"
            disabled={isSectionReadOnly}
          ></SectionAddButton>
        }
      >
        <hr></hr>
        <CustomSectionTable
          modalTitle="Add Document"
          sectionId="documents"
          prefix="userDocumentsData"
          attributeId="id"
          attributes={sectionAttributes}
          mainForm={profileForm}
          editSection={editSection}
          addSectionFieldValue={addSectionFieldValue}
          updateSectionField={updateSectionField}
          removeSectionFieldValue={removeSectionFieldValue}
          setEditSectionTable={setEditSectionTable}
          modalFor="documents"
          hasAction={false}
          onAction={onActionCallback}
          onCancel={onCancelCallback}
          linkAction={downloadDocument}
          isSectionReadOnly={isSectionReadOnly}
          showIconAction={true}
          iconAction="delete"
          buildModal={buildDocumentsModal}
        />
      </StyledPanel>
    </StyledCollapse>
  );
};

export default CaseFileDocument;
