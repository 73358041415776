import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Form, Select, Spin, notification } from 'antd';
import { get, isEmpty, some } from 'lodash';

import { getUsers } from '../../../modules/ReportSharing/services/api';

import ModalBody from 'APP_ROOT/components/common/modal/body';
import { getCurrentUser } from 'APP_ROOT/selectors/session';
import { hasPermissions, PERMISSIONS } from 'APP_ROOT/utils/admin';
import { filterByIntegrationIds } from '../utils/networkRequestFilters';
import ChangesCaseFileSharing from '../services/changesCaseFileSharing';
import {
  formItemLayout,
  SHARE_EDIT,
  SHARE_VIEW,
  FORM_NAME,
} from '../constants/share';
import openConfirmShare from '../actions/openConfirmShare';
import {
  getSharesIds,
  getSharesById,
  getAgencyId,
  getUserData,
  getShareDifferece,
  getShareList,
  getListOfNames,
} from '../utils/utils';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';
import fetchReadableStream from '../utils/fetchReadableStream';

const FormItem = Form.Item;
const Option = Select.Option;

class Modal extends Component {
  state = {
    fetching: false,
    data: [],
    editUsers: [],
    viewUsers: [],
    initialEditUsers: [],
    initialViewUsers: [],
  };

  resetState() {
    this.setState({
      fetching: false,
      data: [],
      editUsers: [],
      viewUsers: [],
      initialEditUsers: [],
      initialViewUsers: [],
      initialUsersSelected: [],
    });
  }

  componentDidMount() {
    this.submitSubscription = ChangesCaseFileSharing.getSubmit().subscribe(
      data => data && this.submit()
    );

    this.isOpenModalSubscription = ChangesCaseFileSharing.getIsOpenModal().subscribe(
      data => {
        data && this.getUsersSelected();
      }
    );
  }

  componentWillUnmount() {
    this.submitSubscription.unsubscribe();
    this.isOpenModalSubscription.unsubscribe();
  }

  getShareUsers = (filter = '') => {
    const { id: casefileId } = this.props;
    const pageSize = 50;
    const pageNumber = 0;
    return caseFileEndpoints.getShareUsersCasefile(casefileId, {
      pageSize,
      pageNumber,
      fullName: filter,
    });
  };

  fetchUser = (value = '') => {
    this.setState({ fetching: true });
    this.getShareUsers(value).then(response => {
      const {
        content: { data: users },
      } = response;
      // to filter out current user
      const userIntegrationId = get(this.props, 'user.userIntegrationId');
      const data = users
        .filter(user => user.integrationId !== userIntegrationId)
        .map(user => getUserData(user));

      this.setState({
        data,
        fetching: false,
      });
    });
  };

  getUsersSelected = () => {
    this.setState({ fetching: true });
    const { shares = [] } = this.props;
    if (shares.length > 0) {
      const ids = getSharesIds(shares);
      const filter = filterByIntegrationIds(ids);
      const agencyId = getAgencyId(this.props);

      getUsers(agencyId, filter).then(response => {
        const editUsers = [];
        const viewUsers = [];
        response.forEach(resp => {
          const { integrationId } = resp;
          const share = getSharesById(shares, integrationId);
          const user = getUserData(resp);
          if (share.mode === SHARE_EDIT) {
            editUsers.push(user);
          } else {
            viewUsers.push(user);
          }
        });
        this.setState({
          editUsers,
          viewUsers,
          fetching: false,
          initialEditUsers: editUsers,
          initialViewUsers: viewUsers,
        });
      });
    }
  };

  closeModal = (namesToShare, namesToUnshare) => {
    const { dispatch, title } = this.props;

    ChangesCaseFileSharing.setSubmit(false);
    ChangesCaseFileSharing.setCloseModal(false);
    if (!isEmpty(namesToShare) || !isEmpty(namesToUnshare)) {
      dispatch(openConfirmShare({ namesToShare, namesToUnshare, title }));
    }
    this.resetState();
  };

  getShares = () => {
    const {
      editUsers,
      viewUsers,
      initialEditUsers,
      initialViewUsers,
    } = this.state;
    const editAdded = getShareDifferece(initialEditUsers, editUsers);
    const viewAdded = getShareDifferece(initialViewUsers, viewUsers);
    const editRemoved = getShareDifferece(editUsers, initialEditUsers);
    const viewRemoved = getShareDifferece(viewUsers, initialViewUsers);
    return {
      share: [
        ...getShareList(editAdded, SHARE_EDIT),
        ...getShareList(viewAdded, SHARE_VIEW),
      ],
      unshare: [
        ...getShareList(editRemoved, SHARE_EDIT),
        ...getShareList(viewRemoved, SHARE_VIEW),
      ],
    };
  };

  catchError = error => {
    if (error?.response?.body instanceof ReadableStream) {
      fetchReadableStream(error.response.body, data => {
        const { errors = [] } = data;
        const description = errors.map(e => e.message).join(', ');
        notification.error({
          message: 'Something went wrong',
          description,
        });
      });
    } else {
      const description = error?.message || '';
      notification.error({
        message: 'Something went wrong',
        description,
      });
    }
  };

  submit = async () => {
    const { dispatch } = this.props;
    const { editUsers, viewUsers } = this.state;
    const shares = this.getShares();
    if (shares.share.length || shares.unshare.length) {
      const caseFileId = this.props.id;
      caseFileEndpoints
        .shareCaseFile(caseFileId, shares)
        .then(() => {
          dispatch(
            change(FORM_NAME, 'shares', [
              ...getShareList(editUsers, SHARE_EDIT),
              ...getShareList(viewUsers, SHARE_VIEW),
            ])
          );
          const namesToShare = getListOfNames(shares.share);
          const namesToUnshare = getListOfNames(shares.unshare);
          this.closeModal(namesToShare, namesToUnshare);
        })
        .catch(error => {
          this.catchError(error);
          this.closeModal();
        });
    }
  };

  handleChange = mode => usersSelected => {
    this.setState({
      [`${mode}Users`]: usersSelected,
      fetching: false,
    });
  };

  getSelectOptions = mode => {
    const users = mode === SHARE_EDIT ? 'viewUsers' : 'editUsers';
    const { data, [users]: selectedUsers } = this.state;

    return data.map(d => {
      const exist = some(selectedUsers, ['key', d.key]);
      return (
        <Option key={d.key} disabled={exist}>
          {d.label}
        </Option>
      );
    });
  };

  checkIfItsOwnerOrAdmin = () => {
    const {
      user: { permissions } = {},
      isCasefileClosed: { isOwner } = {},
    } = this.props;
    return (
      isOwner || hasPermissions(permissions, PERMISSIONS.manageAllCasefiles)
    );
  };

  render() {
    const { fetching, editUsers, viewUsers } = this.state;
    const { isCasefileClosed: { closed } = {} } = this.props;

    return (
      <ModalBody>
        <Form>
          <FormItem {...formItemLayout} label="Edit Case File" colon={false}>
            <Select
              disabled={closed ? !this.checkIfItsOwnerOrAdmin() : false}
              mode="multiple"
              labelInValue
              value={editUsers}
              placeholder="Please select"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onFocus={this.fetchUser}
              onSearch={this.fetchUser}
              onChange={this.handleChange(SHARE_EDIT)}
              style={{ width: '100%' }}
            >
              {this.getSelectOptions(SHARE_EDIT)}
            </Select>
          </FormItem>
          <FormItem {...formItemLayout} label="View Case File" colon={false}>
            <Select
              disabled={closed ? !this.checkIfItsOwnerOrAdmin() : false}
              mode="multiple"
              labelInValue
              value={viewUsers}
              placeholder="Please select"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onFocus={this.fetchUser}
              onSearch={this.fetchUser}
              onChange={this.handleChange(SHARE_VIEW)}
              style={{ width: '100%' }}
            >
              {this.getSelectOptions(SHARE_VIEW)}
            </Select>
          </FormItem>
        </Form>
      </ModalBody>
    );
  }
}

const mapState = (state, props) => {
  return {
    user: getCurrentUser(state),
    shares: get(state, 'forms.manageCaseFile.values.shares', []),
    id: get(state, 'forms.manageCaseFile.values.id', ''),
    title: get(state, 'forms.manageCaseFile.values.title', ''),
    isCasefileClosed: get(
      state,
      'forms.manageCaseFile.values.isCasefileClosed',
      ''
    ),
  };
};

export default connect(mapState)(Modal);
