/**
 * Calls exclusively used for the contribute to report feature
 */
import {
  addContributeToReportNotification,
  deleteContributeNotifications,
} from '../../../../api/activity';
import {
  SECTION_ASSIGN_ACTION,
  SECTION_COMPLETE_ACTION,
  CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
} from '../../../../constants/contributeToReport';

/**
 * Sends a custom notification to the user assigned as task
 * @param {Object} selectedForm session form
 * @param {Number} senderId active submitterId
 * @param {Number} receiverId user assigned a section
 */
export function sendAssignTaskNotification(selectedForm, senderId, receiverId) {
  const {
    meta: { formNumber, formType },
  } = selectedForm;
  addContributeToReportNotification(senderId, {
    title: `You have been added as a ${formNumber} contributor. Please complete your section`,
    body: `You have been added as a ${formNumber} contributor. Please complete your section`,
    data: { formId: selectedForm.meta.id, submitterId: senderId },
    action: SECTION_ASSIGN_ACTION,
    new: true,
    seen: null,
    actionItem: true,
    userId: receiverId,
    agencyId: null,
    isDismissable: false,
    type: CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
  });
}

/**
 * Deletes all the notifications for assignation for the user
 * @param {Number} receiverId user which notifications will be updated
 */
export function removeAssignTaskNotification(receiverId) {
  return deleteContributeNotifications(receiverId);
}

/**
 * Sends a custom notification to submitter when a section is completed
 * @param {Object} selectedForm session form
 * @param {Number} completedById user that completed the section
 */
export function sendSectionCompleteNotification(selectedForm, completedById) {
  const {
    meta: { formNumber, formType, submitterId },
    data: { __users: users },
  } = selectedForm;
  const contributorName = users[completedById].fullName;
  addContributeToReportNotification(submitterId, {
    title: `${contributorName} completed their section of ${formType} ${formNumber}`,
    body: `${contributorName} completed their section of ${formType} ${formNumber}`,
    data: { formId: selectedForm.meta.id, submitterId },
    action: SECTION_COMPLETE_ACTION,
    new: true,
    seen: null,
    actionItem: true,
    userId: submitterId,
    agencyId: null,
    isDismissable: false,
    type: CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
  });
}
