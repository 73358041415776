import React, { Component, Fragment } from 'react';
import { initialize, change } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { get, isEmpty, capitalize, isArray, last } from 'lodash';
import { Row, Col, Button, notification, Modal, Icon, Checkbox } from 'antd';
import ContentEditable from 'react-contenteditable';
import moment from 'moment';

import { getAgencyTZ } from '../../selectors/session';
import { unEscapeInput } from '../../utils/request';
import { unescape as htmlUnescape } from 'html-escaper';
import { hasFeatures, FEATURES } from '../../utils/features';

import parseDate, {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
} from '../../utils/parse-date';

import { getCurrentUser } from '../../selectors/session';
import PageHeader from 'APP_COMPONENTS/PageHeader/PageHeader';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import getRoute from 'APP_ROOT/utils/get-route';
import { hasPermissions, PERMISSIONS } from 'APP_ROOT/utils/admin';

import { getFormValues } from 'APP_ROOT/containers/administrator/agency-users/AgencyUsers.selectors';

import UserProfileSections from 'APP_ROOT/containers/administrator/agency-users/UserProfile/UserCustomSections/UserProfileSections';
import getSectionsData from '../administrator/agency-users/UserProfile/getSectionsData';
// import getSectionsValues from '../administrator/agency-users/UserProfile/getSectionsValues';
import CaseFileDocument from './components/CaseFileDocument';
import connectForm from '../administrator/components/connected-forms';
import {
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_LINK,
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_CUSTOM,
} from '../../components/custom-sections/sections/constants';
import { FORM_NAME } from './constants/share';
import {
  CASE_FILE_TASK_CLOSED,
  CASE_FILE_TASK_OPEN,
  CASE_FILE_TASK_CLOSED_DATA,
} from './constants/taskStatus';

// Containers
import DashboardPage from '../../components/dashboard';

// Components
//import MainTitle from '../../components/common/main-title';
import { getOfficersAndCivilians } from '../../api/users';
import caseFileEndpoints from '../../api/caseFileEndpoints/caseFileEndpoints';
import fetchReadableStream from './utils/fetchReadableStream';
import AddReportModal from './components/AddReportModal';
import AddNoteModal from './components/AddNoteModal';
import AddTaskModal from './components/AddTaskModal';

import getIsSharingEnabled from './utils/isSharingEnabled';
import ShareButton from './components/shareButton';
import getIsDeleteEnabled from './utils/isDeleteEnabled';
import DeleteButton from './components/deleteButton';

import { SHARE_EDIT, SHARE_VIEW } from './constants/share';

import './index.css';
import AddPredefinedTaskButton from './components/addPredefinedTaskButton/AddPredefinedTaskButton';
import AlertOverdueBanner from './components/alertOverdueBanner/AlertOverdueBanner';
import { CLOSED_STATUS } from '../administrator/agency-casefiles/components/statuses/constants';

const { confirm } = Modal;

const FILE_TITLE_ATTR = 'name';
const TITLE_DEFAULT = 'New Case File';
const NOTIFICATION_SUCCESS = 'success';
const NOTIFICATION_ERROR = 'error';
const CASE_FILE_OVERVIEW_SECTION = 'overview';
const CASE_FILE_REPORTS_SECTION = 'reports';
const CASE_FILE_DOCUMENTS_SECTION = 'documents';
const CASE_FILE_NOTES_SECTION = 'notes';
const CREATE_NEW = 'create-new';
const ACTION_CREATE = 'create';
const ACTION_UPDATE = 'update';
const ACTION_FETCH = 'fetch';
const ACTION_REMOVE = 'remove';
const ACTION_ADD = 'add';
const REPORT_ID = 'reportId';
const REPORT_LOCATION = 'locationUrl';
const DOCUMENT_ID = 'id';
const DOCUMENT_LOCATION = 'locationUrl';
const CASE_FILE_REPORTS_TABLE_FIELDS = [
  'objectValueId',
  'reportNumber',
  'reportType',
  'reportId',
  'locationUrl',
  'createdAt',
  'isAssociated',
];
const CASE_FILE_NOTES_TABLE_FIELDS = [
  'objectValueId',
  'details',
  'createdAt',
  'updatedAt',
  'lastModifiedBy',
  'createdBy',
  'createdByFullName',
  'updatedByFullName',
  'created',
  'updated',
];
const CASE_FILE_TASK_LIST_FIELDS = [
  'assignee',
  'assigneeFullName',
  'clonedFromTaskId',
  'createTime',
  'created',
  'creator',
  'creatorFullName',
  'description',
  'dueDate',
  'executionId',
  'followUpDate',
  'name',
  'objectValueId',
  'processDefinitionId',
  'processInstanceId',
  'status',
  'taskDefinitionKey',
  'tenantId',
  'updated',
];
const CASE_FILE_TASK_LIST_SECTION = 'tasks';
const CASE_FILE_TYPE_ATTRIBUTE_ID = 'd241e3dd-da82-4df1-bc4f-69e4af9bc41b';
const CASE_FILE_DEADLINE_ATTRIBUTE_ID = '0e4f1026-39a6-4b38-a7d3-dedba3376a42';
const CASE_FILE_REASON_ATTRIBUTE_ID = 'e395b33a-9502-4b36-af57-1dce2d801f04';
const CASE_FILE_OUTCOME_ATTRIBUTE_ID = '0c55712e-64ed-41ed-bf2c-fcdd3f73fc88';
const CASE_FILE_INDIVIDUAL_ATTRIBUTE_ID =
  '8f993e9a-8900-4230-b49c-36b63cf16147';
const CASE_FILE_STATUS_ATTRIBUTE_ID = '3cbdf87f-bce9-4441-9915-be7cc8a19c09';
const CASE_FILE_TYPE_ATTRIBUTE_NAME = 'typeId';
const CASE_FILE_OVERVIEW_ATTRIBUTE_NAME = 'name';
const CASE_FILE_OVERVIEW_ATTRIBUTE_TITLE = 'Title';
const DATE_PATTERN = /^([0-9]{4}-[0-9]{2}-[0-9]{2})$/;

const mockedSections = {
  sections: [
    {
      sectionId: CASE_FILE_OVERVIEW_SECTION,
      title: 'Case File Overview',
      type: 'form',
      readOnly: false,
      settings: { order: 1, collapsedByDefault: false },
      sectionAttributes: [
        {
          sectionAttributeId: 'b044f16d-a620-4402-8cb6-dc322958efed',
          attributeId: 'a7aa2986-d87e-4d30-acfe-85643a476ad7',
          attributeName: CASE_FILE_OVERVIEW_ATTRIBUTE_NAME,
          title: CASE_FILE_OVERVIEW_ATTRIBUTE_TITLE,
          settings: {
            formOrder: 1,
            formColumn: 1,
            tableOrder: null,
            tableColumn: null,
            maxLength: 200,
          },
          type: ATTRIBUTE_TYPE_STRING,
          isArray: null,
          validValues: null,
          parentAttributeId: null,
        },
        {
          sectionAttributeId: '36efe79b-fb29-4bec-abe1-11f309b03057',
          attributeId: '0e4f1026-39a6-4b38-a7d3-dedba3376a45',
          attributeName: 'createdAt',
          title: 'Created on',
          settings: {
            formOrder: 9,
            formColumn: 1,
            tableOrder: null,
            tableColumn: null,
          },
          type: ATTRIBUTE_TYPE_DATE,
          editable: false,
          isArray: null,
          validValues: null,
          parentAttributeId: null,
          isConstantValue: true,
        },
        {
          sectionAttributeId: '60d9313c-d9cd-4be0-a6f8-6418a0603212',
          attributeId: '03876346-2ab2-4f1e-9d09-c26c3bc98d3e',
          attributeName: 'description',
          title: 'Description',
          settings: {
            formOrder: 3,
            formColumn: 1,
            tableOrder: null,
            tableColumn: null,
            maxLength: 500,
          },
          type: ATTRIBUTE_TYPE_STRING,
          isArray: null,
          validValues: null,
          parentAttributeId: null,
        },
        {
          sectionAttributeId: '05b386ca-9bd3-429a-9bc2-5e387bb2898f',
          attributeId: '7277816f-0b90-4d98-9c8b-76e0b64ad99e',
          attributeName: 'createdByFullName',
          title: 'Owner',
          settings: {
            formOrder: 7,
            formColumn: 1,
            tableOrder: null,
            tableColumn: null,
            maxLength: 500,
          },
          type: ATTRIBUTE_TYPE_STRING,
          editable: false,
          isArray: false,
          validValues: [],
          parentAttributeId: null,
          isConstantValue: true,
        },
        {
          sectionAttributeId: '05b386ca-9bd3-429a-9bc2-5e387bb2895f',
          attributeId: CASE_FILE_TYPE_ATTRIBUTE_ID,
          attributeName: CASE_FILE_TYPE_ATTRIBUTE_NAME,
          title: 'Type',
          settings: {
            formOrder: 5,
            formColumn: 1,
            tableOrder: null,
            tableColumn: null,
            maxLength: 500,
          },
          type: ATTRIBUTE_TYPE_STRING,
          editable: false,
          isArray: false,
          validValues: [],
          parentAttributeId: null,
        },
      ],
      formValues: [],
    },
  ],
};

const mockedReports = {
  sections: [
    {
      sectionId: CASE_FILE_REPORTS_SECTION,
      title: 'Related Reports',
      type: 'table',
      readOnly: false,
      settings: { order: 1, collapsedByDefault: false },
      sectionAttributes: [
        {
          attributeName: 'reportNumber',
          type: ATTRIBUTE_TYPE_LINK,
          title: 'Report Number',
          settings: { tableColumn: 1 },
        },
        {
          attributeName: 'reportType',
          type: ATTRIBUTE_TYPE_STRING,
          title: 'Report Type',
          settings: { tableColumn: 2 },
        },
      ],
      tableValues: [],
    },
  ],
};

const mockedDocument = {
  sections: [
    {
      sectionId: CASE_FILE_DOCUMENTS_SECTION,
      title: 'Documents',
      type: 'table',
      readOnly: false,
      settings: { order: 1, collapsedByDefault: false },
      sectionAttributes: [
        // {
        //   attributeName: 'description',
        //   type: ATTRIBUTE_TYPE_STRING,
        //   title: 'Description',
        //   settings: { tableColumn: 1, unique: false },
        // },
        {
          attributeName: 'fileName',
          type: ATTRIBUTE_TYPE_LINK,
          title: 'File',
          settings: { tableColumn: 2 },
        },
        {
          attributeName: 'createdAt',
          type: ATTRIBUTE_TYPE_DATE,
          title: 'Upload Date',
          settings: { tableColumn: 3, defaultValue: null },
        },
      ],
      formValues: [],
    },
  ],
};

const mockedNotes = {
  sections: [
    {
      sectionId: CASE_FILE_NOTES_SECTION,
      title: 'Notes',
      type: 'table',
      readOnly: false,
      settings: { order: 1, collapsedByDefault: false },
      sectionAttributes: [
        {
          attributeName: 'details',
          type: ATTRIBUTE_TYPE_CUSTOM,
          title: 'Note Details',
          settings: { tableColumn: 1, maxLength: 2500 },
        },
        {
          attributeName: 'created',
          type: ATTRIBUTE_TYPE_CUSTOM,
          title: 'Created',
          settings: {
            tableColumn: 2,
            sorter: (a, b) =>
              (a.created.date || '').localeCompare(b.created.date || ''),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
          },
        },
        {
          attributeName: 'updated',
          type: ATTRIBUTE_TYPE_CUSTOM,
          title: 'Updated',
          settings: {
            tableColumn: 3,
            sorter: (a, b) =>
              ((a.updated.user && a.updated.date) || '').localeCompare(
                (b.updated.user && b.updated.date) || ''
              ),
            sortDirections: ['descend', 'ascend'],
          },
        },
      ],
      tableValues: [],
    },
  ],
};

// TABLE DEFINITION FOR THE NEW TABLE TASK LIST
const mockedTaskList = {
  sections: [
    {
      sectionId: CASE_FILE_TASK_LIST_SECTION,
      title: 'Task List',
      type: 'table',
      readOnly: false,
      settings: { order: 1, collapsedByDefault: false },
      sectionAttributes: [
        {
          attributeName: 'status',
          type: ATTRIBUTE_TYPE_CUSTOM,
          title: 'Status',
          settings: {
            tableColumn: 1,
            width: '100px',
            sorter: (a, b) => {
              if (a.status.status > b.status.status) {
                return 1;
              } else if (a.status.status < b.status.status) {
                return -1;
              }
              return 0;
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
          },
        },
        {
          attributeId: 'a7aa2986-d87e-4d30-acfe-85643a476ad7',
          attributeName: 'description',
          type: ATTRIBUTE_TYPE_CUSTOM,
          title: 'Task Details',
          settings: { tableColumn: 2, maxLength: 60 },
        },
        {
          attributeId: '0e4f1026-39a6-4b38-a7d3-dedba3376a45',
          attributeName: 'assigneeFullName',
          type: ATTRIBUTE_TYPE_CUSTOM,
          title: 'Assignee',
          settings: { tableColumn: 3 },
        },
        {
          attributeId: '03876346-2ab2-4f1e-9d09-c26c3bc98d3e',
          attributeName: 'dueDate',
          type: ATTRIBUTE_TYPE_CUSTOM,
          title: 'Due Date',
          settings: {
            tableColumn: 4,
            sorter: (a, b) => {
              if (!a.dueDate) {
                return 1;
              } else if (!b.dueDate) {
                return -1;
              }
              const dateAFormatted = new Date(a.dueDate);
              const dateBFormatted = new Date(b.dueDate);

              return (dateAFormatted.toISOString() || '').localeCompare(
                dateBFormatted.toISOString() || ''
              );
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
          },
        },
      ],
      tableValues: [],
    },
  ],
};

class CaseFile extends Component {
  constructor(props) {
    super(props);
    const { currentUser: { permissions, featureFlags } = {} } = props;

    const enableTaskListPanelFeature = hasFeatures(
      featureFlags,
      FEATURES.enableCaseFileTaskListPanel
    );

    const enableCasefileKeyDetails = hasFeatures(
      featureFlags,
      FEATURES.enableCasefileKeyDetails
    );

    const editable = hasPermissions(permissions, [
      PERMISSIONS.manageAllCasefiles,
      PERMISSIONS.manageMyCasefiles,
    ]);

    const sectionsDefinition = this.getSectionsDefinition(
      editable,
      enableCasefileKeyDetails
    );

    const { sections: mockedNotesSections } = mockedNotes;
    this.notesDefinition = {
      ...mockedNotes,
      sections: mockedNotesSections.map(section => ({
        ...section,
        sectionAttributes: section.sectionAttributes.map(a => ({
          ...a,
          settings: {
            ...a.settings,
            render: this.renderNoteField,
          },
        })),
      })),
    };

    const { sections: mockedTasksSections } = mockedTaskList;
    this.tasksDefinition = {
      ...mockedTaskList,
      sections: mockedTasksSections.map(section => ({
        ...section,
        sectionAttributes: section.sectionAttributes.map(a => ({
          ...a,
          settings: {
            ...a.settings,
            render: this.renderTaskField,
          },
        })),
      })),
    };

    this.state = {
      editable,
      loading: true,
      loadingTaskChangeStatus: false,
      title: TITLE_DEFAULT,
      sectionsDefinition,
      id: '',
      reportsSections: [],
      notesSections: [],
      tasksSections: [],
      isTaskListAvailable: enableTaskListPanelFeature,
      isKeyDetailsAvailable: enableCasefileKeyDetails,
      shares: [],
      isTaskListLoading: false,
      isPredefinedTaskLoading: false,
      savingCasefile: false,
      casefileTypeId: '',
      statuses: [],
      isCasefileClosed: false,
    };

    this.formName = FORM_NAME;
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getCasefileData();
    this.setState({
      tasksSections: mockedTaskList.sections,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { status: prevStatus, statuses: prevStatuses } = prevState;
    const { status: currStatus, statuses: currStatuses } = this.state;
    const statusChanged = prevStatus !== currStatus;
    const statusesFilled =
      prevStatuses !== currStatuses && currStatuses && currStatuses.length > 0;
    if (statusChanged || statusesFilled) {
      this.disableFieldsIfCasefileIsClosed();
    }
  }

  getSectionsWithKeyDetails() {
    const { sections } = mockedSections;
    return {
      sections: sections.map(section => ({
        ...section,
        sectionAttributes: [
          ...section.sectionAttributes,
          {
            sectionAttributeId: '36efe79b-fb29-4bec-abe1-11f309b03053',
            attributeId: CASE_FILE_DEADLINE_ATTRIBUTE_ID,
            attributeName: 'deadline',
            title: 'Deadline',
            settings: {
              formOrder: 2,
              formColumn: 2,
              tableOrder: null,
              tableColumn: null,
            },
            type: ATTRIBUTE_TYPE_DATE,
            editable: false,
            isArray: null,
            validValues: null,
            parentAttributeId: null,
            disabledDate: current => {
              return current && current < moment().startOf('day');
            },
            formatDateWithoutTimezone: value =>
              value &&
              moment(
                moment
                  .utc(value)
                  .startOf('day')
                  .format(BENCHMARK_DATE_FORMAT)
              ),
          },
        ],
      })),
    };
  }

  getCasefileData(isOverviewAlreadyLoaded = false) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.fetchData(id, isOverviewAlreadyLoaded);
  }

  getSectionsDefinition = (editable = false, isKeyDetailsAvailable = false) => {
    let usableSections;
    const { sections = [] } = isKeyDetailsAvailable
      ? this.getSectionsWithKeyDetails()
      : mockedSections;

    if (this.state === undefined) {
      usableSections = [...sections];
    } else {
      usableSections = [...this.state.sectionsDefinition];
    }

    return usableSections.map(section => ({
      ...section,
      sectionAttributes: section.sectionAttributes.map(a => ({
        ...a,
        editable: a.isConstantValue ? a.editable : editable,
      })),
    }));
  };

  updateSectionDefinitionDeadline = sections => {
    return sections.map(section => ({
      ...section,
      sectionAttributes: section.sectionAttributes.map(a => ({
        ...a,
        editable:
          a.attributeId === CASE_FILE_DEADLINE_ATTRIBUTE_ID
            ? this.checkIfItsOwnerOrAdmin()
            : a.editable,
      })),
    }));
  };

  fetchTaskListData = async id => {
    try {
      this.setState({ isTaskListLoading: true });
      const response = await caseFileEndpoints.getCasefileTaskList(id);
      this.setState({ isTaskListLoading: false });
      return response;
    } catch (error) {
      this.catchError(error, ACTION_FETCH);
      return {};
    }
  };

  fetchData = (id, isOverviewAlreadyLoaded = false) => {
    const { isKeyDetailsAvailable } = this.state;
    if (id && id !== CREATE_NEW) {
      this.setState({ id });

      caseFileEndpoints
        .getCasefile(id)
        .then(async response => {
          if (!this.state.isTaskListAvailable) {
            return response;
          }
          try {
            const { shares } = response.content;
            const taskList = await this.fetchTaskListData(id);
            response.content.tasks = taskList.content.map(task => ({
              ...task,
              status: {
                id: task.id,
                status: task.status === CASE_FILE_TASK_CLOSED_DATA,
              },
            }));
            this.setState({ shares });
            return response;
          } catch (e) {
            this.catchError(e, ACTION_FETCH);
          }
          return response;
        })
        .then(response => {
          const {
            individual,
            status,
            reasons,
            outcomes,
            type,
          } = response.content;
          this.processResponse(ACTION_FETCH)(response);

          const { sectionsDefinition } = this.state;
          const updatedSectionsDefinition = this.updateSectionDefinitionDeadline(
            sectionsDefinition
          );

          this.setState({
            sectionsDefinition: updatedSectionsDefinition,
            casefileTypeId: type.id,
          });

          return {
            casefileTypeId: type.id,
            casefileId: id,
            previousSelectedReasons: reasons,
            previousSelectedOutcomes: outcomes,
            previousSelectedStatus: status,
            previousSelectedIndividual: individual,
          };
        })
        .then(data => {
          if (!isOverviewAlreadyLoaded && isKeyDetailsAvailable) {
            this.fillStatusField(data);
            this.fillReasonsField(data);
            this.fillOutcomesField(data);
            this.fillIndividualField(data);
          }
        })
        .catch(error => this.catchError(error, ACTION_FETCH))
        .finally(() => this.setDoneLoading());
    } else {
      const {
        currentUser: { organizationUnit: { tenantId } = {} } = {},
      } = this.props;
      const { editable } = this.state;

      if (editable) {
        caseFileEndpoints
          .getCasefileTypes(tenantId)
          .then(response => {
            const { success, content } = response;
            if (success) {
              this.updateCasefileTypes(content, true);
            } else {
              this.processErrorResponse('', response);
            }
          })
          .catch(error => this.catchError(error));
      }

      this.loadData({
        data: isKeyDetailsAvailable
          ? this.getSectionsWithKeyDetails()
          : mockedSections,
      });

      if (!isOverviewAlreadyLoaded && isKeyDetailsAvailable) {
        this.fillIndividualField();
      }
      this.setDoneLoading();
    }
  };

  updateCasefileTypes = (_types, editable = false) => {
    const { sectionsDefinition } = this.state;
    const getAttributes = attributes => {
      const removeType = isEmpty(_types);
      const types = isArray(_types) ? _types : [_types];
      return removeType
        ? attributes.filter(a => a.attributeId !== CASE_FILE_TYPE_ATTRIBUTE_ID)
        : attributes.map(a =>
            a.attributeId === CASE_FILE_TYPE_ATTRIBUTE_ID
              ? {
                  ...a,
                  validValues: types.map(t => ({
                    label: t.name,
                    value: t.id,
                  })),
                  editable,
                }
              : a
          );
    };

    this.setState({
      sectionsDefinition: sectionsDefinition.map(section => ({
        ...section,
        sectionAttributes: getAttributes(section.sectionAttributes),
      })),
    });
  };

  fillReasonsField = ({
    casefileTypeId,
    casefileId,
    previousSelectedReasons,
  }) => {
    caseFileEndpoints
      .getCasefileTypeReasonsWithDeletedReasons(casefileTypeId, casefileId)
      .then(response => {
        const { success, content } = response;
        if (success) {
          const unEscapedContent = content.map(reason => ({
            ...reason,
            label: htmlUnescape(reason.label),
          }));
          if (unEscapedContent.length > 0) {
            this.updateReasonsField(unEscapedContent, previousSelectedReasons);
          }
        } else {
          this.processErrorResponse('', response);
        }
      })
      .catch(error => this.catchError(error, ACTION_FETCH));
  };

  updateReasonsField = (reasons, previousSelectedReasons) => {
    const { sectionsDefinition } = this.state;
    const { dispatch } = this.props;

    const getAttributes = attributes => {
      const modifiedAttributes = [...attributes];
      const workableReasons = isArray(reasons) ? reasons : [reasons];
      const reasonSection = {
        sectionAttributeId: '05b386ca-9bd3-429a-9bc2-5e387bb2895f',
        attributeId: CASE_FILE_REASON_ATTRIBUTE_ID,
        attributeName: 'reasons',
        title: 'Reason(s)',
        settings: {
          formOrder: 8,
          formColumn: 2,
          tableOrder: null,
          tableColumn: null,
          maxLength: 500,
        },
        type: ATTRIBUTE_TYPE_STRING,
        allowClear: true,
        isArray: true,
        validValues: workableReasons.map(r => ({
          label: r.label,
          value: r.id,
          isDeleted: r.isDeleted,
        })),
        editable: this.checkIfItsOwnerOrAdmin(),
        onDeselect: data => {
          const { sectionsDefinition } = this.state;
          const indexReasons = sectionsDefinition[0].sectionAttributes.findIndex(
            attribute => attribute.attributeId === CASE_FILE_REASON_ATTRIBUTE_ID
          );
          if (indexReasons === -1) {
            return;
          }

          const newSectionsAttributes = [
            ...sectionsDefinition[0].sectionAttributes,
          ];
          const newValidValues = newSectionsAttributes[
            indexReasons
          ].validValues.filter(({ value, isDeleted }) =>
            value !== data ? true : !isDeleted
          );
          newSectionsAttributes[indexReasons].validValues = newValidValues;
          this.setState({
            sectionsDefinition: sectionsDefinition.map(section => ({
              ...section,
              sectionAttributes: newSectionsAttributes,
            })),
          });
        },
        parentAttributeId: null,
      };

      modifiedAttributes.push(reasonSection);
      return modifiedAttributes;
    };

    this.setState({
      sectionsDefinition: sectionsDefinition.map(section => ({
        ...section,
        sectionAttributes: getAttributes(section.sectionAttributes),
      })),
    });

    dispatch(
      change(
        'manageCaseFile',
        'sectionsData.overview.reasons',
        previousSelectedReasons
      )
    );
  };

  disableFieldsIfCasefileIsClosed = () => {
    const { id, casefileTypeId, statuses } = this.state;
    if (!statuses || isEmpty(statuses)) {
      this.fetchStatuses({ casefileTypeId, casefileId: id }).then(
        newStatuses => {
          const isCasefileClosed = this.checkIfCasefileIsClosed(newStatuses);
          if (isCasefileClosed) {
            this.toggleCasefileReadonly(true);
            this.markCasefileAsClosed();
          } else {
            this.toggleCasefileReadonly(false);
            this.markCasefileAsOpen();
          }
        }
      );
    } else {
      const isCasefileClosed = this.checkIfCasefileIsClosed(statuses);
      if (isCasefileClosed) {
        this.toggleCasefileReadonly(true);
        this.markCasefileAsClosed();
      } else {
        this.toggleCasefileReadonly(false);
        this.markCasefileAsOpen();
      }
    }
  };

  markCasefileAsOpen = () => {
    const { isOwner } = this.state;
    const { dispatch } = this.props;
    this.setState({ isCasefileClosed: false });
    dispatch(
      change('manageCaseFile', 'isCasefileClosed', {
        closed: false,
        isOwner,
      })
    );
  };

  markCasefileAsClosed = () => {
    const { isOwner } = this.state;
    const { dispatch } = this.props;
    this.setState({ isCasefileClosed: true });
    dispatch(
      change('manageCaseFile', 'isCasefileClosed', {
        closed: true,
        isOwner,
      })
    );
  };

  toggleCasefileReadonly = readOnly => {
    const { sectionsDefinition } = this.state;
    const newSectionsDefinition = sectionsDefinition.map(section => ({
      ...section,
      readOnly,
    }));
    this.setState({ sectionsDefinition: newSectionsDefinition });
  };

  fetchStatuses = async ({
    casefileTypeId,
    casefileId,
    previousSelectedStatus = '',
  }) => {
    try {
      const response = await caseFileEndpoints.getCasefileTypeStatusesWithDeletedStatuses(
        casefileTypeId,
        casefileId
      );
      const { success, content } = response;
      if (success) {
        const unEscapedContent = content.map(status => ({
          ...status,
          label: htmlUnescape(status.label),
        }));
        if (unEscapedContent.length > 0) {
          const sorted = unEscapedContent.sort((a, b) => {
            // Move DELETED ones to the top
            if (a.isDeleted) {
              return -1;
            } else if (b.isDeleted) {
              return 1;
            }
            // DESC order with CLOSED on bottom
            if (b.type === CLOSED_STATUS || a.order > b.order) {
              return -1;
            }
            return 1;
          });
          const statuses = isArray(sorted) ? sorted : [sorted];
          this.setState({ status: previousSelectedStatus, statuses });
          return statuses;
        }
      } else {
        this.processErrorResponse('', response);
      }
    } catch (error) {
      this.catchError(error, ACTION_FETCH);
    }
    return [];
  };

  fillStatusField = ({
    casefileTypeId,
    casefileId,
    previousSelectedStatus,
  }) => {
    this.fetchStatuses({ casefileTypeId, casefileId, previousSelectedStatus })
      .then(statuses => {
        this.updateStatusField(statuses, previousSelectedStatus);
      })
      .catch(error => this.catchError(error, ACTION_FETCH));
  };

  updateStatusField = (statuses, previousSelectedStatus) => {
    const { sectionsDefinition } = this.state;
    const { dispatch } = this.props;
    const getAttributes = attributes => {
      const modifiedAttributes = [...attributes];
      const statusSection = {
        sectionAttributeId: 'bd0d6b03-6b4e-4568-871c-d54256fd58bd',
        attributeId: CASE_FILE_STATUS_ATTRIBUTE_ID,
        attributeName: 'status',
        title: 'Status',
        settings: {
          formOrder: 4,
          formColumn: 2,
          tableOrder: null,
          tableColumn: null,
          showSearch: true,
        },
        type: ATTRIBUTE_TYPE_STRING,
        filterOption: (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
        forceEnable: this.checkIfItsOwnerOrAdmin(),
        editable: this.checkIfItsOwnerOrAdmin(),
        isArray: false,
        parentAttributeId: null,
        validValues: statuses.map(status => ({
          value: status.id,
          label: status.label,
        })),
        onChange: value => {
          if (Object.keys(value).length === 1) {
            dispatch(
              change('manageCaseFile', 'sectionsData.overview.status', null)
            );
          }
        },
      };
      modifiedAttributes.push(statusSection);
      return modifiedAttributes;
    };

    this.setState({
      sectionsDefinition: sectionsDefinition.map(section => ({
        ...section,
        sectionAttributes: getAttributes(section.sectionAttributes),
      })),
    });

    dispatch(
      change(
        'manageCaseFile',
        'sectionsData.overview.status',
        previousSelectedStatus
      )
    );
  };

  fillOutcomesField = ({
    casefileTypeId,
    casefileId,
    previousSelectedOutcomes,
  }) => {
    caseFileEndpoints
      .getCasefileTypeOutcomesWithDeletedOutcomes(casefileTypeId, casefileId)
      .then(response => {
        const { success, content } = response;
        if (success) {
          const unEscapedContent = content.map(outcome => ({
            ...outcome,
            label: htmlUnescape(outcome.label),
          }));
          if (unEscapedContent.length > 0) {
            this.updateOutcomesField(
              unEscapedContent,
              previousSelectedOutcomes
            );
          }
        } else {
          this.processErrorResponse('', response);
        }
      })
      .catch(error => this.catchError(error, ACTION_FETCH));
  };

  updateOutcomesField = (outcomes, previousSelectedOutcomes) => {
    const { sectionsDefinition } = this.state;
    const { dispatch } = this.props;

    const getAttributes = attributes => {
      const modifiedAttributes = [...attributes];
      const workableData = isArray(outcomes) ? outcomes : [outcomes];
      const outcomesSection = {
        sectionAttributeId: '05b386ca-9bd3-429a-9bc2-5e387bb2895f',
        attributeId: CASE_FILE_OUTCOME_ATTRIBUTE_ID,
        attributeName: 'outcomes',
        title: 'Outcome(s)',
        settings: {
          formOrder: 10,
          formColumn: 2,
          tableOrder: null,
          tableColumn: null,
          maxLength: 500,
        },
        type: ATTRIBUTE_TYPE_STRING,
        allowClear: true,
        isArray: true,
        parentAttributeId: null,
        validValues: workableData.map(r => ({
          label: r.label,
          value: r.id,
          isDeleted: r.isDeleted,
        })),
        editable: this.checkIfItsOwnerOrAdmin(),
        onDeselect: data => {
          const { sectionsDefinition } = this.state;
          const indexOutcomes = sectionsDefinition[0].sectionAttributes.findIndex(
            attribute =>
              attribute.attributeId === CASE_FILE_OUTCOME_ATTRIBUTE_ID
          );
          if (indexOutcomes === -1) {
            return;
          }

          const newSectionsAttributes = [
            ...sectionsDefinition[0].sectionAttributes,
          ];
          const newValidValues = newSectionsAttributes[
            indexOutcomes
          ].validValues.filter(({ value, isDeleted }) =>
            value !== data ? true : !isDeleted
          );
          newSectionsAttributes[indexOutcomes].validValues = newValidValues;
          this.setState({
            sectionsDefinition: sectionsDefinition.map(section => ({
              ...section,
              sectionAttributes: newSectionsAttributes,
            })),
          });
        },
      };
      modifiedAttributes.push(outcomesSection);
      return modifiedAttributes;
    };

    this.setState({
      sectionsDefinition: sectionsDefinition.map(section => ({
        ...section,
        sectionAttributes: getAttributes(section.sectionAttributes),
      })),
    });

    dispatch(
      change(
        'manageCaseFile',
        'sectionsData.overview.outcomes',
        previousSelectedOutcomes
      )
    );
  };

  fillIndividualField = ({ previousSelectedIndividual } = {}) => {
    const {
      currentUser: {
        agency: { id },
      },
    } = this.props;

    getOfficersAndCivilians(id)
      .then(response =>
        this.updateIndividuals(response, previousSelectedIndividual)
      )
      .catch(error => this.catchError(error, ACTION_FETCH));
  };

  updateIndividuals = (individuals, previousSelectedIndividual) => {
    const currentIndividual = previousSelectedIndividual
      ? previousSelectedIndividual?.integrationId
      : '';
    const { sectionsDefinition } = this.state;
    const { dispatch } = this.props;
    const getAttributes = attributes => {
      const modifiedAttributes = [...attributes];
      const workableUser = isArray(individuals) ? individuals : [individuals];
      const individualSection = {
        sectionAttributeId: '05b386ca-9bd3-429a-9bc2-5e387bb2895f',
        attributeId: CASE_FILE_INDIVIDUAL_ATTRIBUTE_ID,
        attributeName: 'individual',
        title: 'Individual',
        settings: {
          formOrder: 6,
          formColumn: 2,
          tableOrder: null,
          tableColumn: null,
          showSearch: true,
        },
        type: ATTRIBUTE_TYPE_STRING,
        allowClear: true,
        filterOption: (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
        editable: this.checkIfItsOwnerOrAdmin(),
        isArray: false,
        parentAttributeId: null,
        validValues: workableUser.map(t => ({
          label: t.fullName,
          value: t.integrationId,
        })),
        onChange: value => {
          if (Object.keys(value).length === 1) {
            dispatch(
              change('manageCaseFile', 'sectionsData.overview.individual', null)
            );
          }
        },
      };
      modifiedAttributes.push(individualSection);
      return modifiedAttributes;
    };

    this.setState({
      sectionsDefinition: sectionsDefinition.map(section => ({
        ...section,
        sectionAttributes: getAttributes(section.sectionAttributes),
      })),
    });

    dispatch(
      change(
        'manageCaseFile',
        'sectionsData.overview.individual',
        currentIndividual
      )
    );
  };

  reduceDocuments = (docs, doc) => {
    const { timezone } = this.props;
    return Object.keys(doc).reduce((obj, _key) => {
      const key = _key === 'name' ? 'fileName' : _key;
      const data =
        _key === 'createdAt'
          ? parseDate(doc[_key], timezone, BENCHMARK_DATE_FORMAT)
          : doc[_key];

      if (obj[key]) {
        obj[key].push(data);
      } else {
        obj[key] = [data];
      }
      if (key === DOCUMENT_ID) {
        const location = caseFileEndpoints.getDocumentDownloadParams(
          this.state.id,
          data
        );
        if (obj[DOCUMENT_LOCATION]) {
          obj[DOCUMENT_LOCATION].push(location);
        } else {
          obj[DOCUMENT_LOCATION] = [location];
        }
      }
      return obj;
    }, docs);
  };

  reduceReports = (reports, report) => {
    return Object.keys(report).reduce((obj, _key) => {
      const key = _key === 'id' ? 'objectValueId' : _key;
      const data = report[_key];

      if (obj[key]) {
        obj[key].push(data);
      } else {
        obj[key] = [data];
      }
      if (key === REPORT_ID) {
        const location = getRoute('report', { id: data });
        if (obj[REPORT_LOCATION]) {
          obj[REPORT_LOCATION].push(location);
        } else {
          obj[REPORT_LOCATION] = [location];
        }
      }
      return obj;
    }, reports);
  };

  renderNoteField = (value, record) => {
    if (value && value.user) {
      const { timezone } = this.props;
      const date = parseDate(value.date, timezone, BENCHMARK_DATE_TIME_FORMAT);
      return `${date} by ${value.user}`;
    } else if (typeof value === 'string') {
      return <span>{value}</span>;
    }
    return '';
  };

  renderTaskField = (value, record) => {
    const DATE_PATTERN = /^([0-9]{4}-[0-9]{2}-[0-9]{2})$/;

    if (!value) return '';

    if (typeof value === 'object') {
      return this.renderTaskCheckbox(value, record);
    } else if (typeof value === 'string' && DATE_PATTERN.test(value)) {
      return this.renderTaskDate(value, record);
    } else {
      return this.renderTaskString(value, record);
    }
  };

  handleChangeTaskCheckbox = (e, record) => {
    const { dispatch } = this.props;
    const { id: casefileId } = this.state;
    this.setState({ loadingTaskChangeStatus: true });
    const tasksValues = this.props.profileForm.values.sectionsData.tasks;
    const updatedStatus = JSON.parse(JSON.stringify(tasksValues.status));
    const indexFound = updatedStatus.findIndex(
      ({ id }) => id === record.status.id
    );
    if (indexFound === -1) {
      return;
    }
    updatedStatus[indexFound].status = e.target.checked;
    const statusToSend = updatedStatus[indexFound].status
      ? CASE_FILE_TASK_CLOSED
      : CASE_FILE_TASK_OPEN;

    caseFileEndpoints
      .updateTaskStatus(casefileId, updatedStatus[indexFound].id, statusToSend)
      .then(async () => {
        const { content } = await this.fetchTaskListData(this.state.id);

        const formattedContent = content.map(task => ({
          ...task,
          status: {
            id: task.id,
            status: task.status === CASE_FILE_TASK_CLOSED_DATA,
          },
        }));
        const tasksTableValues = formattedContent.reduce(this.reduceTasks, {});
        const tasksData = this.addTableValues(
          this.tasksDefinition,
          CASE_FILE_TASK_LIST_SECTION,
          tasksTableValues
        );

        dispatch(
          change(
            'manageCaseFile',
            `sectionsData.tasks`,
            tasksData.sections[0].tableValues
          )
        );
      })
      .catch(error => this.catchError(error, ACTION_UPDATE))
      .finally(() => this.setState({ loadingTaskChangeStatus: false }));
  };

  renderTaskString = (value, record) => {
    return (
      <span
        className={`${record.status.status ? 'casefile-task-done' : undefined}`}
      >
        {value}
      </span>
    );
  };

  renderTaskCheckbox = (value, record) => {
    const { loadingTaskChangeStatus, editable, isCasefileClosed } = this.state;
    return (
      <Checkbox
        defaultChecked={value.status}
        onChange={e => this.handleChangeTaskCheckbox(e, record)}
        disabled={loadingTaskChangeStatus || !editable || isCasefileClosed}
      />
    );
  };

  renderTaskDate = (data, record) => {
    if (!data) {
      return '';
    }

    const { timezone } = this.props;
    const { status } = record.status;
    const date = parseDate(data, timezone, BENCHMARK_DATE_FORMAT);
    const today = new Date();
    const dueDate = new Date(data);
    const isDateOverdue =
      today.toISOString().localeCompare(dueDate.toISOString()) > 0;
    const shouldRenderAlert = !status && isDateOverdue;

    let classNameForSpan = '';
    if (shouldRenderAlert) {
      classNameForSpan = 'due-date-error-color';
    } else if (status) {
      classNameForSpan = 'casefile-task-done';
    }

    return (
      <>
        <span className={classNameForSpan}>{date}</span>
        {shouldRenderAlert && (
          <Icon
            type="exclamation-circle"
            theme="filled"
            className="casefile-icon-due-date due-date-error-color"
          />
        )}
      </>
    );
  };

  mixNotesData = (data, field) => {
    const value = {
      user: last(data[`${field}ByFullName`]),
      date: last(data[`${field}At`]),
    };
    if (data[field]) {
      data[field].push(value);
    } else {
      data[field] = [value];
    }
  };

  reduceNotes = (notes, note, index) => {
    const reducedData = Object.keys(note).reduce((obj, _key) => {
      const key = _key === 'id' ? 'objectValueId' : _key;
      const data = note[_key];

      if (obj[key]) {
        obj[key].push(data);
      } else {
        obj[key] = [data];
      }
      return obj;
    }, notes);
    this.mixNotesData(reducedData, 'created');
    this.mixNotesData(reducedData, 'updated');
    return reducedData;
  };

  reduceTasks = (tasks, task, index) => {
    const reducedData = Object.keys(task).reduce((obj, _key) => {
      const key = _key === 'id' ? 'objectValueId' : _key;
      const data = task[_key];

      if (obj[key]) {
        obj[key].push(data);
      } else {
        obj[key] = [data];
      }
      return obj;
    }, tasks);
    this.mixNotesData(reducedData, 'created');
    this.mixNotesData(reducedData, 'updated');
    return reducedData;
  };

  getTableValues = (sections, sectionType) => {
    const customSection = sections.find(r => r.sectionId === sectionType);
    const { tableValues } = customSection || {};
    return tableValues;
  };

  loadData = ({
    data,
    documents = [],
    reports = {},
    notes = {},
    shares = [],
    tasks = [],
  }) => {
    const { dispatch } = this.props;
    const { sections: sectionList = [] } = data;
    const { sections: reportsSections = [] } = reports;
    const { sections: notesSections = [] } = notes;
    const { sections: tasksSections = [] } = tasks;
    const reportsValues = this.getTableValues(
      reportsSections,
      CASE_FILE_REPORTS_SECTION
    );
    const notesValues = this.getTableValues(
      notesSections,
      CASE_FILE_NOTES_SECTION
    );
    const tasksValues = this.getTableValues(
      tasksSections,
      CASE_FILE_TASK_LIST_SECTION
    );

    // convert sections data into a hash object
    const sectionsData = {
      ...getSectionsData(sectionList),
      reports: reportsValues,
      notes: notesValues,
      tasks: tasksValues,
    };

    const userDocumentsData = {
      documents: documents.reduce(this.reduceDocuments, {}),
    };

    this.setState({
      sectionsDefinition: sectionList,
      reportsSections,
      notesSections,
      tasksSections,
    });
    const { id, title } = this.state;
    dispatch(
      initialize(this.formName, {
        sectionsData,
        userDocumentsData,
        shares,
        id,
        title,
      })
    );
  };

  convertToFormValues = (content, sectionId) => {
    const { sectionsDefinition } = this.state;
    const create = sectionsDefinition.find(s => s.sectionId === sectionId);

    const formValues = Object.keys(content).reduce((arr, _key, index) => {
      let key;
      let contentValue;
      if (_key === 'type') {
        key = CASE_FILE_TYPE_ATTRIBUTE_NAME;
        contentValue = get(content, `${_key}.id`);
      } else if (_key === 'individual') {
        key = 'individual';
        contentValue = get(content, `${_key}.integrationId`);
      } else {
        key = _key;
        // data will be always into an input, it is ok to unescape it
        contentValue = content[key] ? htmlUnescape(content[key]) : '';
      }

      const attr = create.sectionAttributes.find(a => a.attributeName === key);
      if (attr) {
        arr.push({
          attributeId: attr.attributeId,
          valueId: index,
          value: contentValue,
          createdBy: 'Casper',
          lastModifiedBy: 'Casper',
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
      return arr;
    }, []);

    return {
      sections: sectionsDefinition.map(item => {
        if (item.sectionId === sectionId) {
          return { ...create, formValues };
        } else {
          return item;
        }
      }),
    };
  };

  setDoneLoading = () => {
    this.setState({ loading: false });
  };

  getConnectedForm = () => {
    if (!this.connectedForm) {
      this.connectedForm = connectForm(this.formName, this.onSubmit);
    }

    return this.connectedForm;
  };

  getTitle = sectionsData => get(sectionsData, FILE_TITLE_ATTR, TITLE_DEFAULT);

  showNotification = (type, message, description) =>
    notification[type]({
      message: <ContentEditable html={message} disabled={true} />,
      description: <ContentEditable html={description} disabled={true} />,
    });

  addTableValues = (content, sectionId, tableValues) => {
    const { sections = [] } = content;
    const newSections = sections.map(s =>
      s.sectionId === sectionId ? { ...s, tableValues } : s
    );

    return { ...content, sections: newSections };
  };

  setCasefileTitle = content => {
    const { casefileId, name, type } = content;
    const { name: subtitle } = type || {};
    this.setState({ title: casefileId || name, subtitle });
  };

  verifyShareAccess = _shareMode => {
    const { isOwner, id } = this.state;
    if (isOwner || isEmpty(id)) return;

    const { currentUser: { permissions } = {}, history } = this.props;
    const manageAll = hasPermissions(
      permissions,
      PERMISSIONS.manageAllCasefiles
    );
    let canAccess;
    let shareMode;

    if (manageAll) {
      canAccess = true;
      shareMode = SHARE_EDIT;
    } else {
      canAccess = hasPermissions(permissions, PERMISSIONS.accessCasefiles);
      shareMode = _shareMode;
    }

    if (canAccess) {
      switch (shareMode) {
        case SHARE_EDIT:
          this.setState({
            editable: true,
            sectionsDefinition: this.getSectionsDefinition(true),
          });
          break;
        case SHARE_VIEW:
          this.setState({
            editable: false,
            sectionsDefinition: this.getSectionsDefinition(false),
          });
          break;
        default:
          this.showNotification(NOTIFICATION_ERROR, 'Error', 'Access denied');
          history.push(getRoute('caseFileList'));
          break;
      }
    } else {
      this.showNotification(NOTIFICATION_ERROR, 'Error', 'Access denied');
      history.push(getRoute('caseFileList'));
    }
  };

  formatMultipleField = (data, attributeId) => {
    const index = data.sections[0].formValues.findIndex(
      formValue => formValue.attributeId === attributeId
    );
    if (index !== -1) {
      const values = data.sections[0].formValues[index];
      values.value = values.value.length === 0 ? [] : values.value.split(',');
    }
  };

  processResponse = action => response => {
    const { content = {}, success = false } = response;
    if (success) {
      const section = CASE_FILE_OVERVIEW_SECTION;
      const { currentUser } = this.props;
      const { id } = this.state;
      const { id: newId, name } = content;
      const {
        isOwner = false,
        documents = [],
        reports = [],
        notes = [],
        shares = [],
        tasks = [],
        ...fileContent
      } = content;
      // this is just to update the browser URL after creation
      if (action === ACTION_CREATE && isEmpty(id)) {
        const { history } = this.props;
        history.push(getRoute('openCaseFile', { id: newId }));
      }

      this.setState({
        isOwner,
        id: newId,
      });
      const currentShare = shares.find(
        s => currentUser.userIntegrationId === s.userIntegrationId
      );
      const shareMode = get(currentShare, 'mode');
      this.verifyShareAccess(shareMode);
      const type = get(fileContent, 'type');
      this.updateCasefileTypes(type);
      this.setCasefileTitle(fileContent);
      const data = this.convertToFormValues(fileContent, section);

      this.formatMultipleField(data, CASE_FILE_REASON_ATTRIBUTE_ID);
      this.formatMultipleField(data, CASE_FILE_OUTCOME_ATTRIBUTE_ID);

      const reportsTableValue = reports.reduce(this.reduceReports, {});
      const reportsData = this.addTableValues(
        mockedReports,
        CASE_FILE_REPORTS_SECTION,
        reportsTableValue
      );

      const notesTableValue = notes.reduce(this.reduceNotes, {});
      const notesData = this.addTableValues(
        this.notesDefinition,
        CASE_FILE_NOTES_SECTION,
        notesTableValue
      );

      const tasksTableValues = tasks.reduce(this.reduceTasks, {});
      const tasksData = this.addTableValues(
        this.tasksDefinition,
        CASE_FILE_TASK_LIST_SECTION,
        tasksTableValues
      );

      this.loadData({
        data,
        documents,
        reports: reportsData,
        notes: notesData,
        shares,
        tasks: tasksData,
      });

      if (action !== ACTION_FETCH) {
        this.showNotification(
          NOTIFICATION_SUCCESS,
          'Success',
          `File '${name}' ${action}d successfully`
        );
      }
    } else {
      this.processErrorResponse(action, response);
    }
  };

  processUpdateResponse = action => response => {
    const { content = {}, success = false } = response;

    if (success) {
      const { name, status } = unEscapeInput(content);

      this.setState({ status });
      this.showNotification(
        NOTIFICATION_SUCCESS,
        'Success',
        `File '${name}' ${action}d successfully`
      );
    } else {
      this.processErrorResponse(action, response);
    }
  };

  processErrorResponse = (action, response) => {
    // 404 could return an object like
    // {
    //   error: 'Not Found',
    //   message: 'No message available',
    //   path: '/api/v1/csf/casefile/f3d4cbbd-01c8-483c-8488-ff38c10b2385',
    //   status: 404,
    //   timestamp: '2022-03-30T23:33:52.476+00:00',
    // }
    // instead of the expected object
    const { errors, error, message } = response;
    const errorMessage = isEmpty(errors)
      ? error || message
      : errors.map(e => capitalize(e.message)).join(', ');
    this.showNotification(
      NOTIFICATION_ERROR,
      'Something went wrong',
      errorMessage
    );
    if (action === ACTION_FETCH) {
      const { history } = this.props;
      history.push(getRoute('caseFileList'));
    }
  };

  catchError = (error, action) => {
    if (error?.response?.body instanceof ReadableStream) {
      fetchReadableStream(error.response.body, this.processResponse(action));
    } else {
      this.showNotification(
        NOTIFICATION_ERROR,
        'Something went wrong',
        error?.message
      );
      if (action === ACTION_FETCH) {
        const { history } = this.props;
        history.push(getRoute('caseFileList'));
      }
    }
  };

  /**
   * checks if name attribute in data is empty (and trim it), updates
   * redux with trimmed value
   * @param {object} data : form data
   * @returns false if name is empty otherwise returns data
   */
  overviewNameisValid = data => {
    const { dispatch } = this.props;
    const name = (get(data, CASE_FILE_OVERVIEW_ATTRIBUTE_NAME) ?? '').trim();
    if (data[CASE_FILE_OVERVIEW_ATTRIBUTE_NAME] !== name) {
      data = { ...data, [CASE_FILE_OVERVIEW_ATTRIBUTE_NAME]: name };
      dispatch(
        change(
          this.formName,
          `sectionsData.${CASE_FILE_OVERVIEW_SECTION}.${CASE_FILE_OVERVIEW_ATTRIBUTE_NAME}`,
          name
        )
      );
    }
    return name === '' ? false : data;
  };

  handleUpdateCasefile = () => {
    const { id } = this.state;
    if (!isEmpty(id)) {
      const { profileForm } = this.props;

      // to get custom section values we should use getSectionsValues
      // however, we are just getting the values object to send data
      // to backend
      const sectionsData = this.overviewNameisValid(
        get(profileForm.values.sectionsData, CASE_FILE_OVERVIEW_SECTION, {})
      );

      if (sectionsData === false) {
        this.showNotification(
          NOTIFICATION_ERROR,
          `${CASE_FILE_OVERVIEW_ATTRIBUTE_TITLE} is required`,
          `${CASE_FILE_OVERVIEW_ATTRIBUTE_TITLE} cannot be empty or full of whitespaces`
        );
      } else {
        // send data to backend
        this.setState({ savingCasefile: true });
        caseFileEndpoints
          .updateCasefile(id, sectionsData)
          .then(response => this.processUpdateResponse(ACTION_UPDATE)(response))
          .catch(error => this.catchError(error, ACTION_UPDATE))
          .finally(() => this.setState({ savingCasefile: false }));
      }
    }
  };

  handleCreateCasefile = () => {
    const {
      profileForm,
      currentUser: {
        userIntegrationId,
        agency: { integrationId },
      },
    } = this.props;

    // to get custom section values we should use getSectionsValues
    // however, we are just getting the values object to send data
    // to backend
    const sectionsData = this.overviewNameisValid(
      get(profileForm.values.sectionsData, CASE_FILE_OVERVIEW_SECTION, {})
    );

    if (sectionsData === false) {
      this.showNotification(
        NOTIFICATION_ERROR,
        `${CASE_FILE_OVERVIEW_ATTRIBUTE_TITLE} is required`,
        `${CASE_FILE_OVERVIEW_ATTRIBUTE_TITLE} cannot be empty or full of whitespaces`
      );
    } else {
      // send data to backend
      this.setState({ savingCasefile: true });
      caseFileEndpoints
        .createCasefile(integrationId, userIntegrationId, sectionsData)
        .then(response => this.processResponse(ACTION_CREATE)(response))
        .catch(error => this.catchError(error, ACTION_CREATE))
        .finally(() => this.setState({ savingCasefile: false }));
    }
  };

  onSubmit = values => {
    const { id } = this.state;
    if (isEmpty(id)) {
      this.handleCreateCasefile();
    } else {
      this.handleUpdateCasefile();
    }
  };

  getSectionAttributes = (data, sectionId) => {
    const { sections = [] } = data;
    const section = sections.find(s => s.sectionId === sectionId);
    const { sectionAttributes = [] } = section;
    return sectionAttributes;
  };

  removeTableRow = (fieldList, indexToRemove, contextFunctions, formPath) => {
    const { removeSectionFieldValue = () => {} } = contextFunctions;
    fieldList.forEach(field => {
      removeSectionFieldValue(`${formPath}${field}`, indexToRemove);
    });
  };

  reduceTableValues = (
    reducer,
    acc = {},
    fieldList = CASE_FILE_REPORTS_TABLE_FIELDS
  ) =>
    fieldList.reduce(
      (fields, field) => ({
        ...fields,
        [field]: reducer(field),
      }),
      acc
    );

  applyDeleteReport = (index, props) => {
    const { prefix, sectionId } = props;
    const { reportsSections } = this.state;
    const [section, ...otherSections] = reportsSections;
    const { tableValues = {} } = section;
    const reducer = field => tableValues[field].filter((v, i) => i !== index);

    this.setState({
      reportsSections: [
        {
          ...section,
          tableValues: this.reduceTableValues(reducer),
        },
        ...otherSections,
      ],
    });

    this.removeTableRow(
      CASE_FILE_REPORTS_TABLE_FIELDS,
      index,
      props,
      `${prefix}.${sectionId}.`
    );
  };

  deleteReportAction = (record, index, name, cb, parentContext) => {
    confirm({
      title: 'Are you sure you want to remove the report from this case file?',
      content: `${record.reportNumber} ${record.reportType}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        caseFileEndpoints
          .deleteReport(record.key)
          .then(response => {
            const {
              success = false,
              errors: { message = '' },
            } = response;
            const { props } = parentContext;
            if (success) {
              this.applyDeleteReport(index, props);
            } else {
              this.catchError(message, ACTION_REMOVE);
            }
          })
          .catch(error => this.catchError(error, ACTION_REMOVE));
      },
    });
  };

  openReportLink = (event, index) => {
    const { reportsSections } = this.state;
    const tableValues = this.getTableValues(
      reportsSections,
      CASE_FILE_REPORTS_SECTION
    );
    const url = get(tableValues, `${REPORT_LOCATION}[${index}]`);

    if (url) {
      window.open(url, '_blank');
    }
  };

  addReportsToCasefile = selectedReportObject => {
    const { id } = this.state;
    const payloadReports = selectedReportObject.map(
      ({ reportId, reportType, reportNumber }) => ({
        reportId,
        reportType,
        reportNumber,
      })
    );
    return caseFileEndpoints.addReportToCasefile(id, payloadReports);
  };

  applyAddReport = (content, parentContext) => {
    const { reportsSections } = this.state;
    const [section, ...otherSections] = reportsSections;
    const { tableValues = {} } = section;
    const { objectValueId } = tableValues;
    const {
      addSectionFieldValue = () => {},
      prefix,
      sectionId,
    } = parentContext;

    const formPath = `${prefix}.${sectionId}.`;
    const newReportValues = content.reduce(this.reduceReports, {});
    this.removeTableRow(
      CASE_FILE_REPORTS_TABLE_FIELDS,
      (objectValueId && objectValueId.length) || 0,
      parentContext,
      formPath
    );

    CASE_FILE_REPORTS_TABLE_FIELDS.forEach(field => {
      newReportValues[field].forEach(reportValues => {
        addSectionFieldValue(`${formPath}[${field}]`, reportValues);
      });
    });
    this.showNotification(
      NOTIFICATION_SUCCESS,
      'Success',
      `Report number ${newReportValues.reportNumber.join(
        ','
      )} added successfully`
    );

    const reducer = field =>
      tableValues[field]
        ? [...tableValues[field], ...newReportValues[field]]
        : newReportValues[field];

    this.setState({
      reportsSections: [
        {
          ...section,
          tableValues: this.reduceTableValues(reducer),
        },
        ...otherSections,
      ],
    });
  };

  applyDeleteTask = (index, props) => {
    const { prefix, sectionId } = props;
    const { tasksSections } = this.state;
    const [section, ...otherSections] = tasksSections;
    const { tableValues = {} } = section;
    const reducer = field => tableValues[field].filter((v, i) => i !== index);

    this.setState({
      tasksSections: [
        {
          ...section,
          tableValues: this.reduceTableValues(
            reducer,
            {},
            CASE_FILE_TASK_LIST_FIELDS
          ),
        },
        ...otherSections,
      ],
    });

    this.removeTableRow(
      CASE_FILE_TASK_LIST_FIELDS,
      index,
      props,
      `${prefix}.${sectionId}.`
    );
  };

  applyDeleteNote = (index, props) => {
    const { prefix, sectionId } = props;
    const { notesSections } = this.state;
    const [section, ...otherSections] = notesSections;
    const { tableValues = {} } = section;
    const reducer = field => tableValues[field].filter((v, i) => i !== index);

    this.setState({
      notesSections: [
        {
          ...section,
          tableValues: this.reduceTableValues(
            reducer,
            {},
            CASE_FILE_NOTES_TABLE_FIELDS
          ),
        },
        ...otherSections,
      ],
    });

    this.removeTableRow(
      CASE_FILE_NOTES_TABLE_FIELDS,
      index,
      props,
      `${prefix}.${sectionId}.`
    );
  };

  applyNoteData = (content, index, parentContext) => {
    const { notesSections } = this.state;
    const [section, ...otherSections] = notesSections;
    const { tableValues = {} } = section;
    const { updateSectionField = () => {}, prefix, sectionId } = parentContext;
    const formPath = `${prefix}.${sectionId}.`;
    const newNotesValues = [content].reduce(this.reduceNotes, {});

    CASE_FILE_NOTES_TABLE_FIELDS.forEach(field => {
      newNotesValues[field].forEach(reportValues => {
        updateSectionField(`${formPath}[${field}][${index}]`, reportValues);
      });
    });

    const reducer = field =>
      tableValues[field]
        ? [...tableValues[field], ...newNotesValues[field]]
        : newNotesValues[field];

    this.setState({
      notesSections: [
        {
          ...section,
          tableValues: this.reduceTableValues(
            reducer,
            {},
            CASE_FILE_NOTES_TABLE_FIELDS
          ),
        },
        ...otherSections,
      ],
    });
  };

  onModalAddTaskOkCallback = (e, payload, parentContext) => {
    payload.assigneeFullName = payload.assignee || '';
    const { isNew, rowIndex, methodType } = payload;
    const isUpdate = !isNew && rowIndex > -1;
    const { tasksSections } = this.state;
    const [section, ...otherSections] = tasksSections;
    const { tableValues = {} } = section;
    const { updateSectionField = () => {}, prefix, sectionId } = parentContext;
    const formPath = `${prefix}.${sectionId}.`;
    const newTasksValues = [payload].reduce(this.reduceTasks, {});
    const index = isUpdate
      ? rowIndex
      : Object.keys(tableValues).length === 0
      ? 0
      : tableValues.description.length;

    if (methodType === 'remove') {
      this.applyDeleteTask(rowIndex, parentContext);
    } else {
      const existingFields = CASE_FILE_TASK_LIST_FIELDS.filter(field =>
        Object.keys(newTasksValues).includes(field)
      );

      existingFields.forEach(field => {
        newTasksValues[field].forEach(reportValues => {
          updateSectionField(`${formPath}[${field}][${index}]`, reportValues);
        });
      });

      const reducer = field =>
        tableValues[field]
          ? [...tableValues[field], ...newTasksValues[field]]
          : newTasksValues[field];

      this.setState({
        tasksSections: [
          {
            ...section,
            tableValues: this.reduceTableValues(reducer, {}, existingFields),
          },
          ...otherSections,
        ],
      });

      this.showNotification(
        NOTIFICATION_SUCCESS,
        'Success',
        isUpdate ? 'Task updated successfully' : 'New Task added successfully'
      );
      this.getCasefileData(true);
    }
  };

  buildAddTaskModal = (attributes, modalTitle) => {
    const { id, shares, isCasefileClosed } = this.state;
    const {
      profileForm,
      currentUser: { userIntegrationId },
    } = this.props;
    return function(
      readOnly,
      showModal,
      sectionId,
      rowIndex,
      handleModalCancel,
      handleModalOk,
      isNew,
      prefix
    ) {
      return (
        <AddTaskModal
          title={'Task Details'}
          attributes={attributes}
          profileForm={profileForm}
          readOnly={readOnly}
          visible={showModal}
          sectionId={sectionId}
          rowIndex={rowIndex}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          userId={userIntegrationId}
          isNew={isNew}
          casefileId={id}
          prefix={prefix}
          shares={shares}
          isCasefileClosed={isCasefileClosed}
        />
      );
    };
  };

  buildAddNoteModal = (attributes, modalTitle) => {
    const { id } = this.state;
    const {
      profileForm,
      currentUser: { userIntegrationId },
    } = this.props;

    return function(
      readOnly,
      showModal,
      sectionId,
      rowIndex,
      handleModalCancel,
      handleModalOk,
      isNew,
      prefix
    ) {
      return (
        <AddNoteModal
          title={modalTitle}
          attributes={attributes}
          profileForm={profileForm}
          readOnly={readOnly}
          visible={showModal}
          sectionId={sectionId}
          rowIndex={rowIndex}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          userId={userIntegrationId}
          isNew={isNew}
          casefileId={id}
          prefix={prefix}
        />
      );
    };
  };

  onModalOkCallback = (eNode, selectedReportObjects, parentContext) => {
    this.addReportsToCasefile(selectedReportObjects)
      .then(response => {
        const {
          content = {},
          success = false,
          errors: { message = '' },
        } = response;

        if (success) {
          this.applyAddReport(content, parentContext);
        } else {
          this.catchError(message, ACTION_ADD);
        }
      })
      .catch(error => this.catchError(error, ACTION_ADD));
  };

  buildAddReportModal = (attributes, modalTitle) => {
    const { id } = this.state;

    return function(
      readOnly,
      showModal,
      sectionId,
      rowIndex,
      handleModalCancel,
      handleModalOk,
      isNew
    ) {
      return (
        <AddReportModal
          title={modalTitle}
          attributes={attributes}
          // profileForm={profileForm}
          readOnly={readOnly}
          visible={showModal}
          sectionId={sectionId}
          rowIndex={rowIndex}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          // userId={userId}
          // tenantId={tenantId}
          // sectionsDefinition={sectionsDefinition}
          isNew={isNew}
          casefileId={id}
        />
      );
    };
  };

  processNoteResponse = (rowIndex, parentContext) => response => {
    const {
      content = {},
      success = false,
      errors: { message = '' },
    } = response;

    if (success) {
      this.applyNoteData(content, rowIndex, parentContext);
    } else {
      this.catchError(message, ACTION_ADD);
    }
  };

  processNoteRemoval = ({
    rowId,
    details,
    methodType,
    rowIndex,
    parentContext,
  }) => {
    caseFileEndpoints
      .removeNoteToCasefile(rowId)
      .then(response => {
        const {
          success = false,
          errors: { message = '' },
        } = response;
        this.showNotification(
          NOTIFICATION_SUCCESS,
          'Success',
          `Note '${details}' ${methodType}d successfully`
        );
        if (success) {
          this.applyDeleteNote(rowIndex, parentContext);
        } else {
          this.catchError(message, ACTION_REMOVE);
        }
      })
      .catch(error => this.catchError(error, ACTION_REMOVE));
  };

  onModalNoteOkCallback = (e, payload, parentContext) => {
    const { id } = this.state;
    const { details, isNew, rowIndex, rowId, methodType } = payload;
    const canAdd = isNew && methodType === 'add';
    const canUpdate = !isNew && methodType === 'add';
    if (methodType === 'remove') {
      this.processNoteRemoval({
        rowId,
        details,
        methodType,
        rowIndex,
        parentContext,
      });
    }
    if (canAdd) {
      caseFileEndpoints
        .addNoteToCasefile(id, { details })
        .then(this.processNoteResponse(rowIndex, parentContext))
        .catch(error => this.catchError(error, ACTION_ADD));
    }
    if (canUpdate) {
      caseFileEndpoints
        .updateNoteToCasefile(rowId, { details })
        .then(this.processNoteResponse(rowIndex, parentContext))
        .catch(error => this.catchError(error, ACTION_ADD));
    }
  };

  checkIfItsOwnerOrAdmin = () => {
    const { currentUser: { permissions } = {} } = this.props;
    const { isOwner } = this.state;
    return (
      isOwner || hasPermissions(permissions, PERMISSIONS.manageAllCasefiles)
    );
  };

  checkIfCasefileIsClosed = statuses => {
    const { status } = this.state;
    const isClosed = statuses.findIndex(
      s => s.id === status && s.type === CLOSED_STATUS
    );
    if (isClosed > 0) {
      return true;
    }
    return false;
  };

  renderOverview = () => {
    const { profileForm, timezone } = this.props;
    const {
      sectionsDefinition,
      id,
      editable,
      loading,
      savingCasefile,
    } = this.state;

    return (
      <Fragment>
        <UserProfileSections
          profileForm={profileForm}
          timezone={timezone}
          sectionsDefinition={sectionsDefinition}
          isSectionReadOnly={false}
          parentContent={this}
          loading={loading}
        />
        <Row>
          <Col span={2} push={22}>
            <Button
              type="primary"
              onClick={this.onSubmit}
              disabled={!editable || loading || savingCasefile}
            >
              {isEmpty(id) ? 'Save' : 'Update'}
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  renderSections = () => {
    const { profileForm, timezone } = this.props;
    const {
      reportsSections,
      notesSections,
      id,
      editable,
      tasksSections,
      isTaskListAvailable,
      isTaskListLoading,
      isPredefinedTaskLoading,
      isCasefileClosed,
    } = this.state;
    const docAttributes = this.getSectionAttributes(
      mockedDocument,
      CASE_FILE_DOCUMENTS_SECTION
    );
    const disableFeatures = !editable || isCasefileClosed;
    return (
      <Fragment>
        {isTaskListAvailable && (
          <UserProfileSections
            profileForm={profileForm}
            timezone={timezone}
            sectionsDefinition={tasksSections}
            isSectionReadOnly={disableFeatures}
            parentContent={this}
            showIconAction={false}
            hasAction={true}
            actionLabel="Details"
            addButtonTitle="Add Task"
            modalFor="sections"
            buildModal={this.buildAddTaskModal}
            onModalOkCallback={this.onModalAddTaskOkCallback}
            loading={isTaskListLoading}
            isPredefinedTaskLoading={isPredefinedTaskLoading}
            showSecondaryButton={this.checkIfItsOwnerOrAdmin()}
            secondaryButton={
              <AddPredefinedTaskButton
                caseFileId={id}
                loadingTask={isTaskListLoading}
                hasEditPermission={disableFeatures}
                reloadTaskTable={() => this.fetchData(id, true)}
                setOutterPredefinedTaskLoadingFlag={state =>
                  this.setState({ isPredefinedTaskLoading: state })
                }
              />
            }
          />
        )}
        <UserProfileSections
          profileForm={profileForm}
          timezone={timezone}
          sectionsDefinition={reportsSections}
          isSectionReadOnly={disableFeatures}
          parentContent={this}
          onAction={this.deleteReportAction}
          linkAction={this.openReportLink}
          showIconAction={false}
          hasAction={true}
          disableAction={disableFeatures}
          actionLabel="Remove"
          addButtonTitle="Add Report"
          buildModal={this.buildAddReportModal}
          onModalOkCallback={this.onModalOkCallback}
        />
        <CaseFileDocument
          sectionAttributes={docAttributes}
          profileForm={profileForm}
          timezone={timezone}
          isSectionReadOnly={disableFeatures}
          parentContent={this}
          uploadURL={caseFileEndpoints.getDocumentUploadURL(id)}
          casefileId={id}
        />
        <UserProfileSections
          profileForm={profileForm}
          timezone={timezone}
          sectionsDefinition={notesSections}
          isSectionReadOnly={disableFeatures}
          parentContent={this}
          showIconAction={false}
          hasAction={true}
          actionLabel="Detail"
          addButtonTitle="Add note"
          modalFor="sections"
          buildModal={this.buildAddNoteModal}
          onModalOkCallback={this.onModalNoteOkCallback}
        />
      </Fragment>
    );
  };

  get formActions() {
    const { isCasefileClosed, isOwner } = this.state;
    const { currentUser: { permissions } = {} } = this.props;
    const actions = [];
    const isSharingEnabled = getIsSharingEnabled({
      ...this.state,
      permissions,
    });
    const isDeleteEnabled = getIsDeleteEnabled({ ...this.state, permissions });

    if (isSharingEnabled) {
      actions.push(
        <ShareButton isCasefileClosed={{ closed: isCasefileClosed, isOwner }} />
      );
    }
    if (isDeleteEnabled) {
      actions.push(
        <DeleteButton
          isCasefileClosed={{ closed: isCasefileClosed, isOwner }}
        />
      );
    }
    return actions;
  }

  render() {
    const {
      title,
      subtitle,
      id,
      loading = false,
      isPredefinedTaskLoading = false,
      statuses,
    } = this.state;
    const { profileForm } = this.props;
    const overviewSectionData = profileForm?.values?.sectionsData?.overview;
    const CaseFileForm = this.getConnectedForm();
    const headerTitle = subtitle ? (
      <div>
        <span className="title">{title}</span>
        <p className="subtitle">{subtitle}</p>
      </div>
    ) : (
      title
    );

    return (
      <Fragment>
        <PageHeader
          title={headerTitle}
          history={history}
          goBackTo={getRoute('caseFileList')}
          actions={this.formActions}
          isLoading={loading || isPredefinedTaskLoading}
        />
        <AlertOverdueBanner data={overviewSectionData} statuses={statuses} />
        <CaseFileForm
          render={({ handleSubmit }) => (
            <form
              className="ant-form ant-form-horizontal"
              onSubmit={handleSubmit(this.onSubmit)}
            >
              {this.renderOverview()}
              {!loading && !isEmpty(id) && this.renderSections()}
            </form>
          )}
        />
      </Fragment>
    );
  }
}

const mapState = (state, props) => {
  return {
    currentUser: getCurrentUser(state),
    profileForm: getFormValues(state, FORM_NAME),
    timezone: getAgencyTZ(state),
  };
};

export default withoutClutter(DashboardPage(mapState)(withRouter(CaseFile)));
