import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { get } from 'lodash';
import { Button, Icon, Row, Col, Upload, notification } from 'antd';

import { getAgencyTZ } from '../../../selectors/session';

import parseDate, { BENCHMARK_DATE_FORMAT } from 'APP_ROOT/utils/parse-date';
import storage from 'APP_ROOT/utils/storage';

import sectionModalCancel from 'APP_ROOT/containers/administrator/agency-users/UserProfile/sectionModalCancel';
import FILE_STATUSES from 'APP_ROOT/containers/administrator/agency-users/UserProfile/constants/file-statuses.constants';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';

import StyledModal from './CaseFileDocumentModal.style';

import FileSizeLimit from '../../../components/form-viewer/forms/utils/fileSizeLimit';
import AttachmentsWarning from '../../../components/common/attachments-warning';

const [DONE, ERROR] = FILE_STATUSES;

const PREFIX = 'userDocumentsData';
const ATTRIBUTE_ID = 'id';
const uploadErrorMessage = 'Something went wrong uploading the file.';
const NOTIFICATION_SUCCESS = 'success';
const NOTIFICATION_ERROR = 'error';

class CaseFileDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editForm: null,
      isNew: false,
      currentRecords: [],
      uploadInputKey: 'uploadInput',
      hasUploadedFile: false,
      fileList: [],
    };
  }

  onBeforeUpload = file => {};

  uploadOverride = async antdUploadElementData => {
    const { file, onError, onProgress, onSuccess } = antdUploadElementData;
    const { profileForm: { initial: { id } = {} } = {} } = this.props;
    const {
      content: { url, name: documentName, id: documentId },
    } = await caseFileEndpoints.addDocumentCaseFile(
      id,
      file.name,
      file.type,
      file.size
    );
    onProgress({ percent: 0 });
    const xhr = new XMLHttpRequest();
    const response = await new Promise(resolve => {
      xhr.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      });
      xhr.addEventListener('loadend', () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
    if (response) {
      // confirm upload has finished.
      await caseFileEndpoints.completeUpload(documentId);
      onSuccess({
        success: true,
        content: { id: documentId, name: documentName },
      });
    } else {
      onError(new Error('unable to get a response'));
    }
  };

  componentDidUpdate(prevProps) {
    if (!this.props.visible && this.props.visible !== prevProps.visible) {
      this.setState({ hasUploadedFile: false }, this.resetUploadInput);
    }
  }

  resetUploadInput = () => {
    const newKey = `uploadInput-${Math.random() * 10}`;
    this.setState({ uploadInputKey: newKey });
  };

  getURL = () => {
    const { uploadURL } = this.props;
    return uploadURL;
  };

  showNotification = (type, message, description) =>
    notification[type]({ message, description });

  onChange = ({ file = {} }) => {
    const { status, response = {} } = file;
    const { rowIndex, updateSectionField, timezone, casefileId } = this.props;

    switch (status) {
      case DONE:
        const { success, content } = response;
        if (success) {
          this.setState({ hasUploadedFile: true, fileList: [file] });
          const locationUrl = caseFileEndpoints.getDocumentDownloadParams(
            casefileId,
            content.id
          );

          updateSectionField(
            `userDocumentsData.documents.locationUrl[${rowIndex}]`,
            locationUrl
          );

          updateSectionField(
            `userDocumentsData.documents.fileName[${rowIndex}]`,
            content.name
          );

          updateSectionField(
            `userDocumentsData.documents.createdAt[${rowIndex}]`,
            parseDate(new Date(), timezone, BENCHMARK_DATE_FORMAT)
          );
          this.onModalOk();
          this.showNotification(
            NOTIFICATION_SUCCESS,
            'Success',
            `File '${content.name}' uploaded successfully`
          );
        } else {
          const { errors } = response;
          const message = errors.join(', ') || uploadErrorMessage;
          this.showNotification(NOTIFICATION_ERROR, 'Error', message);
        }

        break;
      case ERROR:
        notification.error({
          message: 'Upload error',
          description: uploadErrorMessage,
        });

        break;
      default:
        break;
    }
  };

  onRemoveFileOnStorage = () => {
    //No action
  };

  saveUpload = () => {
    //No action
  };

  getRowIndex = key => {
    const { profileForm, sectionId } = this.props;
    const keys = get(
      profileForm,
      `values.${PREFIX}.${sectionId}.${ATTRIBUTE_ID}`,
      []
    );
    return keys.findIndex(k => k === key);
  };

  onCancelEdit = () => {
    const { editForm } = this.state;
    const props = {
      updateSectionField: this.props.updateSectionField,
      removeSectionFieldValue: this.props.removeSectionFieldValue,
      attributes: this.props.sectionAttributes,
      profileForm: this.props.profileForm,
      prefix: PREFIX,
      sectionId: this.props.sectionId,
      attributeId: ATTRIBUTE_ID,
    };

    sectionModalCancel(editForm.isNew, editForm.record, props);
    this.setState({ editForm: null });
  };

  onOkEdit = () => {
    this.setState({ editForm: null });
  };

  onModalOk = () => {
    const { onOk, isNew } = this.props;
    const { hasUploadedFile } = this.state;

    if (isNew) {
      if (hasUploadedFile) {
        this.setState({ editForm: null, isNew: false });
        onOk && onOk();
      }
    } else {
      this.setState({ editForm: null, isNew: false });
      onOk && onOk();
    }
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    this.setState({ editForm: null, isNew: false });
    onCancel && onCancel();
  };

  // getExtraData = file => {
  //   const { profileForm, rowIndex } = this.props;
  //   // cannot use sectionId because of some userProfile code
  //   // it is pointing to row.key, instead I need to use
  //   // 'documents'
  //   const description = get(
  //     profileForm,
  //     `values.${PREFIX}.documents.description[${rowIndex}]`,
  //     ''
  //   );
  //   return { description };
  // };

  getModalFooterButtons = readOnly =>
    readOnly
      ? [
          <Button key="Done" type="primary" onClick={this.onModalOk}>
            Done
          </Button>,
        ]
      : [
          <Button key="cancel" onClick={this.onModalCancel}>
            Cancel
          </Button>,
        ];

  getModalFooterMessages = () => (
    <Fragment>
      <Row type="flex" justify="center">
        <Col>
          <FileSizeLimit />
        </Col>
      </Row>
      <Row className="row-important">
        <Col>
          <AttachmentsWarning />
        </Col>
      </Row>
    </Fragment>
  );

  render = () => {
    const { title, visible, readOnly, isNew } = this.props;

    return (
      <StyledModal
        title={title}
        width={1000}
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={this.getModalFooterButtons(readOnly)}
      >
        {isNew && (
          <Row type="flex" justify="center" className="row-upload">
            <Col>
              <Upload
                showUploadList={true}
                beforeUpload={this.onBeforeUpload}
                name="document"
                headers={{
                  Authorization: `bearer ${storage.get('token')}`,
                }}
                action={this.getURL()}
                onChange={this.onChange}
                onRemove={this.onRemoveFileOnStorage}
                customRequest={this.uploadOverride}
                ref={this.saveUpload}
                multiple={false}
                key={this.state.uploadInputKey}
                disabled={readOnly}
              >
                <Button>
                  Select File <Icon type="upload" />
                </Button>
              </Upload>
            </Col>
          </Row>
        )}
        {/* <Row style={{ paddingBottom: '22px', paddingTop: '22px' }}>
          <Col xs={24}>
            <SectionAttribute
              key="key-user-document"
              name={`userDocumentsData.documents.description[${rowIndex}]`}
              title="File Description"
              type={ATTRIBUTE_TYPE_TEXTAREA}
              validValues={null}
              unique={true}
              profileForm={profileForm}
              isArray={false}
              readOnly={readOnly}
              data-test="user-document"
            />
          </Col>
        </Row> */}
        {!readOnly && this.getModalFooterMessages()}
      </StyledModal>
    );
  };
}

const mapState = state => {
  const currentUserFullName = get(state, 'session.currentUser.fullName', '');
  return { currentUserFullName, timezone: getAgencyTZ(state) };
};

export default connect(mapState)(CaseFileDocumentModal);
