import React, { useState } from 'react';
import { Button, notification } from 'antd';
import ContentEditable from 'react-contenteditable';

import caseFileEndpoints from '../../../../api/caseFileEndpoints/caseFileEndpoints';

const AddPredefinedTaskButton = ({
  caseFileId,
  loadingTask,
  hasEditPermission,
  reloadTaskTable = () => {},
  setOutterPredefinedTaskLoadingFlag = () => {},
}) => {
  const [loadingPredefinedTasks, setLoadingPredefinedTasks] = useState(false);

  const showNotification = (type, message, description) =>
    notification[type]({
      message: <ContentEditable html={message} disabled={true} />,
      description: <ContentEditable html={description} disabled={true} />,
    });

  const proccesDefinedTask = async (response, casefileId) => {
    const { content } = response;

    try {
      for (let i = 0; i < content.length; i++) {
        const index = content.length - (i + 1);
        await caseFileEndpoints.addTaskToCasefile(casefileId, {
          description: content[index].description,
        });
      }

      reloadTaskTable();
    } catch (error) {
      showNotification(
        'error',
        'Could not create predefined tasks',
        error?.message
      );
    } finally {
      setOutterPredefinedTaskLoadingFlag(false);
      setLoadingPredefinedTasks(false);
    }
  };

  const handleClickButton = caseFileId => {
    setOutterPredefinedTaskLoadingFlag(true);
    setLoadingPredefinedTasks(true);

    caseFileEndpoints
      .getPredefinedTasks(caseFileId)
      .then(response => proccesDefinedTask(response, caseFileId))
      .catch(error => {
        showNotification(
          'error',
          'Could not retrieve predefined tasks',
          error?.message
        );
        setOutterPredefinedTaskLoadingFlag(false);
        setLoadingPredefinedTasks(false);
      });
  };

  return (
    <Button
      type="primary"
      onClick={e => {
        handleClickButton(caseFileId);
        e.stopPropagation();
      }}
      loading={loadingPredefinedTasks}
      disabled={loadingPredefinedTasks || loadingTask || hasEditPermission}
      style={{ marginRight: '16px' }}
    >
      Add Default Tasks
    </Button>
  );
};

export default AddPredefinedTaskButton;
