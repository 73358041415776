import React from 'react';
import { Icon, Button } from 'antd';

import ModalTitle from 'APP_ROOT/components/common/modal/title';
import ModalBody from 'APP_ROOT/components/common/modal/body';

export default {
  title: (
    <ModalTitle warning>
      <Icon type="exclamation-circle" />
      <span> Do you want to delete this report? </span>
    </ModalTitle>
  ),
  text: (
    <ModalBody>
      <p>
        You are about to mark this report as deleted and remove it from all
        lists and analytics. Are you sure you want to do this?
      </p>
    </ModalBody>
  ),
  /**
   * @param {function} onCancelButtonClick
   * @param {function} onConfirmButtonClick
   * @return {function[]}
   */
  buildFooter: (onCancelButtonClick, onConfirmButtonClick) => [
    <Button key="delete-report-button-cancel" onClick={onCancelButtonClick}>
      Cancel
    </Button>,
    <Button
      key="delete-report-button-accept"
      type="primary"
      onClick={onConfirmButtonClick}
    >
      Delete
    </Button>,
  ],
};
