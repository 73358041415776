import React, { useState } from 'react';
import { message } from 'antd';

import {
  userEquipmentConfigs,
  prepareEquipmentColumns,
} from './UserEquipmentConfigs';
import UserEquipmentModal from './UserEquipmentModal';
import useEquipmentRowsData from './useEquipmentRowsData';
import useSortData from './useSortData';
import EquipmentPanel from './EquipmentPanel';
const UserEquipmentSection = ({ tenantId, userId, dispatch, session }) => {
  const allowed = userEquipmentConfigs(session);
  if (!allowed) return null;

  const [isActive, setIsActive] = useState(false);
  const pageSize = 10;
  const { sort, currentPage, fetchDataCallback } = useSortData();

  const {
    loading,
    equipmentRows,
    totalElements,
    fetchData,
  } = useEquipmentRowsData({
    tenantId,
    userId,
    dispatch,
    isActive,
    currentPage,
    pageSize,
    sort,
  });

  // Modal states
  const [equipment, setEquipment] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const openEquipment = (text, record) => {
    setModalVisible(true);
    setEquipment(record.item);
  };

  const newEquipment = event => {
    event.stopPropagation();
    setModalVisible(true);
    setEquipment({});
  };

  const configs = prepareEquipmentColumns(allowed, openEquipment);

  const onCancel = () => {
    setModalVisible(false);
    setEquipment({});
  };

  const onActive = bool => {
    setIsActive(bool);
  };

  function showResult(error, data, label) {
    setModalVisible(false);
    if (error || !data) {
      message.error(label.error);
      console.error(error);
      return;
    }
    message.success(label.success);
    if (isActive) {
      fetchData();
    }
  }

  const onDelete = (label, error, data) => {
    showResult(error, data, label);
  };

  const onSave = (label, error, data) => {
    showResult(error, data, label);
  };

  return (
    <>
      <EquipmentPanel
        configs={configs}
        equipmentRows={equipmentRows}
        fetchDataCallback={fetchDataCallback}
        pageSize={pageSize}
        loading={loading}
        totalElements={totalElements}
        newEquipment={newEquipment}
        onActive={onActive}
      />
      <UserEquipmentModal
        tenantId={tenantId}
        userId={userId}
        configs={configs}
        visible={modalVisible}
        onCancel={onCancel}
        onSave={onSave}
        onDelete={onDelete}
        dispatch={dispatch}
        equipmentRecord={equipment}
        isEditable={allowed && allowed.isEditable === true}
      />
    </>
  );
};

export default UserEquipmentSection;
