import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { omit } from 'lodash';
import { Button } from 'antd';

import { mapProperties } from '../../../utils/form';
import SectionWrapperDiv from '../styled/section-wrapper';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

import { getContributeToReportVisibilityLevels } from './utils/contribute-to-report-object-types';

class SectionWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  render() {
    const props = this.props;

    const {
      form = {},
      isFirst = false,
      isLast = false,
      options = {},
      parentIndex = 0,
      parentKey = '',
      properties = [],
      ...rest
    } = props;
    const total = properties.length;
    const { className = '' } = options;
    const classes = classNames(className, {
      'bdm-section-wrapper-first': isFirst,
      'bdm-section-wrapper-last': isLast,
      'bdm-section-wrapper-not-first': !isFirst,
      'bdm-section-wrapper-not-last': !isLast,
      'bdm-edit-mode': true,
    });

    const { selectedForm } = this.props;
    // view hierarchy
    const {
      isContributorAssign,
      contributorAssignmentCanEdit,
      contributorAssignmentCanView,
      contributorAssignmentHideSection,
      contributorAssignmentSource,
      showCompleteIncompleteButton,
      contributorAssignmentIsComplete,
      contributorAssignmentIsAssigned,
    } = getContributeToReportVisibilityLevels(this.props);

    return (
      <SectionWrapperDiv
        className={classes}
        style={
          contributorAssignmentHideSection ? { display: 'none' } : undefined
        }
      >
        {properties.map(
          mapProperties({
            ...omit(rest, ['conditions', 'options']),
            form,
            parentKey,
            parentIndex,
            total,
            title: null,
            isContributeReport: selectedForm.isContributeReport,
            isContributorAssign,
            contributorAssignmentCanEdit,
            contributorAssignmentCanView,
            contributorAssignmentSource,
            contributorAssignmentIsComplete,
            contributorAssignmentIsAssigned,
          })
        )}
        {showCompleteIncompleteButton && (
          <Button
            type="primary"
            onClick={event => {
              event.target.parentElement
                .querySelector('.complete-check')
                .click();
            }}
          >
            {contributorAssignmentIsComplete
              ? 'Mark incomplete'
              : 'Mark complete'}
          </Button>
        )}
      </SectionWrapperDiv>
    );
  }
}

const mapState = state => {
  const {
    form: {
      selectedForm,
      selectedForm: { data: { __assignedSections = [] } = {} } = {},
    } = {},
  } = state;
  return {
    __assignedSections,
    selectedForm,
  };
};

export default connect(mapState, null)(SectionWrapper);
