import validator from 'async-validator';
import { isPlainObject, keys, get, set, isEmpty, isArray } from 'lodash';
import isUUID from 'is-uuid';

import { dateValidator } from './form';
import validateConditions from './validateConditions';
import validateBackendConditions from './validateBackendConditions';

import compliesToRequirements from './complies-to-requirements';

import getEnumOptions from './getEnumOptions';

import {
  symlinkFromPropertyName,
  symlinkToPropertyName,
  symlinkIndexPropertyName,
  symlinksPropName,
  getSymlinks,
} from 'APP_ROOT/utils/create-symlink';

const toAsyncValidatorRules = (enums, types, timezone) => (
  fieldRules,
  fieldName
) => {
  const stringTransform = rule =>
    rule.required ? Object.assign({}, { whitespace: true }, rule) : rule;

  const enumTransform = rule => {
    const rest = Object.assign({}, rule);
    delete rest.list;
    delete rest.enumRef;
    delete rest.parentEnum;
    if (rule.list && rule.list.length > 0) return rule;
    const enumRef = rule.enumRef;
    const parentEnum = rule.parentEnum;
    const list = getEnumOptions(null, [], {
      enumRef: enumRef ? enumRef : fieldName,
      parentEnum,
      mapAs: getEnumOptions.mapAsPlain,
      dataEnums: enums,
      combine: true,
    });
    return Object.assign({}, { enum: list }, { type: rule.type, ...rest });
  };
  const multiTransform = rule => {
    const rest = Object.assign({}, rule);
    delete rest.list;
    delete rest.enumRef;
    if (rule.list && rule.list.length > 0) return rule;
    const enumRef = rule.enumRef;
    const list = enumRef ? enums[enumRef] : enums[fieldName];
    return Object.assign({ ...rest }, { enum: list }, { type: 'array' });
  };
  const arrayTransform = rule => {
    const { type, item, ...rest } = rule;
    const transformedItem = toAsyncValidatorRules(enums, types, timezone)(item);
    const values = Array.isArray(transformedItem)
      ? transformedItem[0]
      : transformedItem;
    return Object.assign({}, { type, defaultField: values, ...rest });
  };
  const objectTransform = rule => {
    const { type, fields, ...rest } = rule;
    const transformedFields = Object.keys(fields).reduce((acc, fName) => {
      const transformedField = toAsyncValidatorRules(
        enums,
        types,
        timezone
      )(fields[fName], fName);
      return Object.assign({ [fName]: transformedField }, acc);
    }, {});
    return Object.assign({}, { type, fields: transformedFields, ...rest });
  };
  const dateTransform = rule => ({
    ...rule,
    validator: dateValidator(timezone),
  });
  const numberTransform = rule => {
    const transform = value => {
      try {
        return value && JSON.parse(value);
      } catch (e) {
        return value;
      }
    };
    return { ...rule, transform };
  };

  const transformations = {
    string: stringTransform,
    multi: multiTransform,
    enum: enumTransform,
    array: arrayTransform,
    object: objectTransform,
    number: numberTransform,
    integer: numberTransform,
    date: dateTransform,
  };

  if (types) {
    Object.keys(types).forEach(type => {
      const typeRules = types[type];
      transformations[type] = rule => {
        const transformedTypeRules = toAsyncValidatorRules(
          enums,
          types,
          timezone
        )(typeRules);
        return Object.assign({}, rule, transformedTypeRules);
      };
    });
  }

  let transformedRules;
  const transformFieldRule = rule => {
    const transform = transformations[rule.type];
    return transform ? transform(rule) : rule;
  };
  if (Array.isArray(fieldRules)) {
    // Array rules have precedence
    const arrayRule = fieldRules.find(fr => fr.type === 'array');
    if (arrayRule) {
      const transform = transformations[arrayRule.type];
      transformedRules = transform ? transform(arrayRule) : arrayRule;
    } else {
      transformedRules = fieldRules.map(transformFieldRule);
    }
  } else {
    transformedRules = transformFieldRule(fieldRules);
  }
  return transformedRules;
};

export const transformValidations = async (validations, enums, timezone) => {
  const formRules = validations.rules;
  const customTypes = validations.types;
  const transformedRules = Object.keys(formRules).reduce((acc, fieldName) => {
    const fieldRules = formRules[fieldName];
    const transformed = toAsyncValidatorRules(
      enums,
      customTypes,
      timezone
    )(fieldRules, fieldName);
    return Object.assign({ [fieldName]: transformed }, acc);
  }, {});

  return Object.assign({
    type: 'object',
    fields: transformedRules,
  });
};

/**
 * getTabFieldsError
 * Process current tab during submit and every tab during form navigation
 * Evaluates current tab data usign rulels, conditions
 *
 * @param {*} props
 */
export const getTabFieldsError = props => async current => {
  const {
    selectedForm: { tab = 0, data = {}, formFields },
  } = props;

  const tabIndex = current !== null ? current : tab;
  const tabFields = formFields.fields[tabIndex];

  const tabFieldsWithData = {
    ...keys(tabFields).reduce((result, field) => {
      result[field] = data[field];
      // if array it is probably a repeater
      if (isArray(result[field])) {
        // just use first entry since all of them have the same values
        const [item = {}] = result[field];
        const symlinks = getSymlinks(item);

        // check for nested repeaters
        symlinks.forEach(path => (result[path] = data[path]));
      }
      return result;
    }, {}),
    isReviewer: props.isReviewer || false,
  };

  const isRepeater = _obj =>
    Array.isArray(_obj) && _obj.length > 0 && isPlainObject(_obj[0]);

  const mapDataWithRules = (obj, parentKey, repeaterItem, repeaterIndex) => {
    if (isRepeater(obj)) {
      const type = 'array';
      const mappedItems = obj.map((item, index) =>
        mapDataWithRules(
          item,
          parentKey ? `${parentKey}.${0}` : `${0}`,
          item,
          index
        )
      );
      const perItemRules = mappedItems.reduce(
        (acc, item, index) => (item ? { ...acc, [index]: item } : acc),
        {}
      );
      return Object.keys(perItemRules).length
        ? { type, fields: perItemRules }
        : undefined;
    }

    if (isPlainObject(obj)) {
      const type = 'object';
      const fields = Object.keys(obj).reduce((acc, k) => {
        if (k === symlinksPropName && !isEmpty(obj[k])) {
          // nested repeaters
          return obj[k].reduce((acc, r) => {
            const {
              [symlinkFromPropertyName]: from,
              [symlinkToPropertyName]: to,
              [symlinkIndexPropertyName]: index,
            } = r;
            const mappedKey = mapDataWithRules(
              obj[to],
              `${from}.${index}.${to}`,
              repeaterItem,
              repeaterIndex
            );
            return mappedKey ? { ...acc, [to]: mappedKey } : acc;
          }, acc);
        } else {
          const mappedKey = mapDataWithRules(
            obj[k],
            parentKey ? `${parentKey}.${k}` : k,
            repeaterItem,
            repeaterIndex
          );
          return mappedKey ? { ...acc, [k]: mappedKey } : acc;
        }
      }, {});

      return Object.keys(fields).length ? { type, fields } : undefined;
    }

    const fieldMetadata = get(tabFields, parentKey, {});
    const {
      tabIndex: fieldTab,
      conditions: fieldConditions,
      validationRules: fieldRules,
    } = fieldMetadata;

    if (String(tabIndex) !== String(fieldTab)) return;

    const groupedData = repeaterItem || tabFieldsWithData;
    const groupData = {
      ...groupedData,
      isReviewer: props.isReviewer || false,
    };
    const compliesToConditions = validateConditions(
      fieldConditions,
      groupData,
      tabFieldsWithData
    );

    const replaceLastIndex = (_str, index) => {
      // removing the first repeater in a nested repeater string
      const str = _str
        .split('.')
        .slice(-3)
        .join('.');
      const pos = str.lastIndexOf('.0.');
      if (pos === -1) {
        return str;
      }
      // we just want to replace '0' with the new index
      return str.substring(0, pos + 1) + index + str.substring(pos + 2);
    };

    const fieldKey = replaceLastIndex(parentKey, repeaterIndex);
    const compliesToBackendConditions = validateBackendConditions(
      fieldKey,
      fieldRules,
      data,
      repeaterIndex
    );

    const { required: requiredByConditions = true } = fieldConditions || {};

    const transformRule = rule => {
      const {
        required: ruleRequired,
        conditions: ruleConditions,
        mustExist,
        encrypt,
        pattern,
        ...rest
      } = rule;
      const requiredByVisibility =
        !isEmpty(fieldConditions) && compliesToConditions;
      const requiredByBackend =
        !isEmpty(ruleConditions) && compliesToBackendConditions;

      const isRequired =
        rule.required ||
        (requiredByVisibility && requiredByConditions && requiredByBackend) ||
        requiredByBackend ||
        false;

      const fieldData = get(data, fieldKey);

      // when the field is encrypted and the user does not have permissions
      // to view encrypted data, the value of the field will be a UUID and
      // for masked fields, the pattern won't match the value, for instance
      // SSN pattern !== UUID, so, to avoid a validation error is better to
      // remove the pattern and keep only the required validation CV-293
      return {
        ...rest,
        pattern:
          encrypt && !isEmpty(fieldData) && isUUID.v4(fieldData)
            ? undefined
            : pattern,
        required: rule.optional ? false : isRequired,
      };
    };

    const conditionalFieldRules = Array.isArray(fieldRules)
      ? fieldRules.map(transformRule)
      : [transformRule(fieldRules)];

    return conditionalFieldRules.length ? conditionalFieldRules : undefined;
  };

  const mappedFieldsRules = mapDataWithRules(data) || {
    type: 'object',
    fields: { currentUserId: { type: 'string' } },
  };
  const preparedValidator = new validator(mappedFieldsRules.fields);

  return new Promise(resolve =>
    preparedValidator.validate(tabFieldsWithData, (errors, fields) => {
      // Custom asyn-validator
      let arrayFields = {};
      const singlelFields = keys(fields).reduce((result, field) => {
        const value = { errors: fields[field] };

        // Check if field key has dot notation from sync-validator
        // and populate arrayFields
        if (field.includes('.')) {
          set(arrayFields, field, value);
        } else {
          // Return root level field key
          return { ...result, [field]: value };
        }
      }, {});

      resolve({
        errors: errors || [],
        fields: { ...singlelFields, ...arrayFields } || {},
      });
    })
  );
};

export const getRequirementComplimentFieldsError = props => {
  const {
    selectedForm: { data = {}, formFields, validations: validationState },
    forceValidate = false,
  } = props;

  const requirementCompliantFields =
    formFields.requirementCompliantFields || [];

  const requirementCompliantFieldsErrors = requirementCompliantFields
    .map(item => {
      return compliesToRequirements({
        data,
        options: item,
        validationState,
        message: true,
        forceValidate,
      });
    })
    .filter(item => !item.complies);

  const compliesErrors = requirementCompliantFieldsErrors.map(
    item => item.message
  );

  if (compliesErrors.length) {
    if (process.env.NODE_ENV === 'development') {
      compliesErrors.forEach(item =>
        //eslint-disable-next-line no-console
        console.warn('async-validator / custom: ', [item])
      );
    }
  }

  return requirementCompliantFieldsErrors;
};
