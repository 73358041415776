import React, { useState } from 'react';
import { Button } from 'antd';

import {
  RadioStyled,
  RadioGroupStyled,
  ButtonGroupStyled,
  DividerStyled,
} from './AuditLogsOptions.style';
import getRoute from 'APP_ROOT/utils/get-route';

const userProfileAuditPage = 'User Profile Audit Page';
const reportTimelineAuditPage = 'Report Timeline Audit Page';

const AuditLogsOptions = ({ hideModal, agencyData, push }) => {
  const [radioValue, setRadioValue] = useState(userProfileAuditPage);
  const { agencyId, tenantId } = agencyData;
  const onOkClick = value => {
    const route =
      userProfileAuditPage === value
        ? 'administratorAgencyViewAuditLogs'
        : 'administratorAgencyReportTimelineAuditLogs';
    push(
      getRoute(route, {
        agencyId,
        tenantId,
      })
    );
    hideModal();
  };

  return (
    <>
      <RadioGroupStyled
        onChange={e => setRadioValue(e.target.value)}
        value={radioValue}
      >
        <RadioStyled value={userProfileAuditPage}>
          {userProfileAuditPage}
        </RadioStyled>
        <RadioStyled value={reportTimelineAuditPage}>
          {reportTimelineAuditPage}
        </RadioStyled>
      </RadioGroupStyled>
      <DividerStyled />
      <ButtonGroupStyled>
        <Button type="primary" onClick={() => onOkClick(radioValue)}>
          OK
        </Button>
        <Button onClick={() => hideModal()}>Cancel</Button>
      </ButtonGroupStyled>
    </>
  );
};

export default AuditLogsOptions;
