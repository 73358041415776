import getCurrentWorkFlowStatus from './getCurrentWorkFlowStatus';

import REPORT_STATUS from 'APP_ROOT/constants/status';

import { EDITABLE_STATUS, ADMIN_EDITABLE_STATUS } from '../constants';

const isEditableStatus = props => {
  const currentWorkFlowStatus = getCurrentWorkFlowStatus(props);
  const isReviewer = props.isReviewer;

  return (
    EDITABLE_STATUS.includes(REPORT_STATUS[currentWorkFlowStatus]) ||
    (ADMIN_EDITABLE_STATUS.includes(REPORT_STATUS[currentWorkFlowStatus]) &&
      !isReviewer)
  );
};

export default isEditableStatus;
