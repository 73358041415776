import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { CLOSED_STATUS } from './constants';

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      style,
      ...restProps
    } = this.props;

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

let dragingIndex = -1;

const disableDragAndDrop = props => {
  const typeElement = props.children.find(child => child.key === 'type');
  const statusType = typeElement.props.record.type;
  if (statusType === CLOSED_STATUS) {
    return false;
  }
  return true;
};

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
  canDrag: props => disableDragAndDrop(props),
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
  canDrop: props => disableDragAndDrop(props),
};

const StatusBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

export default StatusBodyRow;
