import { push } from 'react-router-redux';
import { mangle } from '../utils/mangle';
import { isArray, isEmpty } from 'lodash';
import { escape as htmlEscape, unescape as htmlUnescape } from 'html-escaper';

import trackServerError from '../actions/track-server-error';
import { LOGOUT } from '../actions';
import store from '../store';

const protocol = process.env.HTTPS ? 'https' : 'http';
const DEV_HOST = `${protocol}://localhost:3443/api`;

export const HOST = process.env.REACT_APP_API_URL || DEV_HOST;
export const REACT_APP_FORMS_SERVICE_URI =
  process.env.REACT_APP_FORMS_SERVICE_URI ||
  '${protocol}://localhost:3007/forms/v1/';

export const FILE_UPLOAD_REMOTE =
  process.env.REACT_APP_REMOTE_UPLOAD_HOST || HOST;

export const getFileUploadUrl = (url = '') => `${FILE_UPLOAD_REMOTE}${url}`;

export const ENCODE_BLACKLIST = [];

const OPEN_CURLY_BRACKET_ENCODED = encodeURIComponent('{');
const OPEN_SQUARE_BRACKET_ENCODED = encodeURIComponent('[');

export const escapeInput = data => {
  return isEmpty(data)
    ? data
    : Object.keys(data).reduce((escaped, key) => {
        if (ENCODE_BLACKLIST.includes(key)) {
          escaped[key] = data[key];
        } else if (isArray(data[key])) {
          escaped[key] = data[key].map(value => {
            switch (typeof value) {
              case 'string':
                return encodeURIComponent(htmlUnescape(value));
              case 'object':
                return escapeInput(value);
              default:
                return value;
            }
          });
        } else if (typeof data[key] === 'object') {
          escaped[key] = escapeInput(data[key]);
        } else if (typeof data[key] === 'string') {
          //This apply the encodeURIComponent to a plain field
          escaped[key] = encodeURIComponent(htmlUnescape(data[key]));
        } else {
          escaped[key] = data[key];
        }
        return escaped;
      }, {});
};

export const unEscapeInput = data => {
  return isEmpty(data)
    ? data
    : Object.keys(data).reduce((unescaped, key) => {
        if (ENCODE_BLACKLIST.includes(key)) {
          unescaped[key] = data[key];
        } else if (isArray(data[key])) {
          unescaped[key] = data[key].map(value => {
            switch (typeof value) {
              case 'string':
                return htmlEscape(decodeURIComponent(value));
              case 'object':
                return unEscapeInput(value);
              default:
                return value;
            }
          });
        } else if (typeof data[key] === 'object') {
          unescaped[key] = unEscapeInput(data[key]);
        } else if (typeof data[key] === 'string') {
          //This apply the decodeURIComponent to a plain field
          unescaped[key] = htmlEscape(decodeURIComponent(data[key]));
        } else {
          unescaped[key] = data[key];
        }
        return unescaped;
      }, {});
};

export const genOptions = (method, data, authorization, extraHeaders) => {
  method = method.toUpperCase();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...extraHeaders,
  };

  if (authorization) {
    headers.Authorization = authorization;
  }

  let options = {
    headers,
    method,
    credentials: 'include',
  };

  if (['PUT', 'POST', 'PATCH'].indexOf(method) !== -1) {
    if (headers['Content-Type'] !== 'application/x-www-form-urlencoded') {
      if (data) {
        options.body = JSON.stringify(data);
      }
    } else {
      options.body = '';
    }
  }

  return options;
};

export function encodeFilter(filter) {
  return encodeURIComponent(JSON.stringify(mangle(filter)));
}

export const route = path => {
  if (path.indexOf('/') === 0) {
    path = `${HOST}${path}`;
  }

  return path;
};

export const fetchRequest = (url, options, returnResponse = false) => {
  if (
    url.includes(`filter=${OPEN_CURLY_BRACKET_ENCODED}`) ||
    url.includes(`filter=${OPEN_SQUARE_BRACKET_ENCODED}`) ||
    url.includes(`where=${OPEN_CURLY_BRACKET_ENCODED}`) ||
    url.includes(`where=${OPEN_SQUARE_BRACKET_ENCODED}`)
  ) {
    options.headers.mangled = 'true';
  }

  return fetch(url, options)
    .then(isSuccessful)
    .then(response => {
      response.returnResponse = returnResponse;
      return getJson(response);
    });
};

export const fetchRequestBlob = (url, options) => {
  return fetch(url, options)
    .then(isSuccessful)
    .then(getBlob);
};

export const fetchRequestFile = (url, options) => {
  return fetch(url, options).then(isSuccessful);
};

export function ResponseException(response) {
  this.message = 'API response is not ok';
  this.status = response.status;
  this.response = response;

  return this;
}

function AuthenticationException(response) {
  this.message = 'API authentication failed';
  this.response = response;

  return this;
}

const isSuccessful = response => {
  if (
    [401, 419, 500, 404].indexOf(response.status) !== -1 ||
    [200, 201, 204].indexOf(response.status)
  ) {
    store.dispatch(trackServerError(response));
  }
  if ([401, 419].indexOf(response.status) !== -1) {
    store.dispatch({ type: LOGOUT });
    store.dispatch(push('/login'));
    throw new AuthenticationException(response);
  } else if ([200, 201, 204].indexOf(response.status) === -1) {
    throw new ResponseException(response);
  }

  return response;
};

const getBooleanStatusCode = status => {
  return status === 204 || status === 201;
};

export const getJson = response => {
  const booleanStatusCode =
    getBooleanStatusCode(response.status) && !response.returnResponse;
  if (booleanStatusCode) {
    return Promise.resolve(true);
  }
  return response.json();
};

export const getBlob = response => {
  if (getBooleanStatusCode(response.status)) {
    return Promise.resolve(true);
  }
  return response.blob();
};
