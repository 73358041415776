import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.add-task-modal {
    .ant-modal-title {
      font-weight: bold;
      font-size: 16px;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export default StyledModal;
