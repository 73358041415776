import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.add-note-modal {
    .ant-modal-footer {
      display: flex;
    }

    button:nth-child(2) {
      margin-left: auto;
    }
  }
`;

export default StyledModal;
