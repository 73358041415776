import React, { Component } from 'react';
import { connect } from 'react-redux';

import { union, get } from 'lodash';
import { Button, notification, Input, Row, Col, Modal, Tooltip } from 'antd';

import { getAgencyId } from '../../../selectors/session';

import StyledModal from './AddReportModal.styled';
import StyledTable from 'APP_COMPONENTS/custom-sections/tables/CustomSectionTable.styled';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';
import fetchReadableStream from '../utils/fetchReadableStream';

import getRoute from 'APP_ROOT/utils/get-route';

const PAGE_SIZE = 10;
const SEARCH_WARNING_TITLE = 'Warning';
const SEARCH_WARNING =
  'You will lose your current selections by initiating a new search';

const { Search } = Input;
const { confirm } = Modal;

const columns = [
  {
    title: 'Report Number',
    dataIndex: 'reportNumber',
    key: 'reportNumber',
    render: (text, record) => {
      const location = getRoute('report', { id: record.reportId });
      return (
        <Button type="link" href={location} target="_blank" title={text}>
          {text}
        </Button>
      );
    },
  },
  {
    title: 'Report Type',
    dataIndex: 'reportType',
    key: 'reportType',
    render: (text, record) => {
      return <span dangerouslySetInnerHTML={{ __html: text }} />;
    },
  },
];

class AddReportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: false,
      reports: [],
      pagination: {},
      selectedRowKeys: [],
      alreadySelectedRowKeys: [],
      keywords: '',
      selectedRowObjects: [],
    };
  }

  componentDidMount() {
    this.setState({ search: true });
  }

  componentDidUpdate(prevProps) {
    const {
      agencyId,
      hierarchyKey,
      organizationalUnitId,
      casefileId,
      visible,
    } = this.props;
    const { search, keywords } = this.state;
    if (visible && search) {
      this.setState({ search: false, loading: true }, () => {
        caseFileEndpoints
          .searchReports(
            agencyId,
            hierarchyKey,
            organizationalUnitId,
            casefileId,
            PAGE_SIZE,
            0,
            keywords
          )
          .then(this.fetch);
      });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const {
      agencyId,
      hierarchyKey,
      organizationalUnitId,
      casefileId,
    } = this.props;
    const { pagination: pager, keywords } = this.state;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    const skip = (pager.current - 1) * PAGE_SIZE;
    caseFileEndpoints
      .searchReports(
        agencyId,
        hierarchyKey,
        organizationalUnitId,
        casefileId,
        PAGE_SIZE,
        skip,
        keywords
      )
      .then(this.fetch)
      .catch(error => {
        if (error?.response?.body instanceof ReadableStream) {
          fetchReadableStream(error.response.body, this.fetch);
        } else {
          this.showNotification(
            error,
            'Something went wrong',
            error?.error || error?.message || ''
          );
        }
      });
  };

  fetch = (reportsData = {}) => {
    const { selectedRowKeys, alreadySelectedRowKeys, pagination } = this.state;
    const reports = this.getReportList(reportsData.data);
    const selectedKeys = reports
      .filter(r => r.isAssociated)
      .map(r => r.reportId);
    this.setState({
      loading: false,
      reports,
      selectedRowKeys: union(selectedRowKeys, selectedKeys),
      alreadySelectedRowKeys: union(alreadySelectedRowKeys, selectedKeys),
      pagination: {
        ...pagination,
        total: reportsData.totalRecords,
      },
    });
  };

  getReportList = (reportList = []) => {
    const reports = reportList.map(r => ({
      key: r.reportId,
      reportNumber: r.reportNumber,
      reportType: r.reportType,
      reportId: r.reportId,
      isAssociated: r.isAssociated,
    }));
    return reports;
  };

  showNotification = (type, message, description) =>
    notification[type]({ message, description });

  initData = () =>
    this.setState({
      selectedRowKeys: [],
      selectedRowObjects: [],
      alreadySelectedRowKeys: [],
      search: true,
      reports: [],
      keywords: '',
      pagination: { current: 1 },
    });

  onModalOk = e => {
    const { onOk } = this.props;
    const {
      selectedRowKeys,
      alreadySelectedRowKeys,
      selectedRowObjects,
    } = this.state;
    const selectedReportObjects = selectedRowObjects.filter(
      ({ key }) =>
        !alreadySelectedRowKeys.includes(key) && selectedRowKeys.includes(key)
    );

    onOk && onOk(e, selectedReportObjects);
    this.initData();
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
    this.initData();
  };

  onRowSelectionChange = (selectedRowKeys, selectedRows) => {
    const { selectedRowObjects } = this.state;
    this.setState({
      selectedRowKeys,
      selectedRowObjects: union(selectedRowObjects, selectedRows),
    });
  };

  onSearch = value => {
    const { selectedRowKeys, alreadySelectedRowKeys } = this.state;
    if (selectedRowKeys.length === alreadySelectedRowKeys.length) {
      this.setState({ search: true, pagination: { current: 1 } });
    } else {
      confirm({
        title: SEARCH_WARNING_TITLE,
        content: SEARCH_WARNING,
        onOk: this.onConfirmSearch,
      });
    }
  };

  onConfirmSearch = () =>
    this.setState({
      search: true,
      selectedRowKeys: [],
      alreadySelectedRowKeys: [],
      pagination: { current: 1 },
    });

  onKeywordsChange = e => {
    this.setState({ keywords: e.target.value });
  };

  renderSearch = () => {
    const { loading, keywords } = this.state;

    return (
      <Tooltip placement="topLeft" title="Search by report name or case number">
        <Search
          placeholder="Search"
          onSearch={this.onSearch}
          loading={loading}
          value={keywords}
          onChange={this.onKeywordsChange}
          allowClear
        />
      </Tooltip>
    );
  };

  render() {
    const { title, visible } = this.props;
    const {
      reports,
      pagination,
      loading,
      selectedRowKeys,
      alreadySelectedRowKeys,
    } = this.state;

    const disableSave =
      selectedRowKeys.length === alreadySelectedRowKeys.length;
    const rowSelection = {
      onChange: this.onRowSelectionChange,
      selectedRowKeys,
      getCheckboxProps: record => ({
        disabled: record.isAssociated,
      }),
    };

    return (
      <StyledModal
        title={title}
        width={1000}
        className="add-report-modal"
        centered
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={[
          <Button key="cancel" onClick={this.onModalCancel}>
            Cancel
          </Button>,
          <Button
            key="Save"
            type="primary"
            onClick={this.onModalOk}
            disabled={disableSave}
          >
            Save
          </Button>,
        ]}
      >
        <Row gutter={[8, 8]}>
          <Col span={6} offset={18}>
            {this.renderSearch()}
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <StyledTable
            className="add-reports-list"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={reports}
            pagination={pagination}
            loading={loading}
            onChange={this.handleTableChange}
          ></StyledTable>
        </Row>
      </StyledModal>
    );
  }
}

const mapState = (state, props) => {
  const agencyId = getAgencyId(state);
  const hierarchyKey = get(state, 'session.currentUser.hierarchyKey');
  const organizationalUnitId = get(
    state,
    'session.currentUser.organizationalUnitId'
  );
  return {
    agencyId,
    hierarchyKey,
    organizationalUnitId,
  };
};

export default connect(mapState)(AddReportModal);
