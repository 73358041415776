import { camelCase } from 'lodash';

import AgencyCasefiles from './AgencyCasefiles';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/casefile-types/:tenantId`,
    name: camelCase(`${name}-casefiles`),
    component: AgencyCasefiles,
    protectedWith: [PERMISSIONS.manageCaseFileTypes],
    childRoutes: [],
  },
];
