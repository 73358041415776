/**
 * This file contains utils for the contribute to report feature for field-types
 */
import {
  CONTRIBUTOR_ASSIGNMENT,
  CONTRIBUTOR_ASSIGNMENT_DATE,
  CONTRIBUTOR_SECTION_COMPLETE,
  CONTRIBUTOR_SECTION_COMPLETE_DATE,
  SECTION_ASSIGN_ACTION,
  SECTION_UNASSIGN_ACTION,
  CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
  SECTION_COMPLETE_ACTION,
  SECTION_INCOMPLETE_ACTION,
  CONTRIBUTOR_SHARE_UPDATE,
  CONTRIBUTOR_MANUAL_SAVE,
  CONTRIBUTOR_ASSIGNATION,
  CONTRIBUTOR_COMPLETION,
} from '../../../../constants/contributeToReport.js';
import updateAssignedSections from '../../../../containers/report-type/updateAssignedSections.js';
import {
  sendSectionCompleteNotification,
  removeAssignTaskNotification,
} from '../../forms/utils/contribute-report-notifications.js';

/**
 * Creates an obj for the timeline history
 * @param {Number} formId form to redirect to
 * @param {Enumerator} action contribute to report action
 * @param {Number} assignedId user
 * @param {Number} assignatId user
 * @returns
 */
const addHistoryItem = (formId, action, assignedId, assignatId) => ({
  action: action,
  assignedId,
  assignatId,
  formId,
  createdAt: new Date(),
  type: CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
});
/**
 *
 * @param {Array} assignedSections list of all assigned sections
 * @param {Number} userId current evaluated user
 * @param {Object} currentAssignedSection section which user is already assigned
 * @returns {Boolean} wether the user is currently assigned o another section
 */
export function isContributorForAnotherSection(
  assignedSections,
  userId,
  currentAssignedSection
) {
  const anyOtherSection = assignedSections.find(
    section =>
      section.userId === userId &&
      section.sectionId !== currentAssignedSection.sectionId
  );
  return !!anyOtherSection;
}

/**
 * Emits events to do the updates related to contribute to report
 * specifically takes care of the section assignment action
 * and updates internal history
 * @param {Object} props field props
 * @param {Number} inputValue id of the selected user
 */
export async function emitReviewerAssignment(props, inputValue = null) {
  const { reportingKey, dataKey: key } = props;
  // Assuming the form is a performance review form if it has this reporting key
  if (reportingKey !== CONTRIBUTOR_ASSIGNMENT) return;
  if (inputValue === props.value) return;
  const value = inputValue || '';
  // emit share-unshare + timestamp update
  const detail = { unshareUserId: props.value, shareUserId: value };
  const {
    selectedForm: {
      meta: { id: formId },
      data: { __assignedSections, __assignedSectionsHistory },
    },
  } = props;
  // Find the field where this is a review:
  const sectionToUpdate = __assignedSections.find(
    section => section.contributorFields[CONTRIBUTOR_ASSIGNMENT] === key
  );
  detail.unshareUserId = sectionToUpdate.userId;
  if (!detail.unshareUserId && !value) return;

  const stillAssigned = isContributorForAnotherSection(
    __assignedSections,
    sectionToUpdate.userId,
    sectionToUpdate
  );
  // Don't unshare when still a contributor
  if (stillAssigned) {
    detail.unshareUserId = null;
  }
  if (formId) {
    window.dispatchEvent(new CustomEvent(CONTRIBUTOR_SHARE_UPDATE, { detail }));
  }
  window.dispatchEvent(
    new CustomEvent(CONTRIBUTOR_ASSIGNATION, {
      detail: sectionToUpdate.contributorFields[CONTRIBUTOR_ASSIGNMENT_DATE],
    })
  );

  const updatedHistory = __assignedSectionsHistory;
  const { activeUser } = props.data;

  if (sectionToUpdate.userId) {
    updatedHistory.push(
      addHistoryItem(
        formId,
        SECTION_UNASSIGN_ACTION,
        sectionToUpdate.userId,
        activeUser
      )
    );
  }
  if (value) {
    updatedHistory.push(
      addHistoryItem(formId, SECTION_ASSIGN_ACTION, value, activeUser)
    );
  }
  await updateAssignedSections(props, {
    __assignedSections: __assignedSections.map(existingSec => {
      if (existingSec.sectionId === sectionToUpdate.sectionId) {
        return {
          ...existingSec,
          userId: value,
        };
      }
      return existingSec;
    }),
    __assignedSectionsHistory: updatedHistory,
  });
  // trigger manual save to update snapshot
  window.dispatchEvent(
    new CustomEvent(CONTRIBUTOR_MANUAL_SAVE, {
      detail: { includeShare: !formId, shareDetails: detail },
    })
  );
}
/**
 * Emits events to do the updates related to contribute to report
 * specifically takes care of the section completion action
 * and updates internal history
 * @param {*} state
 * @param {*} props
 * @param {*} value
 */
export async function emitSectionCompletion(props, value) {
  const { reportingKey, dataKey: key } = props;
  // Assuming the form is a performance review if it has this reporting key
  if (reportingKey === CONTRIBUTOR_SECTION_COMPLETE) {
    // Update __assignedSection as complete
    const {
      selectedForm,
      selectedForm: {
        meta: { id: formId },
        data: { __assignedSections, __assignedSectionsHistory },
      },
    } = props;
    const sectionToUpdate = __assignedSections.find(
      section => section.contributorFields[CONTRIBUTOR_SECTION_COMPLETE] === key
    );
    window.dispatchEvent(
      new CustomEvent(CONTRIBUTOR_COMPLETION, {
        detail:
          sectionToUpdate.contributorFields[CONTRIBUTOR_SECTION_COMPLETE_DATE],
      })
    );
    const updatedHistory = __assignedSectionsHistory;
    // Marked as true
    if (value) {
      updatedHistory.push(
        addHistoryItem(formId, SECTION_COMPLETE_ACTION, sectionToUpdate.userId)
      );
    } else {
      updatedHistory.push(
        addHistoryItem(
          formId,
          SECTION_INCOMPLETE_ACTION,
          sectionToUpdate.userId
        )
      );
    }
    await updateAssignedSections(props, {
      __assignedSections: __assignedSections.map(existingSec => {
        if (existingSec.sectionId === sectionToUpdate.sectionId) {
          return {
            ...existingSec,
            complete: value,
          };
        }
        return existingSec;
      }),
      __assignedSectionsHistory: updatedHistory,
    });
    if (value === true) {
      sendSectionCompleteNotification(selectedForm, sectionToUpdate.userId);
      removeAssignTaskNotification(sectionToUpdate.userId);
    }
    // trigger manual save to update snapshot
    window.dispatchEvent(
      new CustomEvent(CONTRIBUTOR_MANUAL_SAVE, {
        detail: { includeShare: false, shareDetails: {} },
      })
    );
  }
}
/**
 * Creates an updated visibility object based on the contribute to report properties
 * Logic specific to the select contributor auto-complete
 * @param {Object} props react props for the field
 * @param {Boolean} originalIsReviewer current visibility state of the field
 * @param {String} reportingKey field's reporting key
 * @returns {Object} { overrideReviewer, overrideEditRights }
 */
export function getVisibilityOverridesContributor(props) {
  const {
    reportingKey,
    isReviewer,
    selectedForm: { meta, isAdminEdit },
    isContributeReport,
    isContributorAssign,
    contributorAssignmentCanEdit,
    contributorAssignmentCanView,
  } = props;

  const activeUser = Number(props.data.activeUser);
  const submitterId = Number(meta.submitterId || activeUser);
  const isContributorAssignmentField = reportingKey === CONTRIBUTOR_ASSIGNMENT;
  let overrideReviewer = isReviewer;
  let overrideEditRights = false;
  if (isContributorAssignmentField && !isAdminEdit) {
    // Only submitter can select a contributor
    if (isContributorAssign && activeUser !== submitterId) {
      overrideReviewer = true;
    }
  } else if (isContributeReport) {
    if (contributorAssignmentCanEdit) {
      overrideEditRights = true;
    } else if (contributorAssignmentCanView) {
      overrideReviewer = true;
    }
  }

  return {
    overrideReviewer,
    overrideEditRights,
    isContributorAssignmentField,
  };
}
/**
 * Creates an updated visibility value based on the contribute to report properties
 * Logic specific to the select contributor complete check-box
 * @param {Object} props react props for the field
 * @param {Boolean} originalIsReviewer current visibility state of the field
 * @param {String} reportingKey field's reporting key
 * @returns {Object}
 */
export function getVisibilityOverridesCompleteCheckbox(
  props,
  originalIsReviewer
) {
  const {
    reportingKey,
    contributorAssignmentCanEdit,
    contributorAssignmentCanView,
  } = props;
  let overrideReviewer = originalIsReviewer;
  const isContributorSectionComplete =
    reportingKey === CONTRIBUTOR_SECTION_COMPLETE;

  if (contributorAssignmentCanView && !contributorAssignmentCanEdit) {
    overrideReviewer = true;
  }
  if (contributorAssignmentCanEdit || isContributorSectionComplete) {
    overrideReviewer = false;
  }
  return {
    overrideReviewer,
    isContributorSectionComplete,
  };
}
