import React from 'react';
import parseDate, {
  BENCHMARK_DATE_FORMAT,
} from '../../../../../utils/parse-date';
import { Icon } from 'antd';

function sortAlphabetically(a, b, field) {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  return 0;
}

function sortByCreationDate(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}

function renderDate(data, timezone) {
  if (data) {
    const date = parseDate(data, timezone, BENCHMARK_DATE_FORMAT);
    return `${date}`;
  }
  return '';
}

export default (timezone, handleClick) => [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    sorter: (a, b) => sortAlphabetically(a, b, 'description'),
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    defaultSortOrder: 'ascend',
    sorter: (a, b) => sortByCreationDate(a, b),
    render: data => renderDate(data, timezone),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: 90,
    render: (_, data) => (
      <Icon
        type="delete"
        style={{ color: 'red' }}
        onClick={() => handleClick(data)}
      />
    ),
  },
];
