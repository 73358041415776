import { get, set, isPlainObject } from 'lodash';

import {
  symlinkPathPropertyName,
  symlinksPropertiesPropName,
  hasSymlinks,
  getSymlinks,
} from 'APP_ROOT/utils/create-symlink';

import transformRule from './transformRule';
import replaceLastIndex from './replaceLastIndex';

import validateConditions from '../validateConditions';
import validateBackendConditions from '../validateBackendConditions';

const isProtectedField = parentKey => {
  return /0.doneWithAction$/.test(parentKey);
};

export const setNestedRepeaterReference = (
  formData,
  parentKey,
  acc,
  arrItem,
  arrIndex
) => {
  // for nested repeaters there are some symlink properties defined
  // to control which nested item goes in which repeater block, is
  // importat to save them to keep data integrity, not whitelisted
  // because part of the data is dynamic and for better understanding
  // we want to keep all the related changes together
  const [pKey] = parentKey.split('.').slice(-1);
  if (hasSymlinks(arrItem)) {
    const props = getSymlinks(arrItem);
    set(acc, `${pKey}.${arrIndex}.${symlinksPropertiesPropName}`, props);
    props.forEach(prop => {
      set(acc, `${pKey}.${arrIndex}.${prop}`, arrItem[prop]);
    });
  }
  set(
    acc,
    `${pKey}.${arrIndex}.${symlinkPathPropertyName}`,
    get(formData, `${pKey}.${arrIndex}.${symlinkPathPropertyName}`)
  );
};

export const shouldBeInData = (
  data,
  fieldMetadata,
  formData,
  parentKey,
  arrayItem,
  arrayIndex,
  acc
) => {
  const fieldKey = replaceLastIndex(parentKey, arrayIndex);
  const {
    conditions: fieldConditions,
    validationRules: fieldRules,
    defaultValue: fieldDefaultValue,
  } = fieldMetadata;
  const groupData = {
    ...(arrayItem || formData),
    isReviewer: data.isReviewer,
  };
  const compliesToConditions = validateConditions(
    fieldConditions,
    groupData,
    data
  );
  const compliesToBackendConditions = validateBackendConditions(
    fieldKey,
    fieldRules,
    data,
    arrayIndex
  );
  const { required: requiredByConditions = true } = fieldConditions || {};

  const shouldBeInData = Array.isArray(fieldRules)
    ? fieldRules
        .map(
          transformRule(
            data,
            fieldConditions,
            compliesToConditions,
            compliesToBackendConditions,
            requiredByConditions
          )
        )
        .reduce((acc, current) => acc && current, !!fieldRules.length)
    : isPlainObject(fieldRules)
    ? transformRule(
        data,
        fieldConditions,
        compliesToConditions,
        compliesToBackendConditions,
        requiredByConditions
      )(fieldRules)
    : false;

  const keyData =
    shouldBeInData || isProtectedField(parentKey)
      ? get(data, fieldKey, fieldDefaultValue)
      : undefined;
  set(acc, fieldKey, keyData);
};

export const transferIdsForRepeaters = (data, result) => {
  // Transfer ids for repeaters
  // The idea is to keep the id for each element in a repeater
  return Object.keys(result).reduce((acc, key, index) => {
    if (Array.isArray(result[key])) {
      return {
        ...acc,
        [key]: result[key].map((item, index) => {
          // For multiselects we want to return the value
          if (!isPlainObject(item)) {
            return item;
          }
          return {
            ...item,
            id: get(data, [key, index, 'id']),
          };
        }),
      };
    }
    return { ...acc, [key]: result[key] };
  }, {});
};
