import React, { Component, Fragment } from 'react';
import ContentEditable from 'react-contenteditable';
import {
  get,
  debounce,
  isPlainObject,
  keys,
  omit,
  has,
  find,
  isArray,
  trim,
  isEmpty,
  isObject,
} from 'lodash';
import { Icon, Tooltip, Modal } from 'antd';

import getOverrides from 'APP_ROOT/utils/get-field-overrides';
import getOfficersAndCivilians from 'APP_ROOT/actions/get-officers-and-civilians';
import getRanks from 'APP_ROOT/actions/get-ranks';
import removeSourceSelection from 'APP_ROOT/actions/remove-source-selection';
import removeReferenceSelection from 'APP_ROOT/actions/remove-reference-selection';
import setSourceSelection from 'APP_ROOT/actions/set-source-selection';
import createDynamicEnumRef from 'APP_ROOT/actions/create-dynamic-enum-ref';
import postFormLink from 'APP_ROOT/actions/post-form-link';
import postEmptyDraftReport from 'APP_ROOT/actions/post-empty-draft-report';
import addSource from 'APP_ROOT/actions/add-source';
import deleteFormLink from 'APP_ROOT/actions/delete-form-link';
import fetchDORFORM from 'APP_ROOT/actions/fetch-dor-form';
import syncFormData from 'APP_ROOT/actions/sync-form-data';
import FormItem from '../../styled/form-item';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';
import setFormValues from 'APP_ROOT/actions/set-form-values';

import getFormItemLayout from './getFormItemLayout';
import formInterface from './formInterface';
import getFieldProps from './getFieldProps';
import FieldInput from './getFieldInput';
import getFieldDataKey from './getFieldDataKey';
import getInterpolatedLabel from './getInterpolatedLabel';
import getIsReadOnly from './getIsReadOnly';
import getCalculatedValue from './getCalculatedValue';
import getDependsOnChange from './getDependsOnChange';
import getFieldOptions from './getFieldOptions';
import getFieldIsDisabled from './getFieldIsDisabled';
import getFormLinkConfiguration, {
  getSelectedTemplateType,
  getTemplatesNames,
} from './getFormLinkConfiguration';
import getAllDraftReports from './getAllDraftReports';
import { SWITCH, AUTOCOMPLETE } from '../../../../constants/fieldTypes';
import { CONTRIBUTOR_ASSIGNMENT } from '../../../../constants/contributeToReport.js';

import getTitleTooltip from '../../../utils/getTitleTooltip';
import decorateTextWithHyperlink from '../../../utils/decorateTextWithHyperlink';

import validateBooleanValue from './validateBooleanValue';
import StyledSpan from './FormField.styled.js';
import normalizeFilter from './normalizeFilter';

import isMobileDevice from '../../../../utils/isMobileDevice';

const isMobile = isMobileDevice();
const dropdowns = ['select', 'multiselect', 'dynamic-select', 'autocomplete'];

const reviewDecorator = (name, options) => fieldComponent => fieldComponent;

class Field extends Component {
  // Used to trigger contribute report autocomplete once
  state = {
    contRepAutocompleteLoaded: false,
  };

  setDefaultForm = options => {
    const { dataKey, settings: { formName } = {}, data = {} } = this.props;
    const { defaultFrom } = options;

    if (defaultFrom) {
      formActionDispatcher(
        formName,
        syncFormData(
          {
            [dataKey]: data[defaultFrom],
          },
          {}
        )
      );
    }
  };

  setTrackingNumber = (compoundEnumRef, activeUser, defaultValue) => {
    const { settings: { formName } = {} } = this.props;

    const templateType = getSelectedTemplateType();
    const templatesTypeLinked = getFormLinkConfiguration({
      scope: templateType,
    });
    const templatesName = getTemplatesNames(templatesTypeLinked);

    formActionDispatcher(
      formName,
      getAllDraftReports(
        compoundEnumRef,
        [],
        activeUser,
        defaultValue,
        templatesName,
        this.createFallbackEnumFromData()
      )
    );
  };

  createPayload = (options, compoundEnumRef, defaultValue) => {
    const {
      dataKey,
      settings: { formName } = {},
      data: { currentUserId = '' } = {},
    } = this.props;
    const { filter = {} } = options;

    const payload = {
      enumRef: compoundEnumRef,
      filter: {
        ...options.filter,
        include: 'rank',
        where: filter.where || {},
      },
      options,
      formName,
    };

    if (dataKey === 'currentUserId') {
      payload.filter = {
        ...payload.filter,
        where: {
          ...payload.filter.where,
          id: !isEmpty(currentUserId) ? currentUserId : undefined,
        },
      };
    } else if (defaultValue) {
      payload.filter = {
        ...payload.filter,
        where: {
          id: !isEmpty(defaultValue) ? defaultValue : undefined,
        },
      };
    }
    if (payload.filter.where.id) {
      payload.filter.id = payload.filter.where.id;
    }
    return payload;
  };

  shouldOverrideFilter = filter => {
    const { reportingKey } = this.props;
    // Only override for contribute to report
    if (reportingKey !== CONTRIBUTOR_ASSIGNMENT) return false;
    // If the filter is looking for an specific user
    // don't override
    if (filter.id) return false;
    return true;
  };

  ranksCallback = (options, payload) => data => {
    const {
      settings: { formName, agencyId } = {},
      combinedEnumRef,
    } = this.props;
    const { useRanks = false, onlyTrainees = false } = options;
    formActionDispatcher(
      formName,
      getOfficersAndCivilians(
        normalizeFilter(
          this.props,
          this.replaceRanks(useRanks, data, payload),
          combinedEnumRef,
          agencyId
        ),
        (error, data) => {
          if (isEmpty(data)) {
            this.createFallbackEnumFromData();
          }
        },
        onlyTrainees
      )
    );
  };

  ranksCallbackUsers = options => data => {
    const {
      settings: { formName, agencyId } = {},
      data: { activeUser = '' } = {},
      enumRef = '',
      combinedEnumRef,
    } = this.props;
    const { filter = {}, onlyTrainees = false } = options;
    const filterWithoutCurrentUser = {
      ...filter,
      where: {
        ...get(filter, ['where'], {}),
        and: [
          {
            id: {
              neq: activeUser,
            },
          },
        ],
      },
    };

    formActionDispatcher(
      formName,
      getOfficersAndCivilians(
        normalizeFilter(
          this.props,
          {
            enumRef,
            filter: filterWithoutCurrentUser,
            options,
            formName,
          },
          combinedEnumRef,
          agencyId
        ),
        () => {},
        onlyTrainees
      )
    );
  };

  setAutocomplete = options => {
    const {
      dataKey,
      parentKey,
      parentIndex,
      enumRef = '',
      data = {},
    } = this.props;
    const { activeUser = '' } = data;
    const hasParent = !!parentKey;
    const defaultValue = this.getValue(dataKey);

    const compoundEnumRef = hasParent
      ? `${enumRef}-${parentIndex}-${dataKey}`
      : enumRef;

    if (
      ['vehiclePursuitTrackingNumber', 'useOfForceTrackingNumber'].includes(
        dataKey
      )
    ) {
      this.setTrackingNumber(compoundEnumRef, activeUser, defaultValue);
    } else {
      const payload = this.createPayload(
        options,
        compoundEnumRef,
        defaultValue
      );
      this.getRanksIfNeeded().then(this.ranksCallback(options, payload));
    }
  };

  setDropdowns = options => {
    const { source = '' } = options;

    if (source === 'users') {
      this.getRanksIfNeeded().then(this.ranksCallbackUsers(options));
    }
  };

  componentDidMount() {
    const { field_type = '', isReviewer = false, overrides } = this.props;

    if (isReviewer) {
      return;
    }
    // Avoid re-loading data on this mount
    this.setState({ contRepAutocompleteLoaded: true });

    const fieldOverrides = getOverrides(this.props, overrides);
    const options = fieldOverrides('options', {});

    const { source = '', populateFrom = '' } = options;
    const populateFromRepeater = source == 'repeating';

    this.setDefaultForm(options);

    validateBooleanValue(this.props);

    if (field_type === AUTOCOMPLETE && !populateFromRepeater) {
      this.setAutocomplete(options);
    } else if (populateFrom) {
      this.createFallbackEnumFromData();
    } else if (dropdowns.includes(field_type) && source) {
      this.setDropdowns(options);
    }
  }

  /**
   * Contribute to report does not re-mounts the fields
   * This process happens during mount for fields
   */
  loadAutocompleteValuesForContributeToReportTypes() {
    const { field_type = '', overrides, isContributeReport } = this.props;

    // Only load autocomplete for contributor
    if (!isContributeReport || field_type !== AUTOCOMPLETE) return;
    // only fetch them once
    this.setState({ contRepAutocompleteLoaded: true });

    const fieldOverrides = getOverrides(this.props, overrides);
    const options = fieldOverrides('options', {});

    this.setAutocomplete(options);
  }

  createFallbackEnumFromData = () => {
    const {
      settings: { formName } = {},
      enumRef = '',
      data = {},
      overrides,
    } = this.props;

    const fieldOverrides = getOverrides(this.props, overrides);
    const options = fieldOverrides('options', {});

    const { populateFrom = '' } = options;

    if (populateFrom) {
      formActionDispatcher(
        formName,
        createDynamicEnumRef(populateFrom, enumRef, data)
      );
    }
  };

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
    // Review if need to populate auto-completes for contribute to report
    const { contRepAutocompleteLoaded } = this.state;
    if (!contRepAutocompleteLoaded) {
      this.loadAutocompleteValuesForContributeToReportTypes();
    }
  }

  getRanksIfNeeded = async () => {
    const { settings: { formName } = {}, overrides, dataEnums } = this.props;
    const fieldOverrides = getOverrides(this.props, overrides);
    const options = fieldOverrides('options', {});

    const { useRanks = false, ranksEnumRef = 'ranks' } = options;

    return new Promise(resolve => {
      if (!useRanks) {
        resolve();
      } else {
        const ranks = get(dataEnums, ranksEnumRef, []).map(rank =>
          get(rank, 'data', { id: rank.value, name: rank.label })
        );

        if (ranks.length !== 0) {
          resolve(ranks);
        } else {
          formActionDispatcher(
            formName,
            getRanks({ ranksEnumRef, options, formName }, (error, ranks = []) =>
              resolve(ranks)
            )
          );
        }
      }
    });
  };

  replaceRanks = (useRanks = false, data = [], query) => {
    if (!useRanks) {
      return query;
    }

    const filter = get(query, 'filter', {});
    const hasWhere = has(filter, 'where');
    const hasAnd = hasWhere ? has(get(filter, 'where'), 'and') : false;
    let normalizedFilter = filter;

    if (hasAnd) {
      normalizedFilter = {
        ...normalizedFilter,
        where: {
          ...get(normalizedFilter, 'where', {}),
          and: get(normalizedFilter, 'where.and', []).map(rule => {
            if (has(rule, 'rankId')) {
              if (data.length === 0) {
                return {
                  rankId: { neq: null },
                };
              }

              return {
                ...rule,
                rankId: {
                  inq: get(rule, 'rankId.inq', [])
                    .map(rankName => this.findRankName(data, rankName))
                    .filter(rank => rank !== -1)
                    .map(rank => rank.id),
                },
              };
            }

            return rule;
          }),
        },
      };
    }

    return { ...query, filter: normalizedFilter };
  };

  findRankName = (ranks = [], rankName) =>
    find(ranks, rank => rank.name === rankName) || -1;

  getValue = key => {
    const { data, parentKey, parentIndex = 0 } = this.props;

    return !!parentKey ? data[parentKey][parentIndex][key] : data[key];
  };

  onSearch = currentValue =>
    debounce(
      (value = '', byId = false, onComplete) => {
        const {
          settings: { formName, agencyId } = {},
          enumRef = '',
          parentIndex,
          dataKey,
          parentKey,
          data: { submitterId = '' } = {},
          overrides = {},
          combinedEnumRef,
        } = this.props;
        const fieldOverrides = getOverrides(this.props, overrides);
        const options = fieldOverrides('options', {});

        const {
          filter = {},
          multiselect: canSelectMultiple,
          useRanks,
          onlyTrainees = false,
          source = '',
        } = options;

        const populateFromRepeater = source == 'repeating';
        const compoundEnumRef = !!parentKey
          ? `${enumRef}-${parentIndex}-${dataKey}`
          : enumRef;

        const next = (err, response) => {
          onComplete &&
            typeof onComplete === 'function' &&
            onComplete(response);
        };

        if (
          ['vehiclePursuitTrackingNumber', 'useOfForceTrackingNumber'].includes(
            dataKey
          )
        ) {
          const defaultValue = this.getValue(dataKey);
          const searchByFilter = byId
            ? [
                {
                  column: 'id',
                  operator: 'eq',
                  value,
                },
              ]
            : [
                {
                  column: 'number',
                  operator: 'ilike',
                  value: encodeURIComponent(`%${value}%`),
                },
              ];

          const templateType = getSelectedTemplateType();
          const templatesTypeLinked = getFormLinkConfiguration({
            scope: templateType,
          });
          const templatesName = getTemplatesNames(templatesTypeLinked);

          formActionDispatcher(
            formName,
            getAllDraftReports(
              compoundEnumRef,
              searchByFilter,
              submitterId,
              defaultValue,
              templatesName,
              next
            )
          );
        } else if (!populateFromRepeater) {
          const filterById = value
            ? [
                {
                  id:
                    canSelectMultiple && Array.isArray(value)
                      ? { inq: value }
                      : !isEmpty(value)
                      ? value
                      : undefined,
                },
                { sworn: get(filter, ['where', 'sworn'], true) },
              ]
            : [];

          const searchByFilter = byId
            ? filterById
            : [
                {
                  fullName: {
                    ilike: encodeURIComponent(`%${value}%`),
                  },
                },
                {
                  starNumber: {
                    ilike: encodeURIComponent(`%${value}%`),
                  },
                },
                {
                  employeeId: {
                    ilike: encodeURIComponent(`%${value}%`),
                  },
                },
                {
                  id:
                    canSelectMultiple && Array.isArray(currentValue)
                      ? { inq: currentValue }
                      : currentValue,
                },
              ];

          const whereFilter = {
            ...get(filter, ['where'], {}),
            or: searchByFilter.length > 0 ? searchByFilter : undefined,
          };

          const payload = {
            enumRef: compoundEnumRef,
            filter: {
              ...options.filter,
              fullName: value,
              where: this.setUpFilterWhereClause(whereFilter),
              include: 'rank',
            },
            options,
            formName,
          };

          this.getRanksIfNeeded().then(data => {
            formActionDispatcher(
              formName,
              getOfficersAndCivilians(
                normalizeFilter(
                  this.props,
                  this.replaceRanks(useRanks, data, payload),
                  combinedEnumRef,
                  agencyId
                ),
                next,
                onlyTrainees
              )
            );
          });
        }
      },
      900,
      {
        trailing: true,
      }
    );

  onSelectVehiclePursuitTrackingNumber = (value, cb = () => {}) => {
    const {
      data: { meta: currentReport = {} } = {},
      options: { source = '', resetRemoteKeys = [], linkableToType } = {},
      parentKey,
      enumRef = '',
      dataKey = '',
      parentIndex = 0,
      settings,
    } = this.props;
    const compoundEnumRef = !!parentKey
      ? `${enumRef}-${parentIndex}-${dataKey}`
      : enumRef;

    const createFormLink = (
      currentReportId,
      targetReportId,
      newReport = null
    ) => {
      formActionDispatcher(
        settings.formName,
        postFormLink(
          {
            currentReportId,
            targetReportId,
            newReport,
          },
          (err, formLink) => {
            if (!err) {
              if (newReport) {
                formActionDispatcher(
                  settings.formName,
                  addSource({
                    enumRef: compoundEnumRef,
                    sourceKey: source.startsWith('__') ? source : `__${source}`,
                    enumItem: {
                      value: newReport.id,
                      label: newReport.formNumber,
                      data: newReport,
                    },
                    sourceItem: newReport,
                  })
                );
                cb(targetReportId + []);
              } else {
                cb();
              }
              emitFormEvent('onSaveDraft', settings.formName);
            }
          }
        )
      );
    };

    const createEmptyDraftReport = (
      templateId,
      currentReport,
      data,
      dataLinked
    ) => {
      formActionDispatcher(
        settings.formName,
        postEmptyDraftReport(
          {
            templateId,
            data: { ...data, ...dataLinked },
          },
          (error, report) => {
            if (!error) {
              createFormLink(currentReport.id, report.id, report);
            }
          }
        )
      );
    };

    const linkingReports = (currentReport = {}, targetReportId) => {
      const { data = {} } = this.props;
      const {
        currentUserId,
        meta: { submitterId = '' },
      } = data;

      //user decide to create a new form
      if (targetReportId.includes('create-new')) {
        const templateKey = targetReportId.replace('create-new-', '');
        const dataLinked = { submitterId, currentUserId };

        if (linkableToType === 'use-of-force') {
          dataLinked['useOfForceTrackingNumber'] = currentReport.id + [];
          dataLinked['isUseOfForceInvolved'] = true;
        } else {
          dataLinked['vehiclePursuitTrackingNumber'] = currentReport.id + [];
          dataLinked['isVehiclePursuitInvolved'] = true;
        }

        dataLinked['__reports'] = {
          [currentReport.id]: omit(currentReport, [
            'data',
            'participants',
            'participantsId',
            'workFlowData',
            'workflowId',
          ]),
        };

        const {
          templates: { [templateKey]: { id: templateId = '' } = {} } = {},
        } = this.props;
        const remoteFormId = data[dataKey];

        if (remoteFormId) {
          formActionDispatcher(
            settings.formName,
            deleteFormLink({ formId: remoteFormId, resetRemoteKeys }, () => {
              createEmptyDraftReport(
                templateId,
                currentReport,
                data,
                dataLinked
              );
            })
          );
        } else {
          createEmptyDraftReport(templateId, currentReport, data, dataLinked);
        }
      } else {
        //is an existent draft
        const remoteFormId = data[dataKey];
        //there was already a form linked
        if (remoteFormId) {
          formActionDispatcher(
            settings.formName,
            deleteFormLink({ formId: remoteFormId, resetRemoteKeys }, () => {
              createFormLink(currentReport.id, targetReportId);
            })
          );
        } else {
          createFormLink(currentReport.id, targetReportId);
        }
      }
    };

    if (value) {
      if (currentReport.id) {
        linkingReports(currentReport, value);
      } else {
        emitFormEvent('onSaveDraft', settings.formName, (err, draftReport) => {
          if (!err) {
            linkingReports(draftReport, value);
          }
        });
      }
    } else {
      cb();
    }
  };

  onSelectUseOfForceTrackingNumber = (value, cb = () => {}) =>
    this.onSelectVehiclePursuitTrackingNumber(value, cb);

  onAutocompleteSelect = (currentvalue = '') => (value, cb = () => {}) => {
    const { dataKey } = this.props;

    // If the newly selected value is the same as the last, do nothing
    if (currentvalue === value) {
      return false;
    }
    /**
     * If you need a custom onSelect for any autocomplete, please
     * add another condition that matches with the dataKey that
     * you need, doing this it will be more readable having different
     * behaviors based on the field, otherwise the default onSelect will
     * be called.
     */
    switch (dataKey) {
      case 'vehiclePursuitTrackingNumber':
        this.onSelectVehiclePursuitTrackingNumber(value, cb);
        break;
      case 'useOfForceTrackingNumber':
        this.onSelectUseOfForceTrackingNumber(value, cb);
        break;
      default:
        cb();
        break;
    }
  };

  resetKeys = options => {
    const { form = formInterface } = this.props;
    const { resetKeys = [] } = options;

    if (resetKeys.length) {
      const { resetFields } = form;
      resetFields(resetKeys);
    }
  };

  removeKeys = options => {
    const { data = {}, settings: { formName } = {} } = this.props;
    const { removeKeys = [] } = options;

    if (removeKeys.length) {
      removeKeys.forEach(item => {
        const { key = '', parentKey = '', source, batch = false } = item;
        const value = get(data, batch ? parentKey : key, '');
        if (isArray(value)) {
          value.forEach(obj => {
            formActionDispatcher(
              formName,
              removeSourceSelection(obj[key], source)
            );
          });
        } else {
          formActionDispatcher(formName, removeSourceSelection(value, source));
        }
      });
    }
  };

  linkableToType = (options, value) => {
    const {
      data = {},
      dataKey = '',
      form = formInterface,
      settings: { formName } = {},
    } = this.props;
    const { resetRemoteKeys = [], linkableToType = null } = options;
    const { setFieldsValue, getFieldInstance } = form;

    if (linkableToType) {
      const remoteFormId = data[dataKey];

      if (!value && remoteFormId) {
        if (getFieldInstance(dataKey)) setFieldsValue({ [dataKey]: null });
        formActionDispatcher(
          formName,
          deleteFormLink({ formId: remoteFormId, resetRemoteKeys }, () => {
            emitFormEvent('onSaveDraft', formName);
          })
        );
      }
    }
  };

  onlyTrainees = (options, value) => {
    const { field_type: type = '', settings: { formName } = {} } = this.props;
    const { onlyTrainees = false } = options;

    if (onlyTrainees && value) {
      if (type === 'date') {
        const emptySearch = this.onSearch('');
        emptySearch();
      } else {
        formActionDispatcher(formName, fetchDORFORM(value, true));
      }
    }
  };

  resetSource = (options, value) => {
    const {
      data = {},
      dataKey = '',
      parentIndex = 0,
      parentKey = '',
      settings: { formName } = {},
    } = this.props;
    const { source = '', resetSource = false, removeRefKeys = [] } = options;
    const currentValue = get(
      data,
      parentKey ? [parentKey, parentIndex, dataKey] : dataKey,
      ''
    );

    if (resetSource && currentValue !== value && removeRefKeys.length) {
      formActionDispatcher(
        formName,
        removeSourceSelection(currentValue, source)
      );
      removeRefKeys.forEach(item => {
        const { source = '', fields = [] } = item;
        formActionDispatcher(
          formName,
          removeReferenceSelection(currentValue, source, fields)
        );
      });
    }
  };

  onChangeDropdowns = (options, value) => {
    const { field_type = '', settings: { formName } = {} } = this.props;

    const { source = '' } = options;

    if (dropdowns.includes(field_type) && source) {
      formActionDispatcher(
        formName,
        setSourceSelection(value, source, formName)
      );
    }
  };

  setFieldsOnChange = options => {
    const {
      parentIndex = 0,
      parentKey = '',
      settings: { formName } = {},
    } = this.props;

    const hasParent = !!parentKey;

    const { setFieldsOnChange = null } = options;

    if (setFieldsOnChange) {
      const fields = keys(
        isPlainObject(setFieldsOnChange) ? setFieldsOnChange : {}
      ).reduce((result, key) => {
        return {
          ...result,
          [hasParent ? `${parentKey}.${parentIndex}.${key}` : key]: get(
            setFieldsOnChange,
            [key],
            ''
          ),
        };
      }, {});
      formActionDispatcher(formName, setFormValues(fields, this.props));
    }
  };

  sourceField = (options, value) => {
    const {
      dataKey = '',
      parentIndex = 0,
      parentKey = '',
      settings: { formName } = {},
    } = this.props;
    const { sourceField = '' } = options;

    if (!isEmpty(sourceField) && !sourceField.includes('none')) {
      const path = `${parentKey}[${parentIndex}].${dataKey}`;
      formActionDispatcher(
        formName,
        syncFormData({
          [path]: value,
        })
      );
    }
  };

  onChange = value => {
    const { overrides = {} } = this.props;
    const getFieldOverrides = getOverrides(this.props, overrides);
    const options = getFieldOverrides('options', {});

    this.resetKeys(options);

    this.removeKeys(options);

    this.linkableToType(options, value);

    this.onlyTrainees(options, value);

    this.resetSource(options, value);

    this.onChangeDropdowns(options, value);

    this.setFieldsOnChange(options);

    this.sourceField(options, value);
  };

  get fieldDecorator() {
    const { form = formInterface, isReviewList = false } = this.props;
    const { getFieldDecorator } = form;

    return isReviewList || typeof getFieldDecorator === 'undefined'
      ? reviewDecorator
      : getFieldDecorator;
  }

  renderField = () => {
    const {
      changedFields = {},
      field_type,
      isContributeReport,
      isContributorAssign,
      contributorAssignmentCanEdit,
      contributorAssignmentCanView,
      contributorAssignmentSource,
      contributorAssignmentIsComplete,
      contributorAssignmentIsAssigned,
    } = this.props;

    const fieldProps = getFieldProps(this.props, {
      onChange: this.onChange,
      onSearch: this.onSearch,
      onAutocompleteSelect: this.onAutocompleteSelect,
    });
    const fieldDataKey = getFieldDataKey(this.props);
    const isReadOnly = getIsReadOnly(this.props);
    const calculatedValue = getCalculatedValue(this.props);
    const dependsOnChange = getDependsOnChange(this.props);
    const fieldOptions = getFieldOptions(this.props, fieldDataKey);
    const reportingKey = this.props.reportingKey;
    const isDisabled = getFieldIsDisabled(this.props);
    const isOwnChange = fieldDataKey in changedFields;
    const props = Object.assign({}, fieldProps, {
      isReviewer: isReadOnly,
      isOwnChange,
      calculatedValue,
      dependsOnChange,
      dependsOn: this.props.dependsOn,
      disabled: isDisabled,
      field_type,
      dispatch: this.props.dispatch,
      reportingKey,
      isContributeReport,
      isContributorAssign,
      contributorAssignmentCanEdit,
      contributorAssignmentCanView,
      contributorAssignmentSource,
      contributorAssignmentIsComplete,
      contributorAssignmentIsAssigned,
    });

    return this.fieldDecorator(
      fieldDataKey,
      fieldOptions
    )(<FieldInput key={fieldDataKey} {...props} />);
  };

  isRequired = () => {
    const { field_type = '', validations, dataKey, parentKey } = this.props;

    let isRequired = false;
    if (parentKey) {
      const { mustExist = '', required = false } = get(
        validations,
        `rules.${parentKey}[0].item[0].fields.${dataKey}[0]`,
        {}
      );

      isRequired =
        (required || mustExist ? true : false) && field_type !== SWITCH;
    } else {
      const { mustExist = '', required = false } = get(
        validations,
        `rules[${dataKey}][0]`,
        {}
      );
      isRequired =
        (required || mustExist ? true : false) && field_type !== SWITCH;
    }
    return isRequired;
  };

  // information modal data
  showModal = (title, information) => () => {
    Modal.info({
      title: title,
      content: (
        <ContentEditable
          className="ql-editor"
          html={information}
          disabled={true}
          style={{ display: 'inline', lineHeigh: '40px', heigh: '40px' }}
        />
      ),
    });
  };

  interpolateLabelCallback = (options = {}) => text => {
    const { information, tooltipText } = options;
    const { tooltipTitle, tooltipSize = 'large', hasTooltip } = getTitleTooltip(
      this.props
    );

    return hasTooltip || tooltipText || information ? (
      <StyledSpan className="info-tooltip-wrapper info-tooltip-wrapper-formfield">
        <ContentEditable
          html={decorateTextWithHyperlink(text)}
          disabled={true}
        />{' '}
        {(hasTooltip || tooltipText) && (
          <Tooltip
            placement="rightTop"
            autoAdjustOverflow
            overlayClassName={`info-tooltip info-tooltip-${tooltipSize}`}
            title={tooltipText || tooltipTitle}
          >
            <Icon type="question-circle" />
          </Tooltip>
        )}{' '}
        {information && (
          <Icon
            type="info-circle"
            onClick={this.showModal(text, information)}
          />
        )}
      </StyledSpan>
    ) : (
      <StyledSpan>
        <ContentEditable
          html={decorateTextWithHyperlink(text)}
          disabled={true}
          className="field-label-format"
        />
      </StyledSpan>
    );
  };

  getLabelText = options => {
    const interpolateLabel = getInterpolatedLabel(
      this.props,
      this.interpolateLabelCallback(options)
    );

    return isObject(interpolateLabel)
      ? interpolateLabel
      : trim(interpolateLabel);
  };

  getLabel = (labelText, options = {}) => {
    const { labelComponent: LabelComponent } = this.props;
    const { showTitle = true } = options;
    const isRequired = this.isRequired();
    const fieldProps = getFieldProps(this.props, {
      onChange: this.onChange,
      onSearch: this.onSearch,
      onAutocompleteSelect: this.onAutocompleteSelect,
    });

    return showTitle ? (
      <Fragment>
        <LabelComponent className="required" fieldProps={fieldProps}>
          {labelText}
        </LabelComponent>
        {isRequired && <span className="required">*</span>}
      </Fragment>
    ) : null;
  };

  render() {
    const { extra = '', field_type = '', overrides = {}, form } = this.props;
    const { getFieldError, isFieldTouched } = Object.assign(
      {},
      formInterface,
      form
    );
    const getFieldOverrides = getOverrides(this.props, overrides);
    const options = getFieldOverrides('options', {});
    const { className } = options;
    const formItemLayout = getFormItemLayout(this.props);
    const fieldDataKey = getFieldDataKey(this.props);
    const isReadOnly = getIsReadOnly(this.props);
    const labelText = this.getLabelText(options);
    const label = this.getLabel(labelText, options);
    const contributor = this.props.contributorAssignmentCanEdit || false;
    // TODO: fix formItemLayout
    let itemLayout = {};
    const formItemClassName = [`ant-form-item-${field_type}`, 'bdm-form-item'];
    // INFO: When user is editing a section, they need to see the layout as edit mode
    formItemClassName.push(
      isReadOnly && !contributor ? 'bdm-readonly-mode' : 'bdm-edit-mode'
    );
    if (!isMobile) {
      formItemClassName.push(className ? className : '');
      itemLayout = formItemLayout;
    }

    return (
      <FormItem
        key={`${fieldDataKey}.wrapper`}
        colon={false}
        label={label}
        labelText={labelText}
        extra={extra}
        validationStatus={
          isFieldTouched(fieldDataKey) && getFieldError(fieldDataKey)
            ? 'error'
            : ''
        }
        className={formItemClassName.join(' ')}
        {...itemLayout}
      >
        {this.renderField()}
      </FormItem>
    );
  }

  setUpFilterWhereClause(whereFilter) {
    return Object.keys(whereFilter).length > 0 ? whereFilter : undefined;
  }
}

export default Field;
