import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { kebabCase, get, isEmpty, chain, debounce } from 'lodash';
import moment from 'moment-timezone';
import { Badge, Col, DatePicker, Form, Input, Select, Tooltip } from 'antd';

import { FORM_TEMPLATE_IDENTIFIERS } from 'APP_ROOT/constants/form-types';
import { hasPermissions } from 'APP_ROOT/utils/admin';
import getFormTemplate from '../../../actions/get-form';
import AdvanceFilter from './advance-filter';
import FilterStatus from './filters/status';
import FilterForceApplied from './filters/force-applied';
import FilterAllegationsType from './filters/allegation-filter';
import {
  reportViews,
  VIEW_OPTION_MY_WORKLOAD,
  VIEW_OPTION_ADMIN_ALL,
} from './filters/reportViews.constants';

import getDateRangeFilter from './utils/getDateRangeFilter';
import getTypeFilter from './utils/getTypeFilter';
import getStatusFilter from './utils/getStatusFilter';
import getForceFilter from './utils/getForceFilter';
import getAllegationFilter from './utils/getAllegationFilter';
import getMentionedUsersFilter from './utils/getMentionedUsersFilter';
import getReportSubmitterFilter from './utils/getReportSubmitterFilter';
import canApplyFilters from './utils/canApplyFilters';
import isBusinessViewsEnabled from './utils/isBusinessViewsEnabled';

import getOfficersAndCivilians from '../../../actions/get-officers-and-civilians';
import normalizeFilter from '../../form-viewer/object-types/FormField/normalizeFilter';

import {
  FILTER_SEARCH,
  FILTER_TYPE,
  FILTER_RANGE,
  FILTER_EVENT_DATE,
  FILTER_VIEW,
  FILTER_STATUS,
  FILTER_FORCE,
  FILTER_ALLEGATION,
  FILTER_LAYOUT_OPTIONS,
  FILTER_ARRAY_SEPARATOR,
  FILTER_MENTIONED_USERS,
  FILTER_REPORT_SUBMITTER,
} from './utils/constants';
import { Button } from 'antd';

import AdvanceFilterWrapper from './advance-filter.styled';
import {
  StyledRow,
  StyledRowAdvance,
  StyledFilterCol,
} from './form-filter.styled';
import { URL_SEARCH_PARAM_PAGE } from '../../../containers/reports/utils/constants';
import { BLUE } from '../../../config/theme';

const Option = Select.Option;
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Item } = Form;

const USER_LIST = 'userList';

let formTypes = {};
let allStatuses = [];

const defaultFilters = [];

const templateFilters = {
  [FORM_TEMPLATE_IDENTIFIERS.UOF]: [
    ...defaultFilters,
    {
      label: 'Officer Force Applied',
      component: FilterForceApplied,
      filterType: FILTER_FORCE,
    },
  ],
  [FORM_TEMPLATE_IDENTIFIERS.IAI]: [
    ...defaultFilters,
    {
      label: 'Allegation Type',
      component: FilterAllegationsType,
      filterType: FILTER_ALLEGATION,
    },
  ],
  [FORM_TEMPLATE_IDENTIFIERS.IAC]: [
    {
      label: 'Allegation Type',
      component: FilterAllegationsType,
      filterType: FILTER_ALLEGATION,
    },
  ],
};

function getTemplateFilters(templateKey) {
  const keys = Object.keys(templateFilters);
  for (let key in keys) {
    if (templateKey.includes(keys[key])) {
      return templateFilters[keys[key]];
    } else {
      return defaultFilters;
    }
  }
}

function createFormTypesFilters(templates) {
  return Object.keys(templates).map(templateKey => {
    const label = get(templates, [templateKey, 'label'], '');
    const type = get(templates, [templateKey, 'type'], '');
    formTypes = {
      ...formTypes,
      [type]: {
        templateKey,
        title: label,
        filters: getTemplateFilters(templateKey),
      },
    };
  });
}

function getAllStatuses(templates) {
  allStatuses = chain(templates)
    .flatMap(template => get(template, 'statuses', []))
    .uniqBy('id')
    .value();
}

export function formTypesFromTemplates(templates) {
  const types = Object.keys(templates).map(templateKey => ({
    value: templates[templateKey].type,
    label: templates[templateKey].label,
  }));
  types.unshift({
    value: '',
    label: 'View All',
  });
  return types.map((item, index) => (
    <Option key={index} value={item.value}>
      {item.label}
    </Option>
  ));
}

const getPopupContainer = trigger => trigger.parentNode;

const PRE_FILTERS_DEFAULT = {
  [FILTER_TYPE]: '',
  [FILTER_RANGE]: '',
  [FILTER_EVENT_DATE]: '',
  [FILTER_STATUS]: [],
  [FILTER_FORCE]: [],
  [FILTER_ALLEGATION]: [],
  [FILTER_MENTIONED_USERS]: [],
  [FILTER_REPORT_SUBMITTER]: [],
};

class FormFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: false,
      checkQueryString: true,
      applyDefault: false,
      filters: {
        [FILTER_VIEW]: '',
        [FILTER_SEARCH]: '',
        ...PRE_FILTERS_DEFAULT,
      },
      preFilters: {
        ...PRE_FILTERS_DEFAULT,
      },
      filtersVisible: false,
      users: [],
    };
    this.searchInput = null;
    this.onSearchUsers = debounce(this.onSearchUsers, 800);
  }

  componentDidMount() {
    this.setState({ applyDefault: true });
    window.addEventListener('popstate', this.popState);
    this.onSearchUsers();
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.popState);
  }

  static getDerivedStateFromProps(props, state) {
    const { userList } = props;
    const { users } = state;
    if (isEmpty(users)) {
      return { users: userList };
    }
    return { users: FormFilter.getUsers(users, userList) };
  }

  static getUsers = (users, userList) => {
    const newList = userList.reduce((list, user) => {
      if (users.find(u => u.data.id === user.data.id) === undefined) {
        list.push(user);
      }
      return list;
    }, []);
    return users.concat(newList);
  };

  popState = event => {
    this.setState({ applyDefault: true });
    this.evaluateQueryString(true);
  };

  onSearchUsers = (value = '', onComplete) => {
    const { dispatch, agencyId } = this.props;
    const { preFilters } = this.state;
    // currentValue should be in the state
    const currentValue = [
      ...preFilters[FILTER_MENTIONED_USERS],
      ...preFilters[FILTER_REPORT_SUBMITTER],
    ];
    const options = {
      filter: { where: {} },
      source: 'users',
      orCriteria: ['starNumber'],
    };
    const next = (err, response) => {
      onComplete && typeof onComplete === 'function' && onComplete(response);
    };

    const ilike = encodeURIComponent(`%${value}%`);
    const searchByFilter = [
      {
        fullName: { ilike },
      },
      {
        starNumber: { ilike },
      },
      {
        employeeId: { ilike },
      },
      {
        id: { inq: currentValue },
      },
    ];

    const whereFilter = {
      or: searchByFilter,
    };

    const payload = {
      filter: {
        fullName: value,
        where: whereFilter,
        include: 'rank',
      },
      options,
      enumRef: USER_LIST,
    };

    dispatch(
      getOfficersAndCivilians(
        normalizeFilter(this.props, payload, undefined, agencyId),
        next,
        false // onlyTrainees
      )
    );
  };

  setFilters = (filter, value) => {
    const { filters } = this.state;
    let newFilters = { ...filters, [filter]: value };
    this.setState({ filters: newFilters }, () => this.rollbackPreFilters(true));
  };

  setPreFilters = (filter, value) => {
    const { preFilters } = this.state;
    let newPreFilters = { ...preFilters, [filter]: value };
    if (preFilters[FILTER_TYPE] !== newPreFilters[FILTER_TYPE]) {
      newPreFilters[FILTER_FORCE] = [];
      newPreFilters[FILTER_ALLEGATION] = [];
    }

    this.setState({ preFilters: newPreFilters });
  };

  applyFilters = () => {
    const { filters, preFilters } = this.state;
    const { history } = this.props;
    const {
      location: { pathname },
    } = history;
    const newFilters = { ...filters, ...preFilters };
    const query = Object.keys(newFilters).reduce((q, key) => {
      if (!isEmpty(newFilters[key])) {
        const filterData = Array.isArray(newFilters[key])
          ? newFilters[key].join(FILTER_ARRAY_SEPARATOR)
          : newFilters[key];
        q.push(`${key}=${filterData}`);
      }
      return q;
    }, []);
    const path = [pathname, query.join('&')].join('?');

    this.setState({ checkQueryString: true, filtersVisible: false });
    history.push(path);
  };

  rollbackPreFilters = (apply = false) => {
    const { filters, preFilters } = this.state;
    const newPreFilters = Object.keys(preFilters).reduce((f, key) => {
      f[key] = filters[key];
      return f;
    }, {});
    this.setState(
      { preFilters: newPreFilters },
      () => apply && this.applyFilters()
    );
  };

  resetPreFilters = () => {
    this.setState(
      {
        preFilters: PRE_FILTERS_DEFAULT,
      },
      this.applyFilters
    );
  };

  getDefaultView = params => {
    const { permissions } = this.props;
    const { applyDefault } = this.state;

    // no need to apply default, this is not the first iteraction
    if (!applyDefault) return '';

    // first iteraction, we need to turn off the flag
    this.setState({ applyDefault: false });

    // default view applies only when no other parameter available
    // only page param is allowed
    let paramKeys = [];
    for (const key of params.keys()) {
      paramKeys.push[key];
    }
    const len = paramKeys.length;
    const notPageParam =
      len === 1 && !paramKeys.includes(URL_SEARCH_PARAM_PAGE);
    if (len > 1 || notPageParam) return '';

    const adminAll = reportViews.find(r => r.value === VIEW_OPTION_ADMIN_ALL);
    return hasPermissions(permissions, adminAll.permissions)
      ? VIEW_OPTION_ADMIN_ALL
      : VIEW_OPTION_MY_WORKLOAD;
  };

  getSplitValue = (control, value, defaultValue = []) => {
    const _value = value === undefined ? control : value;
    return isEmpty(control)
      ? defaultValue
      : _value.split(FILTER_ARRAY_SEPARATOR);
  };

  processParams = ({
    view,
    search,
    valueType,
    dateStrings,
    eventDateStrings,
    statusValues,
    forceValue,
    allegationValues,
    mentionedUsersValues,
    reportSubmitterValues,
  }) => {
    const { timezone } = this.props;
    const type = getTypeFilter(valueType, formTypes);
    const range = getDateRangeFilter(dateStrings, timezone, FILTER_RANGE);
    const eventDate = getDateRangeFilter(
      eventDateStrings,
      timezone,
      FILTER_EVENT_DATE
    );
    const status = getStatusFilter(statusValues);
    const force = getForceFilter(forceValue);
    const allegation = getAllegationFilter(allegationValues);
    const mentionedUsers = getMentionedUsersFilter(mentionedUsersValues);
    const reportSubmitter = getReportSubmitterFilter(reportSubmitterValues);
    const filters = {
      [FILTER_TYPE]: valueType,
      [FILTER_RANGE]: this.getSplitValue(range, dateStrings, ''),
      [FILTER_EVENT_DATE]: this.getSplitValue(eventDate, eventDateStrings, ''),
      [FILTER_STATUS]: this.getSplitValue(statusValues),
      [FILTER_FORCE]: this.getSplitValue(forceValue),
      [FILTER_ALLEGATION]: this.getSplitValue(allegationValues),
      [FILTER_MENTIONED_USERS]: this.getSplitValue(mentionedUsersValues),
      [FILTER_REPORT_SUBMITTER]: this.getSplitValue(reportSubmitterValues),
    };

    this.setState({
      checkQueryString: false,
      filters: {
        ...filters,
        [FILTER_SEARCH]: search,
        [FILTER_VIEW]: view,
      },
      preFilters: filters,
    });

    return {
      [FILTER_SEARCH]: search,
      [FILTER_TYPE]: type,
      [FILTER_VIEW]: view,
      [FILTER_RANGE]: range,
      [FILTER_EVENT_DATE]: eventDate,
      [FILTER_STATUS]: status,
      [FILTER_FORCE]: force,
      [FILTER_ALLEGATION]: allegation,
      [FILTER_MENTIONED_USERS]: mentionedUsers,
      [FILTER_REPORT_SUBMITTER]: reportSubmitter,
    };
  };

  getParamFilter = (params, filterType, defaultValue = '') =>
    params.get(filterType) || defaultValue;

  getQueryParams = () => {
    const {
      history: { location },
    } = this.props;
    const params = new URLSearchParams(location.search);
    const defaultView = this.getDefaultView(params);
    // save filter params
    const search = this.getParamFilter(params, FILTER_SEARCH);
    const valueType = this.getParamFilter(params, FILTER_TYPE);
    const dateStrings = this.getParamFilter(params, FILTER_RANGE);
    const eventDateStrings = this.getParamFilter(params, FILTER_EVENT_DATE);
    const view = this.getParamFilter(params, FILTER_VIEW, defaultView);
    const statusValues = this.getParamFilter(params, FILTER_STATUS);
    const forceValue = this.getParamFilter(params, FILTER_FORCE);
    const allegationValues = this.getParamFilter(params, FILTER_ALLEGATION);
    const mentionedUsersValues = this.getParamFilter(
      params,
      FILTER_MENTIONED_USERS
    );
    const reportSubmitterValues = this.getParamFilter(
      params,
      FILTER_REPORT_SUBMITTER
    );

    return this.processParams({
      view,
      search,
      valueType,
      dateStrings,
      eventDateStrings,
      statusValues,
      forceValue,
      allegationValues,
      mentionedUsersValues,
      reportSubmitterValues,
    });
  };

  canCleanDataFilters = filters => {
    const { filters: oldFilters } = this.state;

    return (
      !isEmpty(filters[FILTER_TYPE]) &&
      filters[FILTER_TYPE][0].type !== oldFilters[FILTER_TYPE] &&
      isEmpty(filters[FILTER_FORCE]) &&
      isEmpty(filters[FILTER_ALLEGATION])
    );
  };

  getMainFilter = filters =>
    filters[FILTER_TYPE].concat(filters[FILTER_STATUS]);

  getDataFilter = filters =>
    filters[FILTER_RANGE].concat(filters[FILTER_FORCE])
      .concat(filters[FILTER_ALLEGATION])
      .concat(filters[FILTER_EVENT_DATE])
      .concat(filters[FILTER_MENTIONED_USERS])
      .concat(filters[FILTER_REPORT_SUBMITTER]);

  evaluateQueryString = async (forceCheck = false) => {
    const { checkQueryString, filters: oldFilters } = this.state;
    const mustCheck = checkQueryString || forceCheck;
    if (mustCheck) {
      const {
        clearFilterColumn,
        onChangeFilter,
        action,
        agencyId,
      } = this.props;
      this.onSearchUsers();
      const filters = this.getQueryParams();

      setTimeout(() => {
        if (canApplyFilters(filters, oldFilters, clearFilterColumn)) {
          const clearDataFilters = this.canCleanDataFilters(filters);

          onChangeFilter(
            this.getMainFilter(filters),
            this.getDataFilter(filters),
            filters[FILTER_SEARCH],
            clearDataFilters,
            filters[FILTER_VIEW]
          );
          if (clearDataFilters) {
            this.setState({
              selectedTemplate: true,
            });
            action(
              getFormTemplate({
                templateType: kebabCase(filters[FILTER_TYPE][0].type),
                agencyId,
              })
            );
          }
        }
      }, 200);
    }
  };

  onDatePickerChange = filterType => (dates, dateStrings) => {
    const { timezone } = this.props;
    const dateFrom = moment.tz(dateStrings[0], timezone).format();
    const dateTo = moment.tz(dateStrings[1], timezone).format();
    const range = dates.length ? [dateFrom, dateTo] : [];

    this.setPreFilters(filterType, range);
  };

  disabledDate = current => current && current.valueOf() > Date.now();

  onFormTypeChange = (value = '') => {
    const { clearFilterColumn } = this.props;
    const { selectedTemplate } = this.state;

    // Clear button has been pressed
    if (selectedTemplate && !value) {
      this.setState({
        selectedTemplate: false,
      });
      clearFilterColumn('mainFilter', ['formType', 'status']);
    }
  };

  onFormTypeSelect = (value = '') => {
    const { action, agencyId } = this.props;

    this.setPreFilters(FILTER_TYPE, value);
    if (value) {
      action(
        getFormTemplate({
          templateType: kebabCase(value),
          agencyId,
        })
      );
    }
  };

  onSearchSubmit = searchValue => {
    this.setFilters(FILTER_SEARCH, searchValue);
  };

  onSearchChange = value => {
    const {
      target: { value: searchValue = '' },
    } = value;

    const { updateOnlyStateFilters } = this.props;
    const { filters } = this.state;

    if (updateOnlyStateFilters) {
      updateOnlyStateFilters({ keywords: searchValue });
    }

    this.setState({
      filters: {
        ...filters,
        [FILTER_SEARCH]: searchValue,
      },
    });
  };

  componentDidUpdate(prevProps) {
    const { formTemplates } = this.props;
    if (formTemplates !== prevProps.formTemplates) {
      createFormTypesFilters(formTemplates);
      getAllStatuses(formTemplates);
    }
    this.evaluateQueryString();
  }

  getListOfViewsBasedOnUserPermissions = () => {
    const { permissions } = this.props;
    const viewsAssignedToShow = reportViews.map(view => {
      if (isEmpty(view.permissions)) return { ...view, visible: true };
      const isCurrentViewVisible = hasPermissions(
        permissions,
        view.permissions
      );
      return { ...view, visible: isCurrentViewVisible };
    });
    return viewsAssignedToShow;
  };

  getViews = () => {
    const { Option } = Select;
    return this.getListOfViewsBasedOnUserPermissions()
      .filter(view => view.visible === true)
      .map(({ title, value }) => {
        return (
          <Option key={value} value={value}>
            {title}
          </Option>
        );
      });
  };

  onViewSelectChange = value => {
    this.setFilters(FILTER_VIEW, value);
  };

  renderViewFilter = () => {
    const { loadingReports } = this.props;
    const { filters } = this.state;
    const isViewsFilterEnable = isBusinessViewsEnabled(this.props);

    return isViewsFilterEnable ? (
      <Select
        placeholder="Views"
        style={{ width: '100%' }}
        onChange={this.onViewSelectChange}
        disabled={loadingReports}
        value={filters[FILTER_VIEW]}
      >
        {this.getViews()}
      </Select>
    ) : (
      <Fragment />
    );
  };

  renderStatusFilter = () => {
    const { loadingReports } = this.props;
    const { preFilters } = this.state;
    const template = { statuses: allStatuses };
    return (
      <Item label="Report Status" {...FILTER_LAYOUT_OPTIONS}>
        <FilterStatus
          label="Status"
          size="default"
          className="full-width"
          template={template}
          disabled={loadingReports}
          setFilters={this.setPreFilters}
          filterType={FILTER_STATUS}
          filterValues={preFilters}
          getPopupContainer={getPopupContainer}
        />
      </Item>
    );
  };

  renderReportTypeFilter = () => {
    const { formTemplates, loadingReports } = this.props;
    const { preFilters } = this.state;

    return (
      <Item label="Report Type" {...FILTER_LAYOUT_OPTIONS}>
        <Select
          optionFilterProp="children"
          style={{ width: '100%' }}
          placeholder="Type"
          size="default"
          onChange={this.onFormTypeSelect}
          showSearch={true}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          disabled={loadingReports}
          value={preFilters[FILTER_TYPE]}
          getPopupContainer={getPopupContainer}
        >
          {formTypesFromTemplates(formTemplates)}
        </Select>
      </Item>
    );
  };

  renderEventDateFilter = () => {
    const { loadingReports } = this.props;

    return (
      <Item label="Event Date" {...FILTER_LAYOUT_OPTIONS}>
        <RangePicker
          onChange={this.onDatePickerChange(FILTER_EVENT_DATE)}
          size="default"
          className="full-width"
          disabled={loadingReports}
          value={this.getDateRangeValue(FILTER_EVENT_DATE)}
          getCalendarContainer={getPopupContainer}
        />
      </Item>
    );
  };

  renderSubmittedDateFilter = () => {
    const { loadingReports } = this.props;

    return (
      <Item label="Submitted Date" {...FILTER_LAYOUT_OPTIONS}>
        <RangePicker
          onChange={this.onDatePickerChange(FILTER_RANGE)}
          disabledDate={this.disabledDate}
          size="default"
          className="full-width"
          disabled={loadingReports}
          value={this.getDateRangeValue(FILTER_RANGE)}
          getCalendarContainer={getPopupContainer}
        />
      </Item>
    );
  };

  onMentionedUsersChange = value => {
    this.setPreFilters(FILTER_MENTIONED_USERS, value);
  };

  onReportSubmitterChange = value => {
    this.setPreFilters(FILTER_REPORT_SUBMITTER, value);
  };

  filterOption = (inputValue = '', option) => {
    const {
      benchmarkId = '',
      employeeId = '',
      starNumber = '',
      children = '',
    } = option.props;
    const lowerCaseValue = inputValue.toLowerCase();
    return (
      (children != null &&
        children.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
      (benchmarkId !== null &&
        benchmarkId.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
      (employeeId !== null &&
        employeeId.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
      (starNumber !== null &&
        starNumber.toLowerCase().indexOf(lowerCaseValue) >= 0)
    );
  };

  renderMentionedUsersFilter = () => {
    const { loadingReports } = this.props;
    const { preFilters, users } = this.state;

    return (
      <Item label="Mentioned Users" {...FILTER_LAYOUT_OPTIONS}>
        <Select
          onChange={this.onMentionedUsersChange}
          onSearch={keyworkds => this.onSearchUsers(keyworkds)}
          filterOption={this.filterOption}
          allowClear={true}
          placeholder="Mentioned Users"
          size="default"
          className="full-width"
          mode="multiple"
          disabled={loadingReports}
          value={preFilters[FILTER_MENTIONED_USERS]}
          getPopupContainer={getPopupContainer}
        >
          {users.map(d => (
            <Option
              key={d.value}
              starNumber={d.data.starNumber}
              employeeId={d.data.employeeId}
              benchmarkId={d.data.benchmarkId}
            >
              {d.label}
            </Option>
          ))}
        </Select>
      </Item>
    );
  };

  renderReportSubmitterFilter = () => {
    const { loadingReports } = this.props;
    const { preFilters, users } = this.state;

    return (
      <Item label="Report Submitter" {...FILTER_LAYOUT_OPTIONS}>
        <Select
          onChange={this.onReportSubmitterChange}
          onSearch={keyworkds => this.onSearchUsers(keyworkds)}
          filterOption={this.filterOption}
          allowClear={true}
          placeholder="Report Submitter"
          size="default"
          className="full-width"
          mode="multiple"
          disabled={loadingReports}
          value={preFilters[FILTER_REPORT_SUBMITTER]}
          getPopupContainer={getPopupContainer}
        >
          {users.map(d => (
            <Option
              key={d.value}
              starNumber={d.data.starNumber}
              employeeId={d.data.employeeId}
              benchmarkId={d.data.benchmarkId}
            >
              {d.label}
            </Option>
          ))}
        </Select>
      </Item>
    );
  };

  renderAdvanceFilters = () => {
    const { formTemplates, onChangeFilter, clearFilterColumn } = this.props;
    const { preFilters, filtersVisible } = this.state;
    const type = preFilters[FILTER_TYPE];
    const selectedFormType = formTypes[type];
    const selectedTemplate =
      formTemplates[kebabCase(type)] ||
      formTemplates[get(selectedFormType, 'templateKey')];

    return (
      filtersVisible && (
        <AdvanceFilterWrapper>
          <div className="scrollable">
            {this.renderReportTypeFilter()}
            {selectedFormType && (
              <AdvanceFilter
                formType={selectedFormType}
                template={selectedTemplate}
                onChangeFilter={onChangeFilter}
                clearFilterColumn={clearFilterColumn}
                setFilters={this.setPreFilters}
                filterValues={preFilters}
                getPopupContainer={getPopupContainer}
              />
            )}
            {this.renderStatusFilter()}
            {this.renderEventDateFilter()}
            {this.renderSubmittedDateFilter()}
            {this.renderMentionedUsersFilter()}
            {this.renderReportSubmitterFilter()}
          </div>
          <StyledRow>
            <Col span={5}>
              <Button
                type="danger"
                size="default"
                className="btn-reset"
                onClick={this.resetPreFilters}
              >
                Reset
              </Button>
            </Col>
            <Col span={5} offset={14}>
              <Button
                type="primary"
                size="default"
                className="btn-apply"
                onClick={this.applyFilters}
              >
                Apply
              </Button>
            </Col>
          </StyledRow>
        </AdvanceFilterWrapper>
      )
    );
  };

  getDateRangeValue = filterType => {
    const { timezone } = this.props;
    const { preFilters } = this.state;

    return isEmpty(preFilters[filterType])
      ? ''
      : preFilters[filterType].map(d => moment.tz(d, timezone));
  };

  onClickAdvancedFilters = () => {
    const { filtersVisible } = this.state;
    this.setState({
      filtersVisible: !filtersVisible,
    });
    filtersVisible && this.rollbackPreFilters();
  };

  renderSearch = () => {
    const { loadingReports } = this.props;
    const { filters } = this.state;

    return (
      <Tooltip placement="topLeft" title="Search by report name or case number">
        <Search
          placeholder="Search"
          style={{ width: '100%' }}
          size="default"
          onChange={this.onSearchChange}
          onSearch={this.onSearchSubmit}
          allowClear={true}
          value={filters[FILTER_SEARCH]}
          disabled={loadingReports}
          ref={input => {
            this.searchInput = input;
          }}
        />
      </Tooltip>
    );
  };

  getBadgeCount = () => {
    const { preFilters } = this.state;
    return Object.values(preFilters).reduce(
      (count, value) => (isEmpty(value) ? count : count + 1),
      0
    );
  };

  render() {
    const { filtersVisible } = this.state;
    const filtersIcon = filtersVisible ? 'minus' : 'plus';
    const count = this.getBadgeCount();
    const { loadingReports } = this.props;

    return (
      <Fragment>
        <StyledRow gutter={[10, 10]}>
          <StyledFilterCol xs={24} md={8}>
            {this.renderViewFilter()}
          </StyledFilterCol>
          <StyledFilterCol className="filter-search" xs={24} md={8}>
            {this.renderSearch()}
          </StyledFilterCol>
          <StyledFilterCol
            className="filter-advanced text-right"
            xs={24}
            md={8}
          >
            <Badge count={count} style={{ backgroundColor: BLUE }}>
              <Button
                icon={filtersIcon}
                size="default"
                disabled={loadingReports}
                onClick={this.onClickAdvancedFilters}
              >
                Advanced Filters
              </Button>
            </Badge>
          </StyledFilterCol>
        </StyledRow>
        <StyledRowAdvance justify="end">
          <StyledFilterCol xs={24}>
            {this.renderAdvanceFilters()}
          </StyledFilterCol>
        </StyledRowAdvance>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { session, supportedAgency } = state;
  const {
    currentUser: {
      permissions,
      agency: { id: agencyId },
    },
  } = session;
  const userList = get(state, `form.selectedForm.enums.${USER_LIST}`, []);
  const { isAgencyNewWorkFlowActive } = supportedAgency;
  return {
    permissions,
    isAgencyNewWorkFlowActive,
    agencyId,
    userList,
  };
};

export default connect(mapStateToProps)(FormFilter);
