export const CUSTOM_ADDRESS_LINE1 = 'address-line1';
export const CUSTOM_ADDRESS_LEVEL1 = 'address-level1';
export const CUSTOM_ADDRESS_LEVEL2 = 'address-level2';
export const CUSTOM_CITY_STATE_ZIP = 'custom-city-state-zip';
export const CUSTOM_COUNTY_CITY = 'custom-county-city';
export const CUSTOM_STREET = 'custom-street';
export const CUSTOM_POSTAL_CODE = 'postal-code';
export const CUSTOM_LATITUDE = 'latitud';
export const CUSTOM_LONGITUDE = 'longitude';

const CUSTOM_ADDRESS_LINE1_OPTION = {
  label: CUSTOM_ADDRESS_LINE1,
  terms: [{ exactMatch: false, term: 'address' }, 'address line 1'],
};

const CUSTOM_CITY_STATE_ZIP_OPTION = {
  label: CUSTOM_CITY_STATE_ZIP,
  terms: ['city, state, zip', 'city, state and zip'],
};

const CUSTOM_COUNTY_CITY_OPTION = {
  label: CUSTOM_COUNTY_CITY,
  terms: ['city county', 'city, county', 'city and county'],
};

const CUSTOM_STREET_OPTION = {
  label: CUSTOM_STREET,
  terms: [
    'block',
    'block number',
    { exactMatch: false, term: 'intersection' },
    { exactMatch: false, term: 'highway' },
    'landmark',
  ],
};

const CUSTOM_ADDRESS_LEVEL1_OPTION = {
  label: CUSTOM_ADDRESS_LEVEL1,
  terms: ['state'],
};

const CUSTOM_ADDRESS_LEVEL2_OPTION = {
  label: CUSTOM_ADDRESS_LEVEL2,
  terms: ['city'],
};

const CUSTOM_POSTAL_CODE_OPTION = {
  label: CUSTOM_POSTAL_CODE,
  terms: ['postal code', 'zip'],
};

const CUSTOM_LATITUDE_OPTION = {
  label: CUSTOM_LATITUDE,
  terms: ['latitude', 'x coordinate'],
};

const CUSTOM_LONGITUDE_OPTION = {
  label: CUSTOM_LONGITUDE,
  terms: ['longitude', 'y coordinate'],
};

export const CATEGORIES = [
  CUSTOM_ADDRESS_LINE1_OPTION,
  {
    label: 'address-line2',
    terms: ['address line 2', 'street', 'street name'],
  },
  {
    label: 'street-address',
    terms: ['street address'],
  },
  CUSTOM_COUNTY_CITY_OPTION,
  CUSTOM_CITY_STATE_ZIP_OPTION,
  CUSTOM_STREET_OPTION,
  CUSTOM_ADDRESS_LEVEL2_OPTION,
  CUSTOM_ADDRESS_LEVEL1_OPTION,
  CUSTOM_POSTAL_CODE_OPTION,
  CUSTOM_LATITUDE_OPTION,
  CUSTOM_LONGITUDE_OPTION,
];

export const CATEGORIES_MAP = [
  CUSTOM_ADDRESS_LINE1_OPTION,
  CUSTOM_STREET_OPTION,
];
