import React from 'react';
import {
  ATTRIBUTE_TYPE_BOOL,
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_LIST,
  ATTRIBUTE_TYPE_NUMBER,
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_TEXTAREA,
} from '../UserProfile.constants';
import { BENCHMARK_DATE_FORMAT } from '../../../../../utils/parse-date';
import StyledSwitch from '../../../../../components/form-viewer/styled/input-switch';
import { DatePicker, Input as InputField, InputNumber, Select } from 'antd';

const Option = Select.Option;

import './UserEquipmentField.css';

const DatePickerField = ({
  field,
  onChange,
  isValid,
  validationMessages,
  isDisabled,
}) => (
  <DatePicker
    format={BENCHMARK_DATE_FORMAT}
    onChange={(date, dateString) => onChange(date, field)}
    className={`${
      !isValid && validationMessages[field.id] ? 'highlight-error' : ''
    } full-width`}
    defaultValue={field.defaultValue}
    disabled={isDisabled}
  />
);

const InputNumberField = ({
  field,
  onChange,
  isValid,
  validationMessages,
  isDisabled,
}) => (
  <InputNumber
    onChange={value => onChange(value, field)}
    className={`${
      !isValid && validationMessages[field.id] ? 'highlight-error' : ''
    } full-width`}
    defaultValue={field.defaultValue}
    disabled={isDisabled}
  />
);

const StyledSwitchField = ({
  field,
  onChange,
  isValid,
  validationMessages,
  isDisabled,
}) => (
  <StyledSwitch
    checkedChildren="Yes"
    unCheckedChildren="No"
    onChange={value => onChange(value, field)}
    defaultChecked={field.defaultValue}
    disabled={isDisabled}
  />
);

const TextAreaField = ({
  field,
  onChange,
  isValid,
  validationMessages,
  isDisabled,
}) => (
  <InputField.TextArea
    onChange={event => onChange(event.target.value, field)}
    className={`${
      !isValid && validationMessages[field.id] ? 'highlight-error' : ''
    } full-width`}
    defaultValue={field.defaultValue}
    disabled={isDisabled}
  />
);

const InputFieldComponent = ({
  field,
  onChange,
  isValid,
  validationMessages,
  isDisabled,
}) => (
  <InputField
    onChange={event => onChange(event.target.value, field)}
    className={
      !isValid && validationMessages[field.id] ? 'highlight-error' : ''
    }
    defaultValue={field.defaultValue}
    disabled={isDisabled}
  />
);

const SelectField = ({
  field,
  onChange,
  isValid,
  validationMessages,
  isDisabled,
}) => (
  <Select
    showSearch
    placeholder="Select a value"
    optionFilterProp="children"
    disabled={!field.options || field.options.length === 0 || isDisabled}
    className={`${
      !isValid && validationMessages[field.id] ? 'highlight-error' : ''
    } full-width`}
    defaultValue={field.defaultValue || 'Select a value'}
    onChange={value => onChange(value, field)}
  >
    {field.options &&
      field.options.map(o => (
        <Option key={o.id} value={o.option}>
          {o.option}
        </Option>
      ))}
  </Select>
);

const FieldComponent = ({
  field, onChange, isValid, validationMessages, isDisabled,
}) => {
  switch (field.type) {
    case ATTRIBUTE_TYPE_DATE:
      return (
        <DatePickerField
          field={field}
          onChange={onChange}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={isDisabled}
        />
      );
    case ATTRIBUTE_TYPE_NUMBER:
      return (
        <InputNumberField
          field={field}
          onChange={onChange}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={isDisabled}
        />
      );
    case ATTRIBUTE_TYPE_BOOL:
      return (
        <StyledSwitchField
          field={field}
          onChange={onChange}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={isDisabled}
        />
      );
    case ATTRIBUTE_TYPE_TEXTAREA:
      return (
        <TextAreaField
          field={field}
          onChange={onChange}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={isDisabled}
        />
      );
    case ATTRIBUTE_TYPE_STRING:
      const disableExternalId = isExternalIdField(
        field,
        isDisabled
      );
      return (
        <InputFieldComponent
          field={field}
          onChange={onChange}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={disableExternalId}
        />
      );
    case ATTRIBUTE_TYPE_LIST:
      return (
        <SelectField
          field={field}
          onChange={onChange}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={isDisabled}
        />
      );
    default:
      return (
        <InputFieldComponent
          field={field}
          onChange={onChange}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={isDisabled}
        />
      );
  }
};

/**
 * Important to take into account that id value ('018b2f19-e79e-7d6a-a56d-29feb6211b04') is a static UUID value returned by backend for External ID field.
 * If this value changes in backend code, it must be changed in below function as well in order to avoid breaking this functionality.
 * @param {Object} field - The field object to check id.
 * @param {boolean} isDisabled - A flag indicating if the field is disabled based on other conditions.
 * @returns {boolean} - `true` if the field should be disabled based on its id and default value, otherwise the value of `isDisabled`.
 */
const isExternalIdField = (field, isDisabled) => {
  const external_id_by_be = '018b2f19-e79e-7d6a-a56d-29feb6211b04';
  return field.id === external_id_by_be 
    && field.defaultValue ? true : isDisabled;
};

export default FieldComponent;
