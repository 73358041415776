import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  height: 35px;
  padding: 0 5px;
  border: 1px solid lightgray;
  box-sizing: border-box;
`;

export default StyledInput;
