import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { notification } from 'antd';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import { getCurrentUser, getAgencyTZ } from 'APP_ROOT/selectors/session';
import getRoute from 'APP_ROOT/utils/get-route';

import AdministratorWrapper from '../Administrator.styled';
import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';

import StyledTable from './AgencyCasefiles.styled';
import getTableConfig from './getTableConfig';
import PredefinedTasks from './components/predefinedTasks/PredefinedTasks';
import Reasons from './components/reasons/Reasons';
import Outcomes from './components/outcomes/Outcomes';
import CaseFileTypeDeadline from './components/caseFileTypeDeadline/CaseFileTypeDeadline';
import Statuses from './components/statuses/Statuses';
import { hasFeatures, FEATURES } from '../../../utils/features';

const NOTIFICATION_ERROR = 'error';

const AgencyCasefile = React.forwardRef((props, ref) => {
  const [casefileTypes, setCasefileTypes] = useState([]);
  const [selectedCasefileType, setSelectedCasefileType] = useState(null);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [showKeyDetails, setShowKeyDetails] = useState(false);
  const { timezone, agencyId, tenantId } = props;
  const tableConfig = getTableConfig(timezone, handleClick);

  useEffect(() => {
    const { user: { featureFlags } = {} } = props;

    const enableCasefileKeyDetails = hasFeatures(
      featureFlags,
      FEATURES.enableCasefileKeyDetails
    );

    setShowKeyDetails(enableCasefileKeyDetails);
    fetchData();
  }, []);

  function showNotification(type, message, description) {
    return notification[type]({ message, description });
  }

  function fetchData() {
    setLoadingTypes(true);
    caseFileEndpoints
      .getCasefileTypes(tenantId)
      .then(response => {
        if (response?.content.length > 0) {
          const types = response.content.map(type => {
            return {
              key: type.id,
              type: type.name,
              category: type.categoryName,
              abbreviation: type.abbreviation,
              createdAt: type.createdAt,
              deadline: type.deadline,
            };
          });
          setCasefileTypes(types);
        }
      })
      .catch(err => {
        const message = err.message || 'API response is not ok';
        showNotification(NOTIFICATION_ERROR, 'Error', message);
      })
      .finally(() => setLoadingTypes(false));
  }

  function handleClick(casefileType) {
    setSelectedCasefileType(casefileType);
  }

  return (
    <AdministratorWrapper>
      <PageHeader
        title={
          selectedCasefileType ? selectedCasefileType.type : 'Case File Types'
        }
        goBackTo={
          selectedCasefileType
            ? getRoute('administratorAgencyCasefiles', { agencyId, tenantId })
            : getRoute('administratorAgency', { agencyId, tenantId })
        }
        onClick={() => setSelectedCasefileType(null)}
      />

      {selectedCasefileType ? (
        <>
          {showKeyDetails && (
            <CaseFileTypeDeadline casefileType={selectedCasefileType} />
          )}
          <PredefinedTasks
            timezone={timezone}
            casefileType={selectedCasefileType}
          />
          {showKeyDetails && (
            <Reasons timezone={timezone} casefileType={selectedCasefileType} />
          )}
          {showKeyDetails && (
            <Outcomes timezone={timezone} casefileType={selectedCasefileType} />
          )}
          {showKeyDetails && (
            <Statuses timezone={timezone} casefileType={selectedCasefileType} />
          )}
        </>
      ) : (
        <StyledTable
          style={{ padding: '24px' }}
          columns={tableConfig}
          dataSource={casefileTypes}
          loading={loadingTypes}
          bordered
        />
      )}
    </AdministratorWrapper>
  );
});

const mapState = (state, props) => {
  const user = getCurrentUser(state);
  const agencyId = get(props, 'match.params.agencyId');
  const tenantId = get(props, 'match.params.tenantId');
  const timezone = getAgencyTZ(state);
  return {
    user,
    agencyId,
    tenantId,
    timezone,
  };
};

export default withoutClutter(DashboardPage(mapState)(AgencyCasefile));
