import React, { useEffect, useState } from 'react';
import { Button, Divider, notification, Modal, Typography, Icon } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

import StyledTable from '../../AgencyCasefiles.styled';
import getPredefinedTasksTableConfig from './getPredefinedTasksTableConfig';

import { PageWrapper, Content, Header, Title } from './PredefinedTasks.styled';
import AddPredefinedTaskModal from './AddPredefinedTaskModal';
import caseFileEndpoints from '../../../../../api/caseFileEndpoints/caseFileEndpoints';

const { confirm } = Modal;
const { Text } = Typography;

const NOTIFICATION_SUCCESS = 'success';
const NOTIFICATION_ERROR = 'error';

function PredefinedTasks(props) {
  const [predefinedTasks, setPredefinedTasks] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { timezone } = props;
  const tableConfig = getPredefinedTasksTableConfig(
    timezone,
    handleDeletePredefinedTask
  );

  useEffect(() => {
    const { casefileType } = props;
    setLoadingData(true);
    caseFileEndpoints
      .getPredefinedTaskLists(casefileType.key)
      .then(response => {
        if (response) {
          const tasks = response.content.map(task => ({
            key: task.id,
            description: htmlUnescape(task.description),
            createdAt: task.createdAt,
          }));
          setPredefinedTasks(tasks);
        }
      })
      .catch(err => showError(err))
      .finally(() => setLoadingData(false));
  }, []);

  function showError(err) {
    const message = err.message || 'API response is not ok';
    showNotification(NOTIFICATION_ERROR, 'Error', message);
  }

  function showSuccess(message) {
    showNotification(NOTIFICATION_SUCCESS, 'Success', message);
  }

  function showNotification(type, message, description) {
    return notification[type]({ message, description });
  }

  function handleToggleModal() {
    setShowModal(prevState => !prevState);
  }

  function handleDeletePredefinedTask(data) {
    const { casefileType } = props;
    confirm({
      autoFocusButton: null,
      title: (
        <Text
          strong
          style={{
            color: '#121212',
            fontSize: '16px',
          }}
        >
          Are you sure you want to delete this task?
        </Text>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      width: 600,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
      onOk: () => {
        setLoadingData(true);
        caseFileEndpoints
          .archivePredefinedTask(casefileType.key, data.key)
          .then(response => {
            if (response) {
              setShowModal(false);
              showSuccess('Task removed successfully');
              const updatedPredefinedTasks = predefinedTasks.filter(
                task => task.key !== data.key
              );
              setPredefinedTasks(updatedPredefinedTasks);
            }
          })
          .catch(err => showError(err))
          .finally(() => setLoadingData(false));
      },
      onCancel: () => {},
    });
  }

  function handleSaveNewPredefinedTask(description) {
    const { casefileType } = props;
    const predefinedTaskExists = predefinedTasks.find(
      task => task.description === description
    );

    if (predefinedTaskExists) {
      const message = `The task with description '${description}' already exists.`;
      showNotification(NOTIFICATION_ERROR, 'Error', message);
    } else {
      setLoadingData(true);
      caseFileEndpoints
        .addPredefinedTaskToCasefileType(casefileType.key, {
          casefileType: casefileType.key,
          description: description,
        })
        .then(response => {
          if (response) {
            setShowModal(false);
            showSuccess(`Task '${description}' created successfully`);
            const newTask = {
              key: response.content.id,
              description: htmlUnescape(response.content.description),
              createdAt: response.content.createdAt,
            };
            setPredefinedTasks(prevState => [newTask, ...prevState]);
          }
        })
        .catch(err => showError(err))
        .finally(() => setLoadingData(false));
    }
  }

  return (
    <PageWrapper>
      <Content>
        <Header>
          <Title>Default Tasks</Title>
          <Button
            type="primary"
            onClick={handleToggleModal}
            disabled={loadingData}
          >
            Add Default Task
          </Button>
        </Header>
        <Divider />
        <StyledTable
          columns={tableConfig}
          dataSource={predefinedTasks}
          loading={loadingData}
        />
      </Content>
      <AddPredefinedTaskModal
        onOk={handleSaveNewPredefinedTask}
        onCancel={handleToggleModal}
        visible={showModal}
      />
    </PageWrapper>
  );
}

export default PredefinedTasks;
