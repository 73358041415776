import { DISMISS_NOTIFICATION } from '.';

import { markNotificationDismiss } from '../api/activity';
import refreshActionItems from './refresh-action-items';
import refreshActivityItems from './refresh-activity-items';
import fetchActivityFeedCount from './get-feed-unread-count';

export default (notificationId, next = () => {}) => {
  return (dispatch, getState) => {
    const { session: { currentUser: { userId } = {} } = {} } = getState();

    return markNotificationDismiss(userId, notificationId)
      .then(response => {
        dispatch({ type: DISMISS_NOTIFICATION, payload: notificationId });
        dispatch(refreshActionItems());
        dispatch(refreshActivityItems());
        dispatch(fetchActivityFeedCount());
        next(null, response);
      })
      .catch(error => {
        next(error);
      });
  };
};
