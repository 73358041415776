import React, { useCallback, useEffect, useState } from 'react';
import getUserEquipments from '../../../agency-equipment/actions/get-user-equipments-data';
import decodeEquipmentTableRows from './utils/decodeEquipmentTableRows';

const useEquipmentRowsData = ({
  tenantId,
  userId,
  dispatch,
  isActive,
  currentPage,
  pageSize,
  sort,
}) => {
  const [loading, setLoading] = useState(false);
  const [equipmentRows, setEquipmentRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const fetchData = () => {
    setLoading(true);
    dispatch(
      getUserEquipments({
        tenantId: tenantId,
        userId: userId,
        pageNumber: currentPage,
        pageSize: pageSize,
        sort: sort,
        filters: {},
        next: callback,
      })
    );
  };

  const callback = useCallback(
    (error, data) => {
      setLoading(false);
      if (error || !data || data['success'] !== true) {
        return;
      }
      const { content, totalElements } = data;
      setEquipmentRows(decodeEquipmentTableRows(content));
      setTotalElements(totalElements);
    },
    [decodeEquipmentTableRows]
  );

  useEffect(() => {
    if (userId && tenantId && isActive) {
      fetchData();
    }
  }, [userId, tenantId, sort, currentPage, pageSize, isActive]);

  return { loading, equipmentRows, totalElements, fetchData };
};

export default useEquipmentRowsData;
