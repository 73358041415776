import styled from 'styled-components';
import StyledTable from '../../AgencyCasefiles.styled';

const PageWrapper = styled.div`
  padding: 24px;
`;

const Content = styled.div`
  background: #fff;
  padding: 16px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 600;
`;

const StatusTable = styled(StyledTable)`
  .disabled-dnd {
    cursor: default;
  }

  .enabled-dnd {
    cursor: move;
  }
`;

export { PageWrapper, Content, Header, Title, StatusTable };
