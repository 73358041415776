import EventEmitter from 'eventemitter3';

export default new EventEmitter();

export const EventTypes = {
  CREATE_REPORT: 'create-report',
  SET_TAB: 'set-tab',
  DISPATCH_IN_FORM: 'dispatch-in-form',
  REMOVE_REPEATER_ITEM: 'remove-repeater-item',
  CALL_FORM_METHOD: 'call-form-method',
  OPEN_FIELD_EDITOR: 'open-field-editor',
  TOGGLE_STATUS_TRAINING_TASK: 'toggle-status-training-task',
  OPEN_TRAINING_TASK: 'open-training-task',
};
