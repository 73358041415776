import styled from 'styled-components';
import { Table } from 'antd';

const StyledTable = styled(Table)`
  .ant-table table {
    border-bottom: none;
    background-color: #fff;
  }

  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > td .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.employment-table {
    table {
      table-layout: fixed;
    }

    .ant-table-tbody > tr,
    .ant-table-tbody > tr > td,
    .ant-table-tbody > tr > td .text {
      white-space: normal;
    }
  }

  .ant-table-footer {
    border-top: none;
    background-color: #fff;
  }
`;

export default StyledTable;
