import React, { Component } from 'react';
import { Icon, Tooltip } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ContentEditable from 'react-contenteditable';
import LinkingFormsDescription from '../form-viewer/linking-forms-description';
import IconButton from '../common/buttons/icon-button';
import Tag from '../common/tag';
import PageHeader from '../PageHeader/PageHeader';
import StyledIcon from './styled/icon';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import { connect } from 'react-redux';
import FormHeader from './styled/header';
import FormActions from './styled/actions';

export class FormHeaderActions extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props, ['changedFields']);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  handleBackButtonClick = () => {
    const { history = {} } = this.props;
    history.goBack();
  };

  getButtonWithTooltip = (action, index) => {
    return (
      <Tooltip title={action?.tooltipMessage || ''}>
        <IconButton
          key={index}
          shape="circle"
          disabled={action.loading}
          onClick={e => action.onButtonClick()(e, action)}
        >
          {action.loading ? (
            <Icon type="loading" />
          ) : action.type === 'icon' ? (
            <StyledIcon type={action.icon} />
          ) : (
            <img src={action.icon} alt="Button" />
          )}
        </IconButton>
      </Tooltip>
    );
  };

  getOnBehalfUsers = () => {
    const { usersOnBehalf } = this.props;
    const hasUsersOnBehalf = !isEmpty(usersOnBehalf);
    let usersOnBehalfFormatted = [];

    if (hasUsersOnBehalf) {
      JSON.stringify(usersOnBehalf, function(key, value) {
        usersOnBehalfFormatted = Object.values(value);
      });
    }
    return usersOnBehalfFormatted;
  };

  submittedByLabel = () => {
    const {
      isDraft = false,
      submittedBy = {},
      statusSubmittedByDate = '',
      usersOnBehalf = {},
      organizationalUnitDisplayName = '',
    } = this.props;
    const hasUsersOnBehalf = !isEmpty(usersOnBehalf);
    const usersOnBehalfFormatted = this.getOnBehalfUsers();
    const {
      fullName: submitterName,
      rank: { name: submitterRank = '' } = {},
    } = submittedBy;
    const submitterPrefix = isDraft ? 'Last saved by ' : 'Submitted by ';

    return (
      <div>
        <p>
          {submitterPrefix}
          <strong>
            <ContentEditable
              className="in-line"
              html={`${submitterRank} ${submitterName}`}
              disabled={true}
            />
          </strong>
        </p>
        {organizationalUnitDisplayName && (
          <strong>
            <ContentEditable
              html={organizationalUnitDisplayName}
              disabled={true}
            />
          </strong>
        )}
        <p>
          {hasUsersOnBehalf && (
            <strong>
              <ContentEditable
                className="in-line"
                html={` on behalf of ${usersOnBehalfFormatted[0].firstName} ${usersOnBehalfFormatted[0].lastName}, `}
                disabled={true}
              />
            </strong>
          )}
          <span className="submitted-date">{statusSubmittedByDate}</span>
        </p>
      </div>
    );
  };

  getFormExist = () => {
    const {
      isReviewer = false,
      isActiveReviewer = false,
      title = '',
      type = '',
      showType = true,
      statusInfo = {},
      showLinkedFormSummary = false,
      match,
    } = this.props;
    const {
      label: statusLabel = 'Draft',
      key: statusKey = 'draft',
    } = statusInfo;
    return (
      <>
        {title}
        {isActiveReviewer && (
          <Tag
            size="default"
            className="form-info__status status-indicator"
            statusTag
            type={statusKey}
          >
            {statusLabel}
          </Tag>
        )}
        {showLinkedFormSummary ? (
          <LinkingFormsDescription isReviewer={isReviewer} match={match} />
        ) : showType ? (
          <small>{type}</small>
        ) : (
          ''
        )}
      </>
    );
  };

  getFormInfoTitle = () => {
    const { formExist = false, type = '', pageTitle } = this.props;
    return (
      <>
        {pageTitle
          ? pageTitle
          : formExist
          ? this.getFormExist()
          : `New ${type} Report`}
      </>
    );
  };

  render() {
    const {
      actions = [],
      formExist = false,
      children = [],
      sidebarCollapsed = false,
      saveRef,
    } = this.props;

    return (
      <PageHeader
        title={this.getFormInfoTitle()}
        goBackTo="/reports"
        saveRef={saveRef}
        sidebarIsCollapsed={sidebarCollapsed}
        stickToTop
        actions={[
          <FormActions key="form-actions">
            <div className="form-actions__meta">
              {formExist && this.submittedByLabel()}
              {!formExist && (
                <div>
                  <p dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
                  <p dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
                </div>
              )}
            </div>
            <div className="form-actions__buttons">
              {actions.map((action, index) => {
                if (action.href) {
                  return (
                    <Link
                      to={action.href}
                      target={action.target || '_self'}
                      key={index}
                    >
                      {this.getButtonWithTooltip(action, index)}
                    </Link>
                  );
                }

                return this.getButtonWithTooltip(action, index);
              })}
            </div>
          </FormActions>,
        ]}
      >
        <FormHeader>{children}</FormHeader>
      </PageHeader>
    );
  }
}

export default connect()(withRouter(FormHeaderActions));
