import { get, pick } from 'lodash';

import { getAgencyTemplate } from 'APP_ROOT/api/reports';

const getTemplateAtVersionBeforeCreateReport = ({
  agencyId,
  template,
  templateVersionId,
  templateIdParam = null,
}) =>
  new Promise(async resolve => {
    const templateId = get(template, 'id', templateIdParam);

    try {
      const templateAtVersion = await getAgencyTemplate({
        templateId: templateId,
        agencyId,
        isDefault: false,
        onlyPublished: true,
        templateVersionId,
      });

      resolve([
        null,
        Object.assign(
          {},
          template,
          pick(templateAtVersion, [
            'formSchema',
            'shareable',
            'type',
            'version',
            'versionId',
          ])
        ),
      ]);
    } catch (error) {
      resolve([error]);
    }
  });

export default getTemplateAtVersionBeforeCreateReport;
