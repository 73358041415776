import React, { useState, useEffect } from 'react';
import { Button, Divider, notification, Modal, Icon, Typography } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import caseFileEndpoints from '../../../../../api/caseFileEndpoints/caseFileEndpoints';
import useDebounce from '../../../../../hooks/useDebounce';
import getStatusTableConfig from './getStatusesTableConfig';
import {
  PageWrapper,
  Content,
  Header,
  Title,
  StatusTable,
} from './Statuses.styled';
import StatusBodyRow from './StatusBodyRow';
import AddStatusModal from './AddStatusModal';
import { CLOSED_STATUS } from './constants';

const NOTIFICATION_SUCCESS = 'success';
const NOTIFICATION_ERROR = 'error';

const Statuses = ({ casefileType, timezone }) => {
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [typeCount, setTypeCount] = useState({ open: 1, paused: 1 });
  const tableConfig = getStatusTableConfig(
    timezone,
    handleDeleteStatus,
    typeCount
  );

  const { confirm } = Modal;
  const { Text } = Typography;
  const components = {
    body: {
      row: StatusBodyRow,
    },
  };

  useEffect(() => {
    setLoading(true);
    caseFileEndpoints
      .getCasefileTypeStatuses(casefileType.key)
      .then(response => {
        if (response) {
          const { content } = response;
          const newStatuses = content.map(status => ({
            ...status,
            label: htmlUnescape(status.label),
            type: htmlUnescape(status.type),
          }));
          moveClosedStatus(newStatuses);
          setStatuses(newStatuses);
        }
      })
      .catch(err => showError(err))
      .finally(() => setLoading(false));
  }, []);

  function moveClosedStatus(arr) {
    const closedIndex = arr.findIndex(status => status.type == CLOSED_STATUS);
    arr.push(arr.splice(closedIndex, 1)[0]);
  }

  function handleDeleteStatus(value) {
    const { id, type } = value;
    if (type === CLOSED_STATUS) {
      return;
    }
    confirm({
      autoFocusButton: null,
      title: (
        <Text
          strong
          style={{
            color: '#121212',
            fontSize: '16px',
          }}
        >
          Are you sure you want to delete this status?
        </Text>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      width: 600,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
      onOk: () => {
        setLoading(true);
        caseFileEndpoints
          .archiveStatus(casefileType.key, id)
          .then(response => {
            if (response) {
              setShowModal(false);
              showSuccess('Status removed successfully');
              const updatedStatuses = statuses.filter(
                status => status.id !== id
              );
              setStatuses(updatedStatuses);
            }
          })
          .catch(err => showError(err))
          .finally(() => setLoading(false));
      },
      onCancel: () => {},
    });
  }

  function showError(err) {
    const message = err.message || 'API response is not ok';
    showNotification(NOTIFICATION_ERROR, 'Error', message);
  }

  function showSuccess(message) {
    showNotification(NOTIFICATION_SUCCESS, 'Success', message);
  }

  function showNotification(type, message, description) {
    return notification[type]({ message, description });
  }

  function moveRow(dragIndex, hoverIndex) {
    const orderedStatuses = [...statuses];
    const dragRow = statuses[dragIndex];
    const hoverRow = statuses[hoverIndex];
    orderedStatuses[dragIndex] = hoverRow;
    orderedStatuses[hoverIndex] = dragRow;
    setStatuses(orderedStatuses);
    saveStatusesOrder();
  }

  const saveStatusesOrder = useDebounce(() => {
    const newStatusesOrder = {
      statusIds: statuses.map(status => status.id),
    };
    caseFileEndpoints
      .updateStatusesOrder(casefileType.key, newStatusesOrder)
      .catch(err => showError(err));
  }, 800);

  function handleToggleModal() {
    setShowModal(prevState => !prevState);
  }

  function handleSaveNewReason(statusName, statusType) {
    const statusExists = statuses.find(status => status.label === statusName);

    if (statusExists) {
      const message = `The status with label '${statusName}' already exists.`;
      showNotification(NOTIFICATION_ERROR, 'Error', message);
    } else {
      const payload = {
        label: statusName,
        type: statusType,
      };

      setLoading(true);
      caseFileEndpoints
        .createCasefileTypeStatus(casefileType.key, payload)
        .then(response => {
          if (response) {
            setShowModal(false);
            showSuccess(`Status '${statusName}' created successfully`);
            const { id, label, type, createdAt } = response.content;
            const newStatus = {
              id,
              label: htmlUnescape(label),
              type: type,
              createdAt: createdAt,
            };
            setStatuses(prevState => [newStatus, ...prevState]);
          }
        })
        .catch(err => showError(err))
        .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    setLoading(true);
    caseFileEndpoints
      .getCasefileTypeStatuses(casefileType.key)
      .then(response => {
        if (response) {
          const statusesData = response.content.map(status => ({
            ...status,
            label: htmlUnescape(status.label),
          }));

          setStatuses(statusesData);
        }
      })
      .catch(err => showError(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (statuses.length === 0) {
      return;
    }

    const newCount = statuses.reduce(
      (curr, acc) => {
        const type = acc.type;
        if (type !== CLOSED_STATUS) {
          curr[type.toLowerCase()] += 1;
        }
        return curr;
      },
      { open: 0, paused: 0 }
    );

    setTypeCount(newCount);
  }, [statuses]);

  return (
    <div className="casefile-status-container">
      <PageWrapper>
        <Content>
          <Header>
            <Title>Statuses</Title>
            <Button
              type="primary"
              onClick={() => setShowModal(true)}
              disabled={loading}
            >
              Add Status
            </Button>
          </Header>
          <Divider />
          <DndProvider backend={HTML5Backend}>
            <StatusTable
              rowKey="id"
              rowClassName={(record, _) => {
                if (record.type === CLOSED_STATUS) {
                  return 'disabled-dnd';
                }
                return 'enabled-dnd';
              }}
              columns={tableConfig}
              dataSource={statuses}
              loading={loading}
              components={components}
              onRow={(record, index) => ({
                index,
                moveRow,
              })}
            />
          </DndProvider>
        </Content>
        <AddStatusModal
          onOk={handleSaveNewReason}
          onCancel={handleToggleModal}
          visible={showModal}
        />
      </PageWrapper>
    </div>
  );
};

export default Statuses;
