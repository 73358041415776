import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import parsedDate from '../../utils/parse-date';

import { getUsers } from '../../modules/ReportSharing/services/api';
import { filterByIds } from '../../modules/ReportSharing/utils/networkRequestFilters';
import { get, find, isEmpty } from 'lodash';

class ShareItem extends Component {
  state = { isLoading: true, users: [], userShareFrom: [] };

  componentDidMount() {
    this.getShareUsers();
    this.getShareFrom();
  }

  getShareUsers = async () => {
    const { item, agencyId } = this.props;
    const { shareToId } = item;
    const filter = filterByIds(shareToId);
    const response = await getUsers(agencyId, filter);
    const users = response
      .map(user => `${get(user, 'rank.name', '')} ${get(user, 'fullName', '')}`)
      .join(', ');
    this.setState({ users, isLoading: false });
  };

  getParticipant = (participants = [], id) => {
    return find(participants, _item => _item.id === id);
  };

  getShareFrom = async () => {
    const { item, agencyId, participants } = this.props;
    const { shareFromId } = item;
    const participant = await this.getParticipant(participants, shareFromId);
    if (isEmpty(participant)) {
      const filter = filterByIds([shareFromId]);
      const response = await getUsers(agencyId, filter);
      const userShareFrom = response
        .map(
          user => `${get(user, 'rank.name', '')} ${get(user, 'fullName', '')}`
        )
        .join(', ');
      this.setState({ userShareFrom, isLoading: false });
    } else {
      this.setState({
        userShareFrom: `${participant.rank.name} ${participant.fullName}`,
        isLoading: false,
      });
    }
  };

  render() {
    const { item, index, number, timezone } = this.props;
    const { users, isLoading, userShareFrom } = this.state;
    if (isLoading)
      return (
        <ContentLoader
          height={5}
          width={90}
          speed={2}
          primaryColor={'#f3f3f3'}
          secondaryColor={'#ecebeb'}
          className="loader-share-item"
        >
          <rect x="0" y="0" rx="4" ry="4" width="90" height="5" />
        </ContentLoader>
      );

    return (
      <div className={`timeline-item-date-${index}`}>
        <p className={`timeline-item-date-${index}`}>
          {parsedDate(item.createdAt, timezone)}
        </p>
        <p>
          {userShareFrom} has {item.action}d the report{' '}
          {get(item, 'formNumber', number)} with {users}
        </p>
      </div>
    );
  }
}

export default ShareItem;
