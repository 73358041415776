import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Form, Icon, Modal } from 'antd';
import {
  get,
  has,
  kebabCase,
  first,
  keys,
  isPlainObject,
  template,
  unescape,
  isEmpty,
  isEqual,
  debounce,
} from 'lodash';
import scrollToComponent from 'react-scroll-to-component';
import { v4 as uuid } from 'uuid';

import store from 'APP_ROOT/store';
import syncFormData from 'APP_ROOT/actions/sync-form-data';
import syncFormValueChanged from 'APP_ROOT/actions/sync-form-value-changed-boolean';
import changeFormTab from 'APP_ROOT/actions/change-form-tab';
import addRepeaterItem from 'APP_ROOT/actions/add-repeater-item';
import removeRepeaterItem from 'APP_ROOT/actions/remove-repeater-item';
import syncFormValidation from 'APP_ROOT/actions/sync-form-validations';
import removeSourceSelection from 'APP_ROOT/actions/remove-source-selection';
import removeReferenceSelection from 'APP_ROOT/actions/remove-reference-selection';
import addExternalIdToFormData from '../../../actions/add-external-id-to-form-data';

import SchemaDrawer from '../object-types/object-field';
import ModalTitle from '../../common/modal/title';
import withModal from '../../common/modal/base';
import FormHeader from '../../form/header';
import ReviewList from '../../form/review-list';
import ScrollToTop from '../../form/ScrollToTop';
import EditAdminButton from './edit-admin-button';
import { showUpdateConfirmationModal } from './update-report';

import { mapFormChanges } from 'APP_ROOT/utils/form';
import flattenErrors from 'APP_ROOT/utils/flatten-errors';
import {
  getTabFieldsError,
  getRequirementComplimentFieldsError,
} from 'APP_ROOT/utils/validations';
import mapPropsToFields, {
  getFormAttachments,
  initializeFormFields,
} from 'APP_ROOT/utils/map-props-to-fields';
import { transformParticipants } from 'APP_ROOT/utils/form';
import getLastStatus from 'APP_ROOT/utils/get-report-status';
import parseDate from 'APP_ROOT/utils/parse-date';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import getFormSettings from 'APP_ROOT/utils/getFormSettings.memoized';
import getFormProps from 'APP_ROOT/utils/getFormProps.memoized';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import ShareButton from '../../../modules/ReportSharing/components/Button';
import ReassignButton from '../../../modules/ReportReassign/components/Button';
import getIsSharingEnabled from '../../../modules/ReportSharing/utils/isSharingEnabled';
import getIsReassignEnabled from '../../../modules/ReportReassign/utils/getIsReassignEnabled';
import { autoSaveDraftReport, formatData } from '../../utils/auto-save';
import addSelectedFormCopy from '../../../../src/actions/add-selected-form-copy';
import replaceSelectedFormCopy from '../../../../src/actions/replace-selected-form-copy';

import canEnableAdminEditReview from './canEnableAdminEditReview';
import getRepeaterPropsForAutoSave from './getRepeaterPropsForAutoSave';
import onConfirmSubmit from './utils/onConfirmSubmit';

import getErrorMessageModalBody from '../../../utils/getErrorMessageModalBody';
import getAllFieldsFlat from '../../../modules/FormBuilder/utils/getAllFieldsFlat';
import { getErrors } from './utils/get-errors-utils';
import {
  shareReport,
  unshareReport,
} from '../../../modules/ReportSharing/services/api';
import {
  sendAssignTaskNotification,
  removeAssignTaskNotification,
} from './utils/contribute-report-notifications.js';
import {
  CONTRIBUTOR_MANUAL_SAVE,
  CONTRIBUTOR_SHARE_UPDATE,
} from '../../../constants/contributeToReport.js';

import isMobileDevice from '../../../utils/isMobileDevice';
import getRoute from '../../../utils/get-route.js';

const confirm = Modal.confirm;

const formName = 'form';

const isMobile = isMobileDevice();
const DEFAULT_HEIGHT = 215;

class FormViewerWrapper extends withModal(Component) {
  performanceReviewUpdateShare_bound = this.performanceReviewUpdateShare.bind(
    this
  );
  performanceReviewManualSaveDraft_bound = this.manualSaveDraft.bind(this);
  state = {
    formOffset: 0,
    isEditAllow: false,
    drawerKey: 'drawerKey',
    isDraftJustSaved: false,
    lastFieldAutosaved: {},
  };

  tabsRefs = [];

  componentWillMount() {
    emitter.on(EventTypes.DISPATCH_IN_FORM, this.onDispatch);
    emitter.on(EventTypes.CALL_FORM_METHOD, this.onCallFormMethod);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    this.createModal();
    this.getFormHeaderHeight();
    dispatch(addExternalIdToFormData(uuid()));
    window.addEventListener('resize', this.getFormHeaderHeight);
    window.addEventListener(
      CONTRIBUTOR_SHARE_UPDATE,
      this.performanceReviewUpdateShare_bound
    );
    window.addEventListener(
      CONTRIBUTOR_MANUAL_SAVE,
      this.performanceReviewManualSaveDraft_bound
    );
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
    window.removeEventListener('resize', this.getFormHeaderHeight);

    emitter.off(EventTypes.DISPATCH_IN_FORM, this.onDispatch);
    emitter.off(EventTypes.CALL_FORM_METHOD, this.onCallFormMethod);
    window.removeEventListener(
      CONTRIBUTOR_SHARE_UPDATE,
      this.performanceReviewUpdateShare_bound
    );
    window.removeEventListener(
      CONTRIBUTOR_MANUAL_SAVE,
      this.performanceReviewManualSaveDraft_bound
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return propsHasChanged(
      { ...nextProps, ...nextState },
      { ...this.props, ...this.state },
      ['isEditAllow']
    );
  }
  // TODO: Is this the best way to save draft?
  manualSaveDraft({ detail: { includeShare, shareDetails } }) {
    let cb = () => {};
    if (includeShare) {
      cb = () => {
        this.performanceReviewUpdateShare({ detail: shareDetails });
      };
    }
    this.props.onSaveDraft(cb);
  }

  performanceReviewUpdateShare({ detail: { shareUserId, unshareUserId } }) {
    const {
      selectedForm,
      user: { id: senderId },
    } = this.props;
    if (shareUserId) {
      shareReport(selectedForm.meta.id, [shareUserId]);
      sendAssignTaskNotification(selectedForm, senderId, shareUserId);
    }
    if (unshareUserId) {
      unshareReport(selectedForm.meta.id, [Number(unshareUserId)]);
      removeAssignTaskNotification(Number(unshareUserId));
    }
  }

  onCallFormMethod = (name, _formName, ...args) => {
    if (_formName !== formName) return;

    if (this[name]) {
      if (typeof this[name] !== 'function') {
        // eslint-disable-next-line no-console
        console.warn(
          `The method you are trying to call does not exist. Method "${name}" with args "${JSON.stringify(
            args
          )}"`
        );
        return;
      }

      this[name](...args);
    }
  };

  onAttachmentsFetched = () => {
    const { onAttachmentsFetched } = this.props;

    onAttachmentsFetched &&
      typeof onAttachmentsFetched === 'function' &&
      onAttachmentsFetched();
  };

  onSaveDraft = async onDone => {
    const { onSaveDraft } = this.props;
    onSaveDraft && typeof onSaveDraft === 'function' && onSaveDraft(onDone);
  };

  onDispatch = (_formName, fn) => {
    const { dispatch } = this.props;

    if (_formName !== formName) return;

    dispatch(fn);
  };

  addRepeaterItem = (keys, ...args) => {
    const { dispatch } = this.props;

    dispatch(addRepeaterItem(keys, ...args));
  };

  removeRepeaterItem = (
    keys,
    index = 0,
    itemData,
    removeKeys,
    removeRefKeys = [],
    deleteModalOptions = {},
    showConfirmation = true
  ) => {
    const mergedDeleteModalOptions =
      isPlainObject(deleteModalOptions) && showConfirmation
        ? Object.assign(
            {},
            {
              title: 'Heads Up!',
              okText: 'Yes',
              cancelText: 'Cancel',
              hideCounter: false,
              content: `
        <p>
          By removing <strong>this section</strong>, all the information related to it will be removed from the entire form. <br />
          Do you want to remove it?
        </p>
      `,
            },
            deleteModalOptions
          )
        : {};

    const { dispatch } = this.props;
    const content = {
      __html: unescape(
        template(mergedDeleteModalOptions.content)({
          index: mergedDeleteModalOptions.hideCounter ? false : index + 1,
        })
      ),
    };

    const onConfirm = () => {
      keys.forEach(key => {
        dispatch(removeRepeaterItem(key, index));
      });
      removeKeys.forEach(item => {
        const { key, source } = item;
        const value = get(itemData, key, '');
        dispatch(removeSourceSelection(value, source));
      });
      removeRefKeys.forEach(item => {
        const { source = '', field = 'officerId', fields = [] } = item;
        const value = get(itemData, [field], '');
        dispatch(removeReferenceSelection(value, source, fields));
      });
    };

    if (showConfirmation) {
      confirm({
        title: mergedDeleteModalOptions.title,
        okText: mergedDeleteModalOptions.okText,
        cancelText: mergedDeleteModalOptions.cancelText,
        content: <div dangerouslySetInnerHTML={content} />,
        onOk() {
          onConfirm();
        },
      });
    } else {
      onConfirm();
    }
  };

  onSubmit = debounce(() => {
    const { app: { blockNavigation = false } = {} } = this.props;

    // Check if it is performance review + all task are marked as completed
    if (this.props.selectedForm.isContributeReport) {
      const incompleteSection = this.props.selectedForm.data.__assignedSections.find(
        assignedSection => !assignedSection.complete
      );
      if (incompleteSection) {
        this.props.onSaveDraft();
        return false;
      }
    }

    if (blockNavigation) {
      this.showErrorConfirmation(
        'One or more files are still being uploaded. Please wait for them to finish and submit again.'
      );

      return false;
    }

    return onConfirmSubmit(
      this.props,
      this.showErrorConfirmation,
      this.validateFields
    );
  }, 500);

  handleSubmit = e => {
    e.preventDefault();
    this.onSubmit();
  };

  validateFields = (current = null, next = () => {}) => {
    const { dispatch, form } = this.props;
    const tabFields = form.getFieldsValue();

    const tabKeys = keys(tabFields).reduce((result, key) => {
      const item = tabFields[key];

      if (Array.isArray(item)) {
        if (isPlainObject(first(item))) {
          return item.reduce((res, i, index) => {
            return Object.keys(i).reduce((resi, ci) => {
              if (ci === 'id') {
                return resi;
              }
              return [...resi, `${key}[${index}].${ci}`];
            }, res);
          }, result);
        }
      }

      return [...result, key];
    }, []);

    form.validateFields(tabKeys, (errors, values) => {
      const flattenedErrors = flattenErrors(errors);
      dispatch(syncFormValidation(flattenedErrors));
      next();
    });
  };

  validateFormFields = async callback => {
    const { dispatch, selectedForm, shouldValidate = true } = this.props;
    const { presentation = {} } = selectedForm;

    if (shouldValidate) {
      const preparedFieldsValidation = await getTabFieldsError(this.props);
      const getRequirementComplimentErrors = getRequirementComplimentFieldsError(
        { ...this.props, forceValidate: true }
      );
      const requirementErrorsByTab = getRequirementComplimentErrors.reduce(
        (errors, error) => ({
          ...(errors || {}),
          [error.tabIndex]: [...(errors[error.tabIndex] || []), error],
        }),
        {}
      );

      const errors = await Promise.all(
        keys(presentation.fields).map(async tab =>
          preparedFieldsValidation(tab)
        )
      );

      const allErrors = errors
        .map(tab => tab.fields)
        .reduce((result, tab, index) => {
          return { ...result, ...tab };
        }, {});

      const bulkErrors = errors.map((tab, tabIndex) => [
        ...tab.errors,
        ...(requirementErrorsByTab[tabIndex] || []),
      ]);

      const fieldsWithErrors = Object.keys(allErrors);

      callback(bulkErrors);

      if (fieldsWithErrors.length || getRequirementComplimentErrors.length) {
        dispatch(syncFormValidation(allErrors, bulkErrors));
        return false;
      }

      dispatch(syncFormValidation(null, null, true));
    }
  };

  showErrorConfirmation = (errorMessage = '') => {
    const ErrorTitle = (
      <ModalTitle error>
        <Icon type="exclamation-circle" /> <span>Heads up!</span>
      </ModalTitle>
    );

    const ErrorText = getErrorMessageModalBody(errorMessage);

    this.updateModal({
      visible: true,
      title: ErrorTitle,
      children: ErrorText,
    });

    this.showModal();
  };

  saveTabRef = (ref, tab = 0) => {
    this.tabsRefs[tab] = ref;
  };

  saveFormHeaderRef = el => {
    this.formHeader = el;
  };

  saveFormRef = el => {
    this.formBody = el;
  };

  getFormHeaderHeight = () => {
    const headerHeight =
      findDOMNode(this.formHeader)?.clientHeight || DEFAULT_HEIGHT;
    const parentMargin = isMobile ? 20 : 10;

    this.setState({
      formOffset: headerHeight + parentMargin,
    });
  };

  scrollToTop = e => {
    const headerHeight =
      findDOMNode(this.formHeader)?.clientHeight || DEFAULT_HEIGHT;
    const offset = -headerHeight;

    scrollToComponent(this.tabsRefs[0] || this.formBody, {
      align: 'top',
      offset,
    });
  };

  syncTab = active => {
    const { dispatch, form } = this.props;
    const name = get(form, 'selectedForm.name', '');

    dispatch(changeFormTab(active, name));
  };

  getTabFieldsError = getTabFieldsError(this.props);

  get isSaved() {
    const { selectedForm } = this.props;

    return get(selectedForm, ['meta', 'formNumber'], '');
  }

  get formActions() {
    const actions = [];
    if (this.isSaved) {
      const isSharingEnabled = getIsSharingEnabled(this.props);
      const isReassignEnabled = getIsReassignEnabled(this.props);
      if (isSharingEnabled) {
        actions.push(ShareButton);
      }
      if (isReassignEnabled) {
        actions.push(ReassignButton);
      }
    }
    return actions;
  }

  disableEditReport = () => {
    const { dispatch } = this.props;
    dispatch(replaceSelectedFormCopy());
    this.setState({ drawerKey: uuid(), isEditAllow: false });
  };

  validationSucceeded = () => {
    const { dispatch } = this.props;
    const reportId = get(this.props, ['selectedForm', 'id'], '');
    const data = get(this.props, ['selectedForm', 'data']);
    const agencyId = get(this.props, ['user', 'agencyId']);
    const reportData = {
      id: reportId,
      data: formatData(data),
      agencyId: agencyId,
    };

    this.state.isEditAllow
      ? showUpdateConfirmationModal(
          reportData,
          this.showModal,
          this.updateModal,
          dispatch,
          this.disableEditReport
        )
      : this.setState({ drawerKey: uuid(), isEditAllow: true });
  };

  validationResult = (err, values) => {
    const { dispatch } = this.props;
    const { selectedForm } = this.props;

    const {
      template: {
        formSchema: {
          form: { properties = [] },
        },
      },
    } = selectedForm;
    const { fieldsByKey = {} } = getAllFieldsFlat(properties);

    if (!err) {
      this.validationSucceeded();
    } else {
      const flattenedErrors = flattenErrors(err);
      dispatch(syncFormValidation(flattenedErrors));
      const {
        form: {
          selectedForm: { state = {} },
        },
      } = store.getState();
      // in case validateFields had found an error, the state should
      // have it, then check that first
      let hasErrors = Object.values(state).some(s => s.errors);
      if (hasErrors) {
        this.showErrorConfirmation(getErrors(state, fieldsByKey));
        return false;
      }
    }
  };

  enableEditReport = () => {
    const { dispatch } = this.props;
    dispatch(addSelectedFormCopy());
    this.props.form.validateFields(this.validationResult);
  };

  typeOfButtonToShowOnHeader = (
    isEditAllow,
    canSubmit,
    isDraft,
    isActiveReviewer,
    isTemporalActiveReviewer,
    isSubmitter,
    isPrinting,
    isPending
  ) => {
    if (isEditAllow || isPrinting || isPending) {
      return false;
    }
    if (isDraft && canSubmit && isSubmitter) {
      return true;
    } else {
      return isActiveReviewer || isTemporalActiveReviewer;
    }
  };

  getFormBody = (statusIsClosed, formProps, formSchema) => {
    const { isEditAllow } = this.state;
    if (statusIsClosed) {
      return (
        <SchemaDrawer
          {...formProps}
          {...formSchema}
          isReviewer={!isEditAllow}
          key={this.state.drawerKey}
        />
      );
    }

    return <SchemaDrawer {...formProps} {...formSchema} />;
  };

  validateCanRetry = (statusIsDraft = false) => {
    const {
      selectedForm: { changedFields, meta, isContributeReport },
      loading,
    } = this.props;
    const { creationInProgress = false } = meta;
    const { lastFieldAutosaved } = this.state;
    if (isEqual(lastFieldAutosaved, changedFields)) return false;
    this.setState({ lastFieldAutosaved: changedFields });
    // TODO: this is needed to avoid sending a form creation request when reviewer edits their fields
    return (
      !loading &&
      (!statusIsDraft || !!creationInProgress) &&
      !isContributeReport
    );
  };

  // put it here to reduce cyclomatic complexity in FormViewerWrapper.render
  autoSaveProcess = statusIsDraft => {
    const {
      selectedForm = {},
      selectedForm: { meta = {} },
    } = this.props;
    const formSubmittedDate = get(selectedForm, ['meta', 'submittedAt'], '');
    const isSubmitted = !!formSubmittedDate;
    const { isEditAllow } = this.state;
    const { isContributeReport } = selectedForm;
    const allowAutoSaveContributeFeat = isContributeReport && !isSubmitted;

    // Validates autosave to only triggers when report status new or draft OR isContributeReport
    if (
      selectedForm?.isValuechanged &&
      ((!isEditAllow && !isSubmitted) || allowAutoSaveContributeFeat)
    ) {
      const {
        selectedForm,
        selectedForm: { changedFields = {}, data },
        dispatch,
        loading,
      } = this.props;
      const { isDraftJustSaved } = this.state;
      const dataKey = first(Object.keys(changedFields));
      const dataValue = get(data, dataKey);
      // Do not autosave performance review assignees
      const contributorAssignmentFields =
        selectedForm.data?.__assignedSections?.flatMap(section =>
          Object.values(section.contributorFields)
        ) || [];
      if (contributorAssignmentFields.includes(dataKey)) return;
      // Do not autosave empty fields when there isn't a draft
      if (Array.isArray(dataValue) && isEmpty(dataValue) && !meta.id) return;
      const isRepeater =
        Array.isArray(dataValue) && isPlainObject(first(dataValue));

      const canRetry = this.validateCanRetry(statusIsDraft);

      const changedFieldsData = isRepeater
        ? getRepeaterPropsForAutoSave(changedFields, data)
        : changedFields;
      autoSaveDraftReport(
        statusIsDraft,
        selectedForm,
        changedFieldsData,
        isDraftJustSaved || loading,
        dispatch,
        canRetry
      );
      this.setState({ isDraftJustSaved: true });
    } else if (
      meta.id &&
      window.location.href.includes(getRoute('createNewReport'))
    ) {
      const id = meta.id;
      const reportUrlRoute = `/reports/${id}`;
      window.history.replaceState(null, '', reportUrlRoute);
      window.history.pushState(null, '', reportUrlRoute);
    }
  };

  getSubmittedBy = () => {
    const { selectedForm = {} } = this.props;
    const { meta = {} } = selectedForm;
    const { submitterId = '', participants = [] } = meta;

    return participants.reduce((result, item) => {
      const { id, fullName, rank = {} } = item;
      if (parseInt(id) === submitterId) {
        return {
          rank,
          fullName,
        };
      }
      return {
        ...result,
      };
    }, {});
  };

  renderReviewList = () => {
    const {
      selectedForm = {},
      form,
      isReviewer = false,
      user: { id } = {},
      onNotesFetched,
      timezone,
    } = this.props;
    const { meta = {}, validations: validationState } = selectedForm;
    const { participants = [], history = [], notes = [] } = meta;
    const activeReviewer = get(selectedForm, ['meta', 'activeReviewerId'], '');
    const validNotes = notes.filter(note => !isEmpty(note));
    const notesExist = validNotes.length > 0;
    const transformedParticipants = transformParticipants(participants);

    return (
      notesExist &&
      isReviewer && (
        <ReviewList
          title="Reviews"
          form={form}
          activeUser={id}
          activeReviewer={activeReviewer}
          history={history}
          participants={transformedParticipants}
          reviews={validNotes}
          timezone={timezone}
          validationState={validationState}
          onNotesFetched={onNotesFetched}
        />
      )
    );
  };

  getIsTemporalActiveReviewer = () => {
    const { selectedForm = {}, user: { id } = {} } = this.props;
    const { meta = {} } = selectedForm;
    const { workFlowData: { status = {} } = {} } = meta;

    return (
      has(status, id) && get(status, [id, 'labelIdentifier']) === 'TAKE_ACTION'
    );
  };

  isDraftStatus = () => {
    const { selectedForm = {}, user: { id } = {} } = this.props;
    const {
      meta = {},
      meta: { submitterId },
      isContributeReport,
    } = selectedForm;
    let userId = id;
    // INFO: Contributors do not have a workflow status
    // but they participate on a DRAFT
    if (isContributeReport) {
      userId = submitterId;
    }
    const { workFlowData: { status = {} } = {} } = meta;
    return (
      has(status, userId) &&
      get(status, [userId, 'labelIdentifier']) === 'DRAFT'
    );
  };

  getLastStatus = () => {
    const { selectedForm = {}, user: { id } = {} } = this.props;
    const { meta = {} } = selectedForm;

    return getLastStatus({ actions: selectedForm.actions, ...meta }, id);
  };

  isDraft = statusInfo => {
    const { selectedForm = {} } = this.props;
    const { key: statusKey = 'draft' } = statusInfo;

    return statusKey === 'draft' || !selectedForm.meta.id;
  };

  isClosedStatus = (isDraft, statusInfo) =>
    !isDraft && get(statusInfo, 'key', '') === 'closed';

  getFormSettings = () => {
    const {
      selectedForm = {},
      user: { agencyId } = {},
      supportsDraft = true,
      isEditor = false,
    } = this.props;
    const { template = {} } = selectedForm;
    //formSchema inception
    const { formSchema: jsonSchema = {} } = template;
    const { form: formSchema } = jsonSchema;

    const formNumber = get(selectedForm, ['meta', 'formNumber'], '');
    const isEditMode = get(selectedForm, ['meta', 'isEditMode'], false);
    const formExist = !!formNumber;
    const templateType = get(selectedForm, ['template', 'type'], '');

    const syncTab = this.syncTab;

    return getFormSettings(
      syncTab,
      formSchema,
      this.validateFields,
      this.validateFormFields,
      this.getTabFieldsError,
      this.saveTabRef,
      supportsDraft,
      formExist,
      templateType,
      agencyId,
      formName,
      isEditor,
      isEditMode
    );
  };

  getFormProps = () => {
    const {
      selectedForm = {},
      form,
      isReviewer = false,
      user: { id } = {},
      canSubmit = false,
      loading = false,
      templates = [],
      timezone,
    } = this.props;
    const {
      template = {},
      presentation = {},
      meta = {},
      tab = 0,
    } = selectedForm;
    const { viewerIds = [] } = meta;
    //formSchema inception
    const { formSchema: jsonSchema = {} } = template;
    const { overrides = {}, validations = {} } = jsonSchema;

    const isViewer = viewerIds.includes(id);
    const settings = this.getFormSettings();

    return getFormProps(
      presentation,
      form,
      overrides,
      settings,
      validations,
      tab,
      this.onSubmit,
      isReviewer,
      isViewer,
      canSubmit,
      loading,
      templates,
      timezone
    );
  };

  getShouldDisableSubmitButton = () => {
    const { selectedForm = {} } = this.props;
    const { tab = 0, formFields = {} } = selectedForm;
    const { fields: formTabs = [] } = formFields;
    //formSchema inception

    const formNumber = get(selectedForm, ['meta', 'formNumber'], '');
    const formExist = !!formNumber;

    const isFinalTab = tab === formTabs.length - 1;
    const submitRequested = get(
      selectedForm,
      ['validations', 'form', 'submitRequested'],
      false
    );
    const shouldValidate = submitRequested || formExist;
    const isNonSavedDraft = !formExist && !isFinalTab;
    return !shouldValidate && isNonSavedDraft;
  };

  renderHeaderInfo = ({
    statusInfo,
    formProps,
    isDraft,
    isActiveReviewer,
    isTemporalActiveReviewer,
    isSubmitter,
    canEnableAdminEdit,
  }) => {
    const {
      selectedForm = {},
      canSubmit = false,
      isPrinting = false,
    } = this.props;
    const { template = {} } = selectedForm;
    //formSchema inception
    const { formSchema: jsonSchema = {} } = template;
    const { form: formSchema } = jsonSchema;

    const shouldDisableSubmitButton = this.getShouldDisableSubmitButton();

    const { key: statusKey = 'draft' } = statusInfo;
    const isPending = statusKey === 'pending';
    const { isEditAllow } = this.state;

    // esto lo ocupo para el AdminEdit
    const assignIsReviewerHeader = canEnableAdminEdit
      ? { isReviewer: !isEditAllow }
      : {};
    return (
      <div className="header-info">
        <SchemaDrawer
          {...formProps}
          {...formSchema}
          {...assignIsReviewerHeader}
          canSubmit={this.typeOfButtonToShowOnHeader(
            isEditAllow,
            canSubmit,
            isDraft,
            isActiveReviewer,
            isTemporalActiveReviewer,
            isSubmitter,
            isPrinting,
            isPending
          )}
          submitDisable={shouldDisableSubmitButton}
          tabsRefs={this.tabsRefs}
          formHeader={this.formHeader}
          isTopNavigation
          key={this.state.drawerKey}
        />
      </div>
    );
  };

  renderFormHeader = ({
    statusInfo,
    formProps,
    isDraft,
    isActiveReviewer,
    isTemporalActiveReviewer,
    canEnableAdminEdit,
  }) => {
    const {
      app: { sidebarCollapsed = false },
      selectedForm = {},
      actions = [],
      isReviewer = false,
      user: { id, agency = {} } = {},
      loading = false,
      templates = [],
      timezone,
      pageTitle,
      pageSubtitle,
    } = this.props;
    const {
      meta = {},
      onBehalf = {},
      data: { __assignedSections = [] } = {},
    } = selectedForm;
    const {
      submitterId = '',
      linkedForms = [],
      organizationalUnitDisplayName = '',
    } = meta;

    const formNumber = get(selectedForm, ['meta', 'formNumber'], '');
    const formExist = !!formNumber;
    const templateType = get(selectedForm, ['template', 'type'], '');
    const templateTypeName = get(selectedForm, ['template', 'typeName'], '');

    const formType = get(templates, [templateType, 'label'], templateTypeName);
    const formSubmittedDate = get(selectedForm, ['meta', 'submittedAt'], '');
    const formUpdatedDate = get(selectedForm, ['meta', 'updatedAt'], '');
    const formDraftDate = get(
      selectedForm,
      ['meta', 'draftDate'],
      formUpdatedDate
    );
    const submittedAt = parseDate(formSubmittedDate, timezone);
    const draftedAt = parseDate(formDraftDate, timezone);

    const formDate = isDraft ? draftedAt : submittedAt;
    const isContributor =
      __assignedSections.findIndex(s => s.userId === id && !s.complete) !== -1;
    const isSubmitter = Number(id) === submitterId || isContributor;
    const isLinked = !isEmpty(linkedForms);
    const statusIsDraft = this.isDraftStatus();
    const submittedBy = this.getSubmittedBy();

    const submitText =
      (selectedForm.status === 'take-action' || isReviewer) && !statusIsDraft
        ? 'Take Action'
        : 'Submit';
    return (
      <FormHeader
        submitText={submitText}
        statusInfo={statusInfo}
        submittedBy={submittedBy}
        statusSubmittedByDate={formDate}
        isDraft={isDraft || statusIsDraft}
        isActiveReviewer={
          isActiveReviewer || isTemporalActiveReviewer || formExist
        }
        actions={this.formActions.concat(actions)}
        title={formNumber}
        agency={agency.name}
        type={formType}
        loading={loading}
        isReviewer={isReviewer}
        isSubmitter={isSubmitter}
        formExist={formExist}
        showLinkedFormSummary={isLinked}
        saveRef={this.saveFormHeaderRef}
        sidebarCollapsed={sidebarCollapsed}
        pageTitle={pageTitle}
        pageSubtitle={pageSubtitle}
        usersOnBehalf={onBehalf}
        organizationalUnitDisplayName={organizationalUnitDisplayName}
      >
        {this.renderHeaderInfo({
          statusInfo,
          formProps,
          isDraft,
          isActiveReviewer,
          isTemporalActiveReviewer,
          isSubmitter,
          canEnableAdminEdit,
        })}
      </FormHeader>
    );
  };

  renderAdminEditButton = () => {
    const { isEditAllow } = this.state;
    const editButtonProps = {
      type: isEditAllow ? 'save' : 'edit',
      text: isEditAllow ? 'Update Report' : 'Edit Report',
    };

    return (
      <EditAdminButton
        enableEditReport={this.enableEditReport}
        disableEditReport={this.disableEditReport}
        isEditAllow={isEditAllow}
        editButtonProps={editButtonProps}
      />
    );
  };

  render() {
    const {
      selectedForm = {},
      supportsDraft = true,
      user: { id, permissions = [] } = {},
    } = this.props;
    const { template = {} } = selectedForm;
    //formSchema inception
    const { formSchema: jsonSchema = {} } = template;
    const { form: formSchema } = jsonSchema;
    const formNumber = get(selectedForm, ['meta', 'formNumber'], '');
    const formExist = !!formNumber;
    const formProps = this.getFormProps();
    const statusInfo = this.getLastStatus();
    const isDraft = this.isDraft(statusInfo);
    const statusIsDraft = this.isDraftStatus();
    const statusIsClosed = this.isClosedStatus(isDraft, statusInfo);
    const { isEditAllow } = this.state;
    const isEditMode = get(selectedForm, ['meta', 'isEditMode'], false);
    const activeReviewer = get(selectedForm, ['meta', 'activeReviewerId'], '');
    const isActiveReviewer = id === activeReviewer;
    const isTemporalActiveReviewer = this.getIsTemporalActiveReviewer();
    const canEnableAdminEdit = canEnableAdminEditReview({
      statusIsClosed,
      isActiveReviewer,
      isTemporalActiveReviewer,
      isEditMode,
      permissions,
    });
    const assignIsReviewerToBodyForm = canEnableAdminEdit
      ? { isReviewer: !isEditAllow, key: this.state.drawerKey }
      : {};

    this.autoSaveProcess(statusIsDraft);

    return (
      <Form
        className="bdm-form"
        hideRequiredMark
        onSubmit={this.handleSubmit}
        ref={this.saveFormRef}
        style={{ paddingTop: this.state.formOffset }}
      >
        {this.renderFormHeader({
          statusInfo,
          formProps,
          isDraft,
          isActiveReviewer,
          isTemporalActiveReviewer,
          canEnableAdminEdit,
        })}

        {canEnableAdminEdit && this.renderAdminEditButton()}

        <SchemaDrawer
          {...formProps}
          {...formSchema}
          {...assignIsReviewerToBodyForm}
        />

        <SchemaDrawer
          {...formProps}
          {...formSchema}
          submitDisable={!formExist}
          showSaveButton={supportsDraft}
          isBottomNavigation
        />

        <ScrollToTop onClick={this.scrollToTop} />

        {this.renderReviewList()}
      </Form>
    );
  }
}

const options = {
  onValuesChange: props => {
    const { dispatch } = props;
    dispatch(syncFormValueChanged(true));
  },
  onFieldsChange: (props, form) => {
    const {
      dispatch,
      selectedForm: { data = {}, presentation = {}, state: formState = {} },
    } = props;

    const { changes, state } = mapFormChanges(
      data,
      form,
      presentation,
      formState
    );

    dispatch(syncFormData(changes, state));
  },
  mapPropsToFields: props => {
    const { selectedForm = {}, attachments = {} } = props;
    const { data = {}, state = {}, presentation = {} } = selectedForm;
    const template = kebabCase(get(selectedForm, ['template', 'type'], ''));
    const extraFields = getFormAttachments(
      attachments,
      template,
      selectedForm.id
    );

    return initializeFormFields(
      mapPropsToFields(data, state, presentation, extraFields)
    );
  },
};

const FormEngine = Form.create(options)(FormViewerWrapper);

export default FormEngine;
