import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import cond from 'lodash/cond';
import isArray from 'lodash/isArray';
import matches from 'lodash/matches';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import classNames from 'classnames';
import { Modal } from 'antd';

import Switch, { StyledCompoundSwitch } from '../styled/input-switch';
import WrappedCheckbox from '../styled/input-checkbox';

import deleteFormLink from 'APP_ROOT/actions/delete-form-link';
import removeSourceSelection from 'APP_ROOT/actions/remove-source-selection';
import removeReferenceSelection from 'APP_ROOT/actions/remove-reference-selection';
import resetRepeaterKeys from 'APP_ROOT/actions/reset-repeater-keys';
import removeOwnKeys from 'APP_ROOT/actions/remove-own-keys';

import { getSelectedFormLink } from 'APP_ROOT/selectors/form';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import parseDate, {
  BENCHMARK_DATE_TIMEZ_FORMAT_HUMAN,
} from 'APP_ROOT/utils/parse-date';
import {
  emitSectionCompletion,
  getVisibilityOverridesCompleteCheckbox,
} from './utils/contribute-to-report-field-types.js';

const confirm = Modal.confirm;

class InputSwitch extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    const parsed =
      (typeof value === 'string' ? JSON.parse(value) : value) || false;

    this.state = {
      value: parsed,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { value: oldValue = false } = this.props;
    const { value: newValue = false } = newProps;
    if (newValue !== oldValue) {
      const parsed =
        (typeof value === 'string' ? JSON.parse(newValue) : newValue) || false;
      this.setValue(parsed);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  onRemoveParentRefKeys = ({ identifier = '', source = '', fields = [] }) => {
    const { options } = this.props;
    const formName = get(options, 'settings.formName');
    formActionDispatcher(
      formName,
      removeReferenceSelection(identifier, source, fields)
    );
  };

  setValue = value => {
    this.setState({ value });
  };

  toggle = toState => {
    const {
      onChange,
      isReviewer,
      options: { trainignTask = false },
    } = this.props;
    let overrideReviewer =
      isReviewer || this.props.contributorAssignmentCanView;
    if (this.props.contributorAssignmentCanEdit) {
      overrideReviewer = false;
    }
    if (!overrideReviewer) {
      if (trainignTask) {
        emitter.emit(EventTypes.TOGGLE_STATUS_TRAINING_TASK);
      }
      onChange(toState);
    }
  };

  onOk = (
    removeKeys,
    removeRefKeys,
    resetFields,
    resetKeys,
    resetRemoteKeys,
    resetRepeaters,
    parsed,
    resetOwnKeys,
    linker,
    batch
  ) => {
    const {
      data,
      id,
      form,
      parentKey,
      parentIndex,
      selectedFormLink = {},
      options,
    } = this.props;
    const formName = get(options, 'settings.formName');
    const { linkedFormsId = [] } = selectedFormLink;
    return () => {
      resetFields(resetKeys);
      removeKeys.forEach(item => {
        const { key = '', parentKey = '', source, batch = false } = item;
        const value = get(data, batch ? parentKey : key, '');
        if (isArray(value)) {
          value.forEach(obj => {
            formActionDispatcher(
              formName,
              removeSourceSelection(obj[key], source)
            );
          });
        } else {
          formActionDispatcher(formName, removeSourceSelection(value, source));
        }
      });
      removeRefKeys.forEach(item => {
        const {
          source = '',
          field = '',
          fields = [],
          parentKey = '',
          key = '',
          batch = false,
        } = item;
        const value = get(data, batch ? parentKey : field, '');
        if (isArray(value)) {
          value.forEach(obj => {
            formActionDispatcher(
              formName,
              removeReferenceSelection(obj[key], source, fields)
            );
          });
        } else {
          formActionDispatcher(
            formName,
            removeReferenceSelection(value, source, fields)
          );
        }
      });
      formActionDispatcher(formName, resetRepeaterKeys(resetRepeaters));
      formActionDispatcher(
        formName,
        removeOwnKeys(resetOwnKeys, id, parentKey, parentIndex)
      );
      if (linker) {
        const localFormKeys = resetKeys.reduce(
          (acc, key) => ({ ...acc, [key]: null }),
          {}
        );

        // Reset linking fields
        form.setFieldsValue(localFormKeys);
        // Do unlink actions
        linkedFormsId.forEach(id => {
          formActionDispatcher(
            formName,
            deleteFormLink({ formId: id, resetRemoteKeys }, () => {
              emitFormEvent('onSaveDraft', get(options, 'settings.formName'));
            })
          );
        });
      }
      this.toggle(parsed);
    };
  };

  onHandleChange = value => {
    const parsed =
      (typeof value === 'string' ? JSON.parse(value) : value) || false;
    const {
      options,
      title,
      form,
      id,
      parentKey,
      parentIndex,
      selectedFormLink = {},
      data,
    } = this.props;
    const { formType = '', linkedSubmissions = [] } = selectedFormLink;
    const {
      resetKeys = [],
      resetRemoteKeys = [],
      removeKeys = [],
      removeRefKeys = [],
      resetOwnKeys = [],
      resetWithModal = true,
      resetRepeaters = [],
      linker = false,
      removeParentRefKeys = [],
    } = options;
    const { resetFields } = form;
    const linkedSubmission = linkedSubmissions[0] || {};
    const {
      formType: linkedFormType,
      formNumber: linkedFormNumber,
    } = linkedSubmission;
    const formName = get(options, 'settings.formName');

    const isLinked = linkedSubmissions.length > 0;

    if (
      ((resetKeys.length && isLinked) ||
        resetOwnKeys.length ||
        removeKeys.length) &&
      resetWithModal
    ) {
      if (!value) {
        confirm({
          title: 'Heads Up!',
          okText: linker ? 'Continue' : 'Yes',
          content: linker ? (
            <div>
              <p>
                You are about to remove the{' '}
                {`${linkedFormType} incident ${linkedFormNumber} from
                this ${formType}`}
                .
              </p>
              <p>
                By selecting Continue the report will go through their own
                workflow and no longer be linked.
              </p>
            </div>
          ) : (
            <p>
              By changing {title} to NO, all the information related will be
              removed from the entire form.
              <br />
              Do you want to remove it?
            </p>
          ),
          onOk: this.onOk(
            removeKeys,
            removeRefKeys,
            resetFields,
            resetKeys,
            resetRemoteKeys,
            resetRepeaters,
            parsed,
            resetOwnKeys,
            linker
          ),
        });
      } else {
        this.toggle(value);
      }
    } else {
      if (!value && resetRepeaters.length) {
        formActionDispatcher(formName, resetRepeaterKeys(resetRepeaters));
      }

      resetFields(resetKeys);
      formActionDispatcher(
        formName,
        removeOwnKeys(resetOwnKeys, id, parentKey, parentIndex)
      );
      this.toggle(value);
    }

    removeParentRefKeys.forEach(({ matchesValues = [], ...item }) => {
      const identifier = get(data, [parentKey, parentIndex, item.field], '');

      cond(
        matchesValues.map(rule => [
          matches({ value: rule }),
          () => this.onRemoveParentRefKeys({ identifier, ...item }),
        ])
      )({ value });
    });
  };

  render() {
    const renderProps = omit(this.props, [
      'enums',
      'parentIndex',
      'parentKey',
      'dataKey',
      'selectedFormLink',
      'dispatch',
      'isOwnChange',
      'dependsOnChange',
      'timezone',
      'store',
      'storeSubscription',
      'field_type',
    ]);
    const trainingProps = pick(this.props, [
      'parentIndex',
      'parentKey',
      'dataKey',
      'timezone',
      'data',
      'enums',
      'field_type',
    ]);

    const {
      isReviewer,
      options = {},
      calculatedValue: rawCalculatedValue,
      ...props
    } = renderProps;
    const { value: stateValue } = this.state;
    const { offLabel = 'No', onLabel = 'Yes', trainignTask = false } = options;

    const calculatedValue =
      (typeof rawCalculatedValue === 'string'
        ? JSON.parse(rawCalculatedValue)
        : rawCalculatedValue) || false;

    let overrideReviewer =
      isReviewer || this.props.contributorAssignmentCanView;

    if (overrideReviewer && !this.props.contributorAssignmentCanEdit) {
      if (trainignTask) {
        const { data = {}, parentKey, parentIndex, timezone } = trainingProps;
        const { __users: users } = data;
        const taskCompletedItem = get(data, [parentKey, parentIndex], {});
        const {
          taskCompletedBy,
          taskCompletedTimestamp,
          taskCompleted,
        } = taskCompletedItem;
        const user = get(users, taskCompletedBy, {});
        const { fullName = '', rank: { name: rankName = '' } = {} } = user;

        return (
          <StyledCompoundSwitch>
            <div className="text-field">
              {calculatedValue || stateValue ? onLabel : offLabel}
            </div>
            {taskCompleted && (
              <span>
                &bull;&nbsp;&nbsp;&nbsp;
                {`${rankName} ${fullName} marked as Completed on ${parseDate(
                  taskCompletedTimestamp,
                  timezone,
                  BENCHMARK_DATE_TIMEZ_FORMAT_HUMAN
                )}`}
              </span>
            )}
          </StyledCompoundSwitch>
        );
      }
      return (
        <div className="reviewer-field text-field">
          {calculatedValue || stateValue ? onLabel : offLabel}&nbsp;
        </div>
      );
    }

    if (trainignTask) {
      const { data = {}, parentKey, parentIndex, timezone } = trainingProps;
      const { __users: users } = data;
      const taskCompletedItem = get(data, [parentKey, parentIndex], {});
      const {
        taskCompletedBy,
        taskCompletedTimestamp,
        taskCompleted,
      } = taskCompletedItem;
      const user = get(users, taskCompletedBy, {});
      const { fullName = '', rank: { name: rankName = '' } = {} } = user;

      return (
        <StyledCompoundSwitch>
          <Switch
            value={stateValue}
            checked={stateValue}
            checkedChildren={onLabel}
            unCheckedChildren={offLabel}
            size="default"
            {...props}
            onChange={this.onHandleChange}
            ref={ref => (this.input = ref)}
          />
          {taskCompleted && (
            <span>
              {`${rankName} ${fullName} marked as Completed on ${parseDate(
                taskCompletedTimestamp,
                timezone,
                BENCHMARK_DATE_TIMEZ_FORMAT_HUMAN
              )}`}
            </span>
          )}
        </StyledCompoundSwitch>
      );
    }

    return (
      <Switch
        value={stateValue}
        checked={stateValue}
        checkedChildren={onLabel}
        unCheckedChildren={offLabel}
        size="default"
        {...props}
        onChange={this.onHandleChange}
        ref={ref => (this.input = ref)}
      />
    );
  }
}

class InputCheckbox extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    const parsed =
      (typeof value === 'string' ? JSON.parse(value) : value) || false;
    this.state = {
      value: parsed,
      reportingKey: props.reportingKey,
      key: props.id,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value: oldValue = false } = prevState;
    const { value: newValue = false } = nextProps;
    if (newValue !== oldValue) {
      const parsed =
        (typeof newValue === 'string' ? JSON.parse(newValue) : newValue) ||
        false;
      return { value: parsed };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  toggle = event => {
    const value = get(event, 'target.checked', false);
    emitSectionCompletion(this.props, value);
    const { onChange } = this.props;
    onChange(value);
    this.setState({ value });
  };

  render() {
    const {
      enums = [], // eslint-disable-line
      options,
      isReviewer,
      parentIndex,
      parentKey,
      dataKey,
      isOwnChange,
      dependsOnChange,
      timezone,
      field_type,
      calculatedValue,
      ...props
    } = this.props;

    const { value } = this.state;

    const { label = '' } = options;

    const {
      overrideReviewer,
      isContributorSectionComplete,
    } = getVisibilityOverridesCompleteCheckbox(this.props, isReviewer);

    const reviewerClass = classNames({
      'is-reviewing': isReviewer && !isContributorSectionComplete,
      'complete-check': isContributorSectionComplete,
    });

    return (
      <WrappedCheckbox
        checked={isReviewer ? calculatedValue || value : value}
        defaultChecked={value}
        className={reviewerClass}
        {...props}
        onChange={this.toggle}
        ref={ref => (this.input = ref)}
        disabled={overrideReviewer}
      >
        {isContributorSectionComplete ? 'Complete' : label}
        <br />
      </WrappedCheckbox>
    );
  }
}

const mapState = state => ({
  selectedFormLink: getSelectedFormLink(state),
  selectedForm: state.form.selectedForm,
});

export default connect(mapState, null)(InputSwitch);
export const Checkbox = connect(mapState, null)(InputCheckbox);
