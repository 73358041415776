import React from 'react';
import parseDate, { BENCHMARK_DATE_FORMAT } from '../../../utils/parse-date';

function sortAlphabetically(a, b, field) {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  return 0;
}

function renderDate(data, timezone) {
  if (data) {
    const date = parseDate(data, timezone, BENCHMARK_DATE_FORMAT);
    return `${date}`;
  }
  return '';
}

export default (timezone, handleClick) => [
  {
    title: 'Abbreviation',
    dataIndex: 'abbreviation',
    key: 'abbreviation',
    sorter: (a, b) => sortAlphabetically(a, b, 'abbreviation'),
    render: (abbreviation, casefileType) => (
      <a onClick={() => handleClick(casefileType)}>{abbreviation}</a>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => sortAlphabetically(a, b, 'type'),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    sorter: (a, b) => sortAlphabetically(a, b, 'category'),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.createdAt - b.createdAt,
    render: data => renderDate(data, timezone),
  },
];
